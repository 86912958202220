import UserActionTypes from "./users.types";
import { setFirebaseElement, setFirebaseElements } from "../redux.utils";

const INITIAL_STATE = {
    email: null,
    selfId: null,
    users: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserActionTypes.ADD_OTHER:
            return {
                ...state, 
                users: setFirebaseElement(state.users, action.payload)
            };
        case UserActionTypes.ADD_USERS:
            return {
                ...state, 
                users: setFirebaseElements(state.users, action.payload)
            };
        case UserActionTypes.SET_EMAIL:
            return { ...state, email: action.payload };
        case UserActionTypes.SET_SELF:
            return {
                ...state, 
                selfId: action.payload.id, 
                users: setFirebaseElement(state.users, action.payload)
            };
        default:
            return state;
    };
};

export default userReducer;