import { FirestoreProps, ServerResponseStates } from "../../firebase/firestore.types";
import AdminActionTypes from "./admin.types";

const INITIAL_STATE = {
    adminId: null,
    allIds: null,
    breakoutRoomNumber: null,
    debug: false,
    modalMessages: null,
    serverWait: ServerResponseStates.DORMANT,
};

const adminReducer = (state = INITIAL_STATE, action) => {
    // console.log('qwer', action.type);
    const type = action.type;
    switch (true) {
        case type === AdminActionTypes.SET_ADMIN_ID:
            return { 
                ...state, 
                adminId: action.payload 
            };
        case type === AdminActionTypes.SET_BREAKOUT_ROOM_NUMBER:
            return { 
                ...state, 
                breakoutRoomNumber: action.payload 
            };
        case type === AdminActionTypes.SET_DEBUG:
            return { 
                ...state, 
                debug: action.payload 
            };
        case type === AdminActionTypes.SET_MODAL_MESSAGES:
            return { 
                ...state, 
                modalMessages: action.payload 
            };
        case type === AdminActionTypes.SET_SERVER_WAIT:
            return { 
                ...state, 
                serverWait: action.payload 
            };
        case type.includes("@@redux/") || type.includes("persist/"):
            return state;
        default:
            const payload = action.payload;
            if (!payload || payload === undefined) {
                return state;
            };
            let allIds = state.allIds ? [...state.allIds] : [];
            if (payload.constructor === Array) {
                for (const payloadElement of payload) {
                    const payloadElementId = payloadElement[FirestoreProps.ID];
                    if (
                        payloadElementId 
                        && payloadElementId !== undefined
                        && allIds.indexOf(payloadElementId) < 0
                    ) {
                        allIds.push(payloadElementId);
                    };
                };
            } else {
                const payloadId = payload[FirestoreProps.ID];
                if (
                    payloadId 
                    && payloadId !== undefined
                    && allIds.indexOf(payloadId) < 0
                ) {
                    allIds.push(payloadId);
                };
            };
            // console.log('qwer', allIds);
            return { ...state, allIds };
    };
};

export default adminReducer;