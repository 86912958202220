import { Metrics } from "./app.types";

export const getDateFromClientTimeDifference = clientTimeDifference => {
    return new Date().getTime() - (clientTimeDifference * Metrics.MILLIS_PER_SECOND);
};

export const numberHasValue = number => {
    return number 
        && number !== undefined 
        && !isNaN(number) 
        && number > 0;
};
