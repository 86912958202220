import React from "react";

import { BorderedBox, Row } from "../../global.styles";
import { ItemColumn, ItemColumnTitle, ItemImage } from "./item-outputs.styles";
import { MapInteractionCSS } from "react-map-interaction";

//import { getMediumView } from "../components.utils";
import { Generics } from "../../app.types";
import { getMediumView } from "../components.utils";
import { FirestoreProps } from "../../firebase/firestore.types";
import { MediaTypes } from "./item-io.types";

const ItemOutputs = ({ hotspotDetails, item, items, media, storageUrls, itemState }) => {
    // console.log('qwer items', items);
    // console.log('qwer media', media);
    const hotspotDetailsId = hotspotDetails[FirestoreProps.ID];

    const getMediaToShow = (item, state) => {
        // console.log('qwer GMTS 1', item);
        const itemMediaIds = item[FirestoreProps.MEDIA_IDS];
        // console.log('qwer GMTS 2', itemMediaIds);
        let mediaToShow = [];
        let awaitingVideo = false;
        if (itemMediaIds) {
            const itemMediaIdsByState = itemMediaIds[state];
            // console.log('qwer GMTS 3', itemMediaIdsByState);
            if (itemMediaIdsByState) {
                for (const mediumId of itemMediaIdsByState) {
                    const filteredMedia = media ? 
                        media.filter(medium => medium[FirestoreProps.ID] === mediumId)
                        : null;
                    // console.log('qwer GMTS 4', mediumId, media)
                    if (filteredMedia && filteredMedia.length === 1) {
                        const medium = filteredMedia[0];
                        mediaToShow.push(medium);
                        // console.log('qwer GMTS 5', mediaToShow);
                        if (
                            medium[FirestoreProps.TYPE] === MediaTypes.VIDEO
                            && !storageUrls[mediumId]
                        ) {
                            awaitingVideo = true;
                        };
                    };
                };
                // console.log('qwer GMTS 5', awaitingVideo, mediaToShow);
            };
        };
        // console.log('qwer GMTS 6', mediaToShow.length);
        return mediaToShow.length > 0 ? 
            { 
                value: mediaToShow, 
                awaitingVideo 
            }
            : null;
    };

    // console.log('qwer ----------------------------------------------------',);
    // console.log('qwer START', itemState);
    let mediaToShow = [];
    let inventoryItemsToShow = [];
    let awaitingVideo = false;
    if (item) {
        const mediaToShowAndAwaitingVideo = getMediaToShow(item, itemState);
        if (mediaToShowAndAwaitingVideo) {
            mediaToShow = mediaToShow.concat(mediaToShowAndAwaitingVideo.value);
            awaitingVideo = !awaitingVideo ? 
                mediaToShowAndAwaitingVideo.awaitingVideo 
                : awaitingVideo;
        };

        // console.log('qwer mediaToShowAndAwaitingVideo', mediaToShowAndAwaitingVideo);
        const itemInventoryItemIds = item[FirestoreProps.INVENTORY_ITEM_IDS];
        if (itemInventoryItemIds) {
            const itemInventoryItemIdsByState = itemInventoryItemIds[itemState];
            // console.log('qwer iiids by state', itemInventoryItemIdsByState);
            if (itemInventoryItemIdsByState) {
                for (const itemInventoryItemId of itemInventoryItemIdsByState) {
                    const filteredInventoryItems = items ?
                        items.filter(item => item.id === itemInventoryItemId)
                        : null;
                    if (filteredInventoryItems && filteredInventoryItems.length === 1) {
                        const inventoryItem = filteredInventoryItems[0];
                        inventoryItemsToShow.push(inventoryItem);
                    };
                };
            };
        };
    };
    // console.log('qwer RES video?', awaitingVideo);
    // console.log('qwer RES media:', mediaToShow);
    // console.log('qwer RES inv media:', inventoryItemsToShow);

    return awaitingVideo ?
        <p>loading video...</p>
        :
        <div>
            {mediaToShow.length ?
                <Row>
                    {mediaToShow.map(medium => getMediumView(medium, hotspotDetailsId, storageUrls))}
                </Row>
                : null
            }
            {inventoryItemsToShow.length ?
                <Row>
                    {inventoryItemsToShow.map(inventoryItem => {
                        const thumbnailFileName = inventoryItem[FirestoreProps.THUMBNAIL];
                        const filteredStorageUrls = thumbnailFileName && thumbnailFileName !== undefined ? 
                            storageUrls.filter(storageUrl => {
                                return storageUrl[ FirestoreProps.ID ] === thumbnailFileName
                            })
                            : null;
                        const thumbnailUrl = filteredStorageUrls && filteredStorageUrls.length === 1 ?
                            filteredStorageUrls[0][FirestoreProps.URL]
                            : Generics.PLACEHOLDER_IMAGE_URL;
                        // console.log('qwer', inventoryItem, storageUrls);
                        return <ItemColumn key={`${hotspotDetailsId}_${inventoryItem[FirestoreProps.TITLE]}`} widthPercent={50}>
                            <BorderedBox>
                                <MapInteractionCSS
                                    minScale={1}
                                >
                                    <ItemImage src={thumbnailUrl} alt="" />
                                </MapInteractionCSS>
                            </BorderedBox>
                            <ItemColumnTitle>{inventoryItem[FirestoreProps.TITLE]}</ItemColumnTitle>
                        </ItemColumn>
                    })}
                </Row>
                : null
            }
        </div>;
};

export default ItemOutputs;
