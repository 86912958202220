import React from "react";

import { FormButton } from "../global.styles";

const CreateEventForm = ({ handleSubmit }) => (
    <>
        <form onSubmit={handleSubmit}>
            <FormButton type="submit" value="Create new event" />
        </form>
    </>
);

export default CreateEventForm;
