import React from "react";
import { connect } from "react-redux";

import { FormButton, FormButtonSelected } from "../global.styles";

import { FirestoreProps } from "../firebase/firestore.types";

import { selectScenesByProductId } from "../redux/product/product.selectors";

const SceneDetails = ({ sceneId, scenes, handleClick }) => {
    return (<>
        <p>Scenes:</p>
        {scenes ? 
            <>
                {scenes.map(scene => {
                    const title = scene[FirestoreProps.TITLE];
                    const sceneSceneId = scene[FirestoreProps.ID]
                    const isSelectedScene = sceneSceneId === sceneId;
                    const buttonId = isSelectedScene ? null : scene[FirestoreProps.ID];
                    // console.log('qwer', title, isSelectedScene, buttonId);
                    return isSelectedScene ? 
                        <FormButtonSelected 
                            key={sceneSceneId}
                            type="button" 
                            value={title} 
                            id={buttonId}
                            onClick={handleClick} 
                        />
                        :
                        <FormButton 
                            key={sceneSceneId}
                            type="button" 
                            value={title} 
                            id={buttonId}
                            onClick={handleClick} 
                        />
                })}
            </>
            : null
        } 
    </>);
};

const mapStateToProps = (state, ownProps) => ({
    sceneId: ownProps.sceneId,
    scenes: selectScenesByProductId(ownProps.productId)(state),
    handleClick: ownProps.handleClick,
});

export default connect(mapStateToProps)(SceneDetails)