import { createSelector } from "reselect";

const teamReducer = state => state.team;

export const selectCaptainId = createSelector(
    [teamReducer],
    reducer => reducer.captainId
);

export const selectOtherTeams = createSelector(
    [teamReducer],
    reducer => reducer.otherTeams
);

export const selectTeamName = createSelector(
    [teamReducer],
    reducer => reducer.teamName
);

// ------------

export const selectOtherTeamByBreakoutRoomNumberAndEventId = (breakoutRoomNumber, eventId) => createSelector(
    [selectOtherTeams],
    otherTeams => otherTeams ? otherTeams[`${eventId}-${breakoutRoomNumber}`] : null
);
