const ProductActionTypes = {
    ADD_HINT: "ADD_HINT",
    ADD_HOTSPOT_DETAILS: "ADD_HOTSPOT_DETAILS",
    ADD_HOTSPOTS: "ADD_HOTSPOTS",
    ADD_IMAGE_MAP: "ADD_IMAGE_MAP",
    ADD_ITEM: "ADD_ITEM",
    ADD_MEDIUM: "ADD_MEDIUM",
    ADD_PRODUCT: "ADD_PRODUCT",
    ADD_PRODUCT_ID: "ADD_PRODUCT_ID",
    ADD_PRODUCTS: "ADD_PRODUCTS",
    ADD_SCENES: "ADD_SCENES",
    ADD_SOLUTION_DEPENDENCIES: "ADD_SOLUTION_DEPENDENCIES",
    ADD_STORAGE_URL: "ADD_STORAGE_URL",
};

export default ProductActionTypes;