import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Column, ContentContainer, Row } from "../global.styles";
import NameForm from "../components/name-form.component";
import withUserUtils from "./with-user-utils";

import { RealTimeKeys, RoomsCodes } from "../firebase/realtime.types";
import { storeUser } from "../firebase/realtime.utils";
import { CollectionNames, FirestoreProps, ServerResponseStates } from "../firebase/firestore.types";
import { formatWithId, fetchDataByFieldPairs, listenForUpdates } from "../firebase/firestore.utils";

import { selectClient, selectEventId, selectPassCode } from "../redux/event/event.selectors";
import { selectProductId, selectProductName } from "../redux/product/product.selectors";
import { selectSceneIndex } from "../redux/game/game.selectors";
import { selectSelfId } from "../redux/users/users.selectors";

import { addScenes } from "../redux/product/product.actions";
import { setEvent } from "../redux/event/event.actions";
import { setOwnUser } from "../redux/users/users.actions";
import { setSceneId, setSceneIndex } from "../redux/game/game.actions";

import { ModalHeaders, ModalMessages } from "../modal.types";
import { PageTypes } from "./page.types";


class NamePage extends React.Component {
    eventListener = null;

    constructor(props) {
        super(props);
        // console.log('qwer init');

        this.state = {
            userName: "",
            breakoutRoomNumber: 0
        };
    };

    async componentDidMount() {
        const { eventId } = this.props;
        // const { eventId, sceneIndex, productName, client } = this.props;
        // console.log('qwer', eventId, sceneIndex, productName, client);
        // if (sceneIndex !== null) {
        //     this.goToLobby();
        // } else if (!productName || !client) {
        //     const location = "/" + PageTypes.ERROR;
        //     this.props.history.push(location);
        //     // window.location = location;
        // };

        this.eventListener = await listenForUpdates(
            CollectionNames.EVENTS, 
            eventId,
            event => this.props.setEvent(formatWithId(eventId, event))
        );
        
        this.props.setPageName(PageTypes.NAME);
    };

    componentWillUnmount() {
        if (this.eventListener) {
            this.eventListener();
        };
    };

    goToLobby = () => {
        const { eventPassCode, sceneIndex } = this.props;
        // console.log('qwer', eventPassCode, sceneIndex);
        const location = "/" + eventPassCode + "/" + PageTypes.SCENE + (sceneIndex + 1);
        this.props.history.push(location);
        // window.location = location;
    };

    handleChange = e => {
        const { value, name } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { userName, breakoutRoomNumber } = this.state;
        const { eventId, selfId } = this.props;
        if (userName.length === 0) {
            this.props.setModalMessages({ header: ModalHeaders.OOPS, message: "Please choose a name" });
            return;
        };
        if (breakoutRoomNumber < 1) {
            this.props.setModalMessages({ header: ModalHeaders.OOPS, message: "Please choose a breakout room" });
            return;
        };
        const firstSceneIndex = 0;
        const user = await storeUser(
            userName.trim(), 
            breakoutRoomNumber, 
            RoomsCodes.LOBBY,
            firstSceneIndex, 
            eventId + "/" + RealTimeKeys.USERS, 
            selfId
        );
        if (user) {
            this.props.setOwnUser(user);
        } else {
            this.props.setModalMessages({ header: ModalHeaders.OOPS, message: ModalMessages.BAD_DATA + " (#732)" });
            return;
        };
        const { productId, addScenes } = this.props;
        this.props.setServerResponseState(ServerResponseStates.WAITING);
        const scenes = await fetchDataByFieldPairs(
            CollectionNames.SCENES, 
            [{ 
                key: FirestoreProps.PRODUCT_ID, 
                value: productId
            }]
        );
        this.props.setServerResponseState(ServerResponseStates.DORMANT);
        if (!scenes) {
            this.props.setModalMessages({ header: ModalHeaders.OOPS, message: ModalMessages.BAD_DATA + " (#733)" });
            return;
        };
        addScenes(scenes.sort((a, b) => a[FirestoreProps.INDEX] - b[FirestoreProps.INDEX]));
        this.props.setSceneId(scenes[firstSceneIndex].id);
        this.props.setSceneIndex(firstSceneIndex);
        this.goToLobby();
    };
    
    render() {
        const { productName, client } = this.props;
        return (<ContentContainer>
            <Row>
                <Column widthPercent={50}>
                    <p>{productName} with {client}</p>
                </Column>
                <Column widthPercent={50}>
                    <NameForm 
                        userName={this.state.userName}
                        handleSubmit={this.handleSubmit}
                        handleChange={this.handleChange}
                    />
                </Column>
            </Row>
        </ContentContainer>);
    };
};

const mapStateToProps = createStructuredSelector({
    client: selectClient,
    eventId: selectEventId,
    eventPassCode: selectPassCode,
    productId: selectProductId,
    productName: selectProductName,
    sceneIndex: selectSceneIndex,
    selfId: selectSelfId,
});

const mapDispatchToProps = dispatch => ({
    addScenes: value => dispatch(addScenes(value)),
    setEvent: value => dispatch(setEvent(value)),
    setOwnUser: value => dispatch(setOwnUser(value)),
    setSceneId: value => dispatch(setSceneId(value)),
    setSceneIndex: value => dispatch(setSceneIndex(value)),
});

export default withUserUtils(
    connect(mapStateToProps, mapDispatchToProps)(NamePage)
);
