import React from "react";
import { connect } from "react-redux";

import { TableCell, TableCellCentered, TableContainer } from "../global.styles";

import { FirestoreProps } from "../firebase/firestore.types";
import { InteractionKeys, InteractionTypes, UserKeys } from "../firebase/realtime.types";

import { selectInteractions } from "../redux/interactions/interactions.selectors";
import { selectUserIdsByBreakoutRoomNumber, selectUsers } from "../redux/users/users.selectors";

const InteractionsList = ({ breakoutRoomNumber, interactions, sceneId, userIds, users }) => {
    // console.log('qwer', users)
    const unknown = "unknown";
    const filteredInteractions = interactions ? 
        interactions.filter(interaction => {
            const permittedByScene = !sceneId || sceneId === interaction[InteractionKeys.SCENE_ID];
            const permittedByUser = userIds ?
                userIds.indexOf(interaction[InteractionKeys.USER_ID]) > -1
                : !breakoutRoomNumber || breakoutRoomNumber < 1;
            // console.log('qwer', interaction[InteractionKeys.USER_ID], userIds, breakoutRoomNumber);
            return permittedByScene && permittedByUser;
        })
        .sort((a, b) => {
            return (a[InteractionKeys.TIMESTAMP] - b[InteractionKeys.TIMESTAMP]) 
            // && (a[UserKeys.BREAKOUT_ROOM_NUMBER] - b[UserKeys.BREAKOUT_ROOM_NUMBER])
        })
        : null;
    
    const getInteractionTypeCopy = interaction => {
        switch (interaction[InteractionKeys.TYPE]) {
            case InteractionTypes.CHALLENGE_INCORRECT:          return "challenge incorrect";
            case InteractionTypes.CLICKED_OBJECT:               return "item click";
            case InteractionTypes.ENTRY:                        return "room entry";
            case InteractionTypes.HELP:                         return "help request";
            case InteractionTypes.INFO_FOUND:                   return "info found";
            case InteractionTypes.INACTIVE:                     return "inactive";
            case InteractionTypes.INVENTORY_ITEM:               return "inventory item";
            case InteractionTypes.INVENTORY_ITEM_USED:          return "inventory item use";
            case InteractionTypes.INVENTORY_ITEM_MISUSED:       return "inventory item misuse";
            case InteractionTypes.LEFT_GAME:                    return "left game";
            case InteractionTypes.MAJOR_HINT:                   return "major hint";
            case InteractionTypes.MINOR_HINT:                   return "minor hint";
            case InteractionTypes.SCENE_CHALLENGE_CORRECT:      return "scene challenge correct";
            case InteractionTypes.SCENE_CHALLENGE_INCORRECT:    return "scene challenge incorrect";
            case InteractionTypes.USER_CHALLENGE_CORRECT:       return "user challenge correct";
            case InteractionTypes.TEAM_CHALLENGE_CORRECT:       return "team challenge correct";
            default:                                            return unknown;
        }
    };

    const getUserByInteraction = interaction => {
        const userId = interaction[InteractionKeys.USER_ID];
        const filteredUsers = users.filter(user => user[FirestoreProps.ID] === userId);
        return filteredUsers.length === 1 ?
            filteredUsers[0]
            : unknown;
    };

    return (<>
        {filteredInteractions && filteredInteractions.length ? 
            <>
                <p>INTERACTIONS</p>
                <TableContainer>
                    <TableCell widthPercent={35}>NAME</TableCell>
                    <TableCell widthPercent={50}>ACTION</TableCell>
                    <TableCellCentered widthPercent={7.5}>SC</TableCellCentered>
                    <TableCellCentered widthPercent={7.5}>BR</TableCellCentered>
                </TableContainer>
                {filteredInteractions.map(interaction => {
                        const user = getUserByInteraction(interaction);
                        return <TableContainer key={interaction[FirestoreProps.ID]}>
                            <TableCell widthPercent={35}>{user[UserKeys.NAME]}</TableCell>
                            <TableCell widthPercent={50}>{getInteractionTypeCopy(interaction)}</TableCell>
                            <TableCellCentered widthPercent={7.5}>{user[UserKeys.SCENE_INDEX] + 1}</TableCellCentered>
                            <TableCellCentered widthPercent={7.5}>{user[UserKeys.BREAKOUT_ROOM_NUMBER]}</TableCellCentered>
                        </TableContainer>
                    })
                }
            </>
            : null
        }
    </>);
};

const mapStateToProps = (state, ownProps) => ({
    breakoutRoomNumber: ownProps.breakoutRoomNumber,
    interactions: selectInteractions(state),
    sceneId: ownProps.sceneId,
    userIds: selectUserIdsByBreakoutRoomNumber(ownProps.breakoutRoomNumber)(state),
    users: selectUsers(state),
});

export default connect(mapStateToProps)(InteractionsList);
