import GameActionTypes from "./game.types";

import { setFirebaseElement, setFirebaseElements } from "../redux.utils";

import { FirestoreProps } from "../../firebase/firestore.types";

const INITIAL_STATE = {
    clientTimeDifference: null,
    completedScenesIds: null,
    hotspotDetailsId: null,
    hintState: null,
    imageMapProperties: null,
    inventoryItems: null,
    inventoryItemStates: null,
    pageName: null,
    sceneId: null,
    sceneIndex: null,
    solvedSolutions: null,
};

const gameReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GameActionTypes.ADD_COMPLETED_SCENE_ID:
            return { 
                ...state, 
                completedScenesIds: setFirebaseElement(state.completedScenesIds, action.payload) 
            };
        case GameActionTypes.ADD_COMPLETED_SCENES_IDS:
            return { 
                ...state, 
                completedScenesIds: setFirebaseElements(state.completedScenesIds, action.payload) 
            };
        case GameActionTypes.ADD_IMAGE_MAP_PROPERTIES:
            return { 
                ...state, 
                imageMapProperties: action.payload 
            };
        case GameActionTypes.ADD_INVENTORY_ITEM:
            return { 
                ...state, 
                inventoryItems: setFirebaseElement(state.inventoryItems, action.payload) 
            };
        case GameActionTypes.ADD_INVENTORY_ITEM_STATE:
            return { 
                ...state, 
                inventoryItemStates: setFirebaseElement(state.inventoryItemStates, action.payload) 
            };
        case GameActionTypes.ADD_SOLVED_SOLUTION:
            return { 
                ...state, 
                solvedSolutions: setFirebaseElement(state.solvedSolutions, action.payload) 
            };
        case GameActionTypes.ADD_SOLVED_SOLUTIONS:
            return { 
                ...state, 
                solvedSolutions: setFirebaseElements(state.solvedSolutions, action.payload) 
            };
        case GameActionTypes.COMPLETE_INVENTORY_ITEMS_USAGE:
            if (!state.inventoryItems) {
                return state;
            }
            const inventoryItemIds = action.payload;
            let inventoryItems = [...state.inventoryItems];
            for (const inventoryItemId of inventoryItemIds) {
                const inventoryItem = getInventoryItemById(state, inventoryItemId);
                if (inventoryItem) {
                    inventoryItems = setFirebaseElement(
                        inventoryItems, 
                        {
                            ...inventoryItem,
                            [FirestoreProps.NUM_USES]: 0,
                        }
                    )
                };
            };
            return { ...state, inventoryItems };
        case GameActionTypes.SET_CLIENT_TIME_DIFFERENCE:
            return { ...state, clientTimeDifference: action.payload };
        case GameActionTypes.SET_HINT_STATE:
            return { ...state, hintState: action.payload };
        case GameActionTypes.SET_HOTSPOT_DETAILS_ID:
            return { ...state, hotspotDetailsId: action.payload };
        case GameActionTypes.SET_PAGE_NAME:
            return { ...state, pageName: action.payload };
        case GameActionTypes.SET_SCENE_ID:
            return { ...state, sceneId: action.payload };
        case GameActionTypes.SET_SCENE_INDEX:
            return { ...state, sceneIndex: action.payload };
        default:
            return state;
        }
};

const getInventoryItemById = (state, inventoryItemId) => {
    const filteredInventoryItems = state.inventoryItems.filter(inventoryItem => {
        return inventoryItem.id === inventoryItemId;
    });
    return filteredInventoryItems.length === 1 ? 
        filteredInventoryItems[0]
        : null;
};

export default gameReducer;
