import React from "react";
import { connect } from "react-redux";

import { FormLabel, FormSelect } from "../global.styles";
import { selectAdminBreakoutRoomNumber } from "../redux/admin/admin.selectors";

import { selectAmountBreakoutRooms } from "../redux/event/event.selectors";

const BreakoutRoomsSelect = ({ amountBreakoutRooms, adminBreakoutRoomNumber, handleChange }) => {
    let breakoutRoomNumbers = [];
    let i;
    for (i=0; i<amountBreakoutRooms; i++) {
        breakoutRoomNumbers.push(i + 1);
    };
    // console.log('qwer A', adminBreakoutRoomNumber);
    return (<>
        <FormLabel>Breakout room:</FormLabel>
        <FormSelect 
            name="breakoutRoomNumber" 
            id="breakoutRoomNumber" 
            defaultValue={adminBreakoutRoomNumber}
            onChange={handleChange}
        >
            <option value="0"> --- </option>
            {breakoutRoomNumbers.map(breakoutRoomNumber => {
            // console.log('qwer B', breakoutRoomNumber);
                return <option 
                    key={breakoutRoomNumber} 
                    value={breakoutRoomNumber}
                >
                    {`Breakout room ${breakoutRoomNumber}`}
                </option>
            })}
        </FormSelect>
    </>);
}

const mapStateToProps = (state, ownProps) => ({
    adminBreakoutRoomNumber: selectAdminBreakoutRoomNumber(state),
    amountBreakoutRooms: selectAmountBreakoutRooms(state),
    handleChange: ownProps.handleChange, 
});

export default connect(mapStateToProps)(BreakoutRoomsSelect);
