import React from "react";
// import { connect } from "react-redux";
// import { createStructuredSelector } from "reselect";

import withInventoryItemUtils from "./with-inventory-item-utils";

import { FirestoreProps } from "../firebase/firestore.types";
import { InteractionKeys, InteractionTypes } from "../firebase/realtime.types";
import { Metrics } from "../app.types";

const withGameUtils = WrappedComponent => {
    class WithGameUtils extends React.Component {
        constructor(props) {
            super(props);
            this.activeInterval = null;
        };

        assessInteractionBySnap = snap => {
            // console.log('qwer assessInteractionBySnap');
            const interaction = this.props.addInteractionBySnap(snap);
            if (!interaction) {
                return;
            };
            const { self } = this.props;
            if (interaction[InteractionKeys.TYPE] === InteractionTypes.TEAM_CHALLENGE_CORRECT) {
                const hotspotDetails = this.props.getHotspotsDetailsByInteraction(interaction);
                const hotspotDetailsId = hotspotDetails[FirestoreProps.ID];
                // console.log('qwer assessInteractionBySnap', hotspotDetails);
                this.props.addSolvedSolution(interaction);
                // this.props.fetchSolutionDependenciesByHotspotDetailsId(hotspotDetailsId)
                this.props.fetchInventoryItemsByHotspotDetailsId(hotspotDetailsId);
            };
            if (interaction[InteractionKeys.USER_ID] === self.id) {
                this.clearActiveInterval();
                console.log('qwer restarting inactivity count =>');
                this.activeInterval = setInterval(
                    this.inactivateUser, 
                    Math.round(Metrics.MAX_MINUTES_OR_SECONDS 
                        * Metrics.INACTIVITY_MINUTES 
                        * Metrics.MILLIS_PER_SECOND
                    )
                );
            };
        };
    
        clearActiveInterval = () => {
            if (!this.activeInterval) {
                return;
            }
            // console.log('qwer ending inactivity count!');
            clearInterval(this.activeInterval);
            this.activeInterval = null;
        };
        
        inactivateUser = () => {
            console.log("qwer inactivateUser DOES NOTHING");
        }
    
        render() {
            return <WrappedComponent 
                activeInterval={this.activeInterval}
                assessInteractionBySnap={this.assessInteractionBySnap}
                clearActiveInterval={this.clearActiveInterval}
                {...this.props} 
            />
        };
    };

    // const mapStateToProps = createStructuredSelector({
    // });
    
    // const mapDispatchToProps = dispatch => ({
    // });
    
    return withInventoryItemUtils(
        // connect(null, mapDispatchToProps)(
        WithGameUtils
        // )
    );
};

export default withGameUtils;