import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { SpanCentered, SpanItalic, TableCell, TableContainer } from "../global.styles";

import { selectEventId } from "../redux/event/event.selectors";
import { selectOtherTeams } from "../redux/team/team.selectors";
import { selectUsers } from "../redux/users/users.selectors";

import { UserKeys } from "../firebase/realtime.types";

const OtherTeamsList = ({ eventId, otherTeams, users }) => {
    let count = 0;
    const sortedOtherTeams = otherTeams ? Object.keys(otherTeams).sort().reduce(function (result, key) {
        count++;
        result[Number(key.replace(`${eventId}-`, ""))] = otherTeams[key];
        return result;
      }, {})
      : null;
    //   console.log('qwer', sortedOtherTeams);
      return <>
        <p>Other teams:</p>
        {count > 0 ?
            <>
                <TableContainer>
                    <TableCell widthPercent={10}>#</TableCell>
                    <TableCell widthPercent={50}>name</TableCell>
                    <TableCell widthPercent={10}><SpanCentered>#m</SpanCentered></TableCell>
                    <TableCell widthPercent={30}><SpanCentered>capt.</SpanCentered></TableCell>
                </TableContainer>
                <hr />
                {Object.entries(sortedOtherTeams).map(([key, value], i) => {
                    const teamNumber = key.replace(`${eventId}-`, "");
                    const name = value.name ? 
                        value.name 
                        : `Team ${teamNumber}`;
                    let captain = "...";
                    if (value.captainId) {
                        const captainId = value.captainId;
                        const filteredUsers = users.filter(user => user.id === captainId);
                        if (filteredUsers.length === 1) {
                            captain = filteredUsers[0][UserKeys.NAME];
                        };
                    }
                    return (<TableContainer key={key}>
                        <TableCell widthPercent={10} key={`${key}_1`}>{teamNumber}</TableCell>
                        <TableCell widthPercent={50} key={`${key}_2`}><SpanItalic>{name}</SpanItalic></TableCell>
                        <TableCell widthPercent={10} key={`${key}_3`}><SpanCentered>{value.numMembers}</SpanCentered></TableCell>
                        <TableCell widthPercent={30} key={`${key}_4`}><SpanCentered>{captain}</SpanCentered></TableCell>
                    </TableContainer>);
                })}
            </>
            :
            <p>none yet...</p>
        }
    </>;
};

const mapStateToProps = createStructuredSelector({
    eventId: selectEventId,
    otherTeams: selectOtherTeams,
    users: selectUsers,
});

export default connect(mapStateToProps)(OtherTeamsList);
