import React from "react";

import { ModalBackgroundContainer, ModalForegroundContainer } from "./components.styles";
import { FormButtonSmall } from "../global.styles";

const ModalWindow = ({ children, handleClose }) => (
    <ModalBackgroundContainer>
        <ModalForegroundContainer>
            {children}
            <form onSubmit={handleClose}>
                <FormButtonSmall type="submit" value="OK" />
            </form>
        </ModalForegroundContainer>
    </ModalBackgroundContainer>
);

export default ModalWindow;