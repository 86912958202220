import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { 
    BoxLeft,
    BoxRight,
    Column, 
    ContentContainer, 
    FormButton, 
    Row, 
    SpacedContainer,
    SpanBold,
} from "../global.styles";
import FormerActivityList from "../components/former-activity-list.component";
import ItemInputs from "../components/item/item-inputs.component";
import ItemOutputs from "../components/item/item-outputs.component";
import SceneTimer from "../components/scene-timer.component";
import withGameUtils from "./with-game-utils";
import withItemUtils from "./with-item-utils";

import { 
    CollectionNames, 
    ItemStates, 
    FirestoreProps, 
    ServerResponseStates,
} from "../firebase/firestore.types";
import { fetchDataByFieldPairs } from "../firebase/firestore.utils";
import { 
    InteractionTypes, 
    RealTimeKeys, 
    UserKeys, 
    RoomsCodes, 
} from "../firebase/realtime.types";
import { storeInteraction, storeUser } from "../firebase/realtime.utils";

import { 
    selectHintState,
    selectSceneIndex,
} from "../redux/game/game.selectors";
import { selectPassCode } from "../redux/event/event.selectors";
import { 
    selectHints, 
    selectMedia,
    selectStorageUrls,
} from "../redux/product/product.selectors";

import { addHint } from "../redux/product/product.actions";
import { setHintState } from "../redux/game/game.actions";
import { setOwnUser } from "../redux/users/users.actions";

import { formatChallengeResponses } from "../components/item/item.utils";

import { HintState, PageTypes } from "./page.types";
import { ModalHeaders, ModalMessages } from "../modal.types";

class ItemPage extends React.Component {
    goingBackInterval = null;

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            feedback: "",
        };
        this.responses = {};
        this.submitting = false;
        // console.log('qwer', this.state);
    };

    async componentDidMount() {
        const { hotspotDetailsId, assessInteractionBySnap } = this.props;
        const hotspotDetails = this.props.getHotspotsDetailsById(hotspotDetailsId);
        const pageType = PageTypes.ITEM;
        // this.props.fetchSolutionDependenciesByHotspotDetailsId(hotspotDetailsId);
        
        this.props.listenForInteractions(
            this.props.getBreakoutRoomThread(),
            snap => assessInteractionBySnap(snap)
        );
        const hotspotDetailsInputs = hotspotDetails[FirestoreProps.INPUTS];
        const hintState = !hotspotDetailsInputs || hotspotDetailsInputs === undefined ? 
            HintState.IRRELEVANT
            : HintState.NONE;
        // console.log('qwer', hotspotDetailsInputs, hintState);
        this.props.setHintState(hintState)
        this.props.setPageName(pageType);
    };

    componentWillUnmount() {
        this.props.clearActiveInterval();
        this.clearGoingBackInterval();
        this.props.setHintState(null);
    };

    clearGoingBackInterval = () => {
        if (this.goingBackInterval) {
            clearInterval(this.goingBackInterval);
            this.goingBackInterval = null;
        };
    };
    
    getHint = () => {
        const { hotspotDetailsId, hints } = this.props;
        // console.log('qwer hints', hotspotDetailsId, hints);
        const filteredHints = hints.filter(hint => hint[FirestoreProps.HOTSPOT_DETAILS_ID] === hotspotDetailsId);
        return filteredHints.length === 1 ? filteredHints[0] : null;
    };

    goToBack = () => {
        this.clearGoingBackInterval();
        const { eventPassCode, sceneIndex, history } = this.props;
        history.push(`/${eventPassCode}/${PageTypes.SCENE}${sceneIndex + 1}`);
    };

    // goToBackInABit = () => {
    //     const seconds = this.props.debug ? 
    //         3600 
    //         : Metrics.SECONDS_TO_SEE_CORRECT_ANSWERS;
    //     this.goingBackInterval = setInterval(this.goToBack, seconds * Metrics.MILLIS_PER_SECOND);
    // };

    handleAnswerSubmit = async e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        };
        this.submitting = true;
        const { hotspotDetailsId } = this.props;
        const guess = formatChallengeResponses(
            this.responses, 
            FirestoreProps.DOCUMENT_ID, 
            hotspotDetailsId
        );
        this.props.setServerResponseState(ServerResponseStates.WAITING);
        const solutions = await fetchDataByFieldPairs(CollectionNames.SOLUTIONS, guess);
        this.props.setServerResponseState(ServerResponseStates.DORMANT);
        // console.log('qwer', guess, solutions);
        this.props.checkSolutions(solutions, hotspotDetailsId);
    };

    handleChange = e => {
        const { value, name } = e.target;
        this.responses[name] = value;
    };

    handleMinorHint = async e => {
        e.preventDefault();
        // console.log('qwer', this.submitting);
        if (this.submitting) {
            return;
        };
        this.submitting = true;
        this.props.setServerResponseState(ServerResponseStates.WAITING);
        const hints = await fetchDataByFieldPairs(
            CollectionNames.HINTS, 
            [{ 
                key: FirestoreProps.HOTSPOT_DETAILS_ID, 
                value: this.props.hotspotDetailsId
            }]
        );
        this.props.setServerResponseState(ServerResponseStates.DORMANT);
        // console.log('qwer', hints)
        if (hints && hints.length === 1) {
            this.props.addHint(hints[0]);
            this.props.setHintState(HintState.MINOR);
            const { hotspotDetailsId, sceneId, self } = this.props;
            
            const result = await storeInteraction(
                self.id, 
                PageTypes.ITEM, 
                InteractionTypes.MINOR_HINT, 
                this.props.getBreakoutRoomThread(),
                sceneId,
                { [FirestoreProps.HOTSPOT_DETAILS_ID]: hotspotDetailsId },
            );
            if (!result) {
                this.props.setModalMessages({ header: ModalHeaders.OOPS, message: ModalMessages.BAD_DATA + " (#770)" });
            };
        } else {
            this.submitting = false;
        };
    };
    
    handleMajorHint = async e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        };
        this.submitting = true;
        this.props.setHintState(HintState.MAJOR);
        const { hotspotDetailsId, sceneId, self } = this.props;
        
        const result = await storeInteraction(
            self.id, 
            PageTypes.ITEM, 
            InteractionTypes.MAJOR_HINT, 
            this.props.getBreakoutRoomThread(),
            sceneId,
            { [FirestoreProps.HOTSPOT_DETAILS_ID]: hotspotDetailsId },
        );
        if (!result) {
            this.props.setModalMessages({ header: ModalHeaders.OOPS, message: ModalMessages.BAD_DATA + " (#708)" });
        };
    };

    handleSceneTimerComplete = async () => {
        const { eventId, self } = this.props;
        const updatedUser = await storeUser(
            self[UserKeys.NAME], 
            self[UserKeys.BREAKOUT_ROOM_NUMBER], 
            RoomsCodes.LOUNGE,
            self[UserKeys.SCENE_INDEX], 
            eventId + "/" + RealTimeKeys.USERS, 
            self.id
        );
        if (updatedUser) {
            this.props.setOwnUser(updatedUser);
            this.goToBack();
        } else {
            this.props.setModalMessages({ 
                header: ModalHeaders.OOPS, 
                message: ModalMessages.BAD_DATA + " (#938)" 
             });
            return;
        };
    };

    render() {
        this.submitting = false;
        const { 
            clientTimeDifference, 
            hintState, 
            hotspotDetailsId,
            inventoryItems,
            items,
            media,
            storageUrls,
            sceneId, 
        } = this.props;
        const { feedback } = this.state;
        const hotspotDetails = this.props.getHotspotsDetailsById(hotspotDetailsId)
        // console.log('qwer rend', hotspotDetailsId);
        const hotspotDetailsState = this.props.getHotspotsDetailsState(hotspotDetails);
        const dependentSolutionsSolved = this.props.getDependentSolutionsSolved(hotspotDetails);
        // console.log('qwer render', hotspotDetails, hotspotDetailsState, dependentSolutionsSolved);
        const hint = hintState === HintState.MINOR || hintState === HintState.MAJOR ? 
            this.getHint() 
            : null;
        const itemId = hotspotDetails[FirestoreProps.ITEM_ID];
        const filteredItems = items ?
            items.filter(item => item.id === itemId)
            : null;
        const item = filteredItems && filteredItems.length === 1 ?
            filteredItems[0]
            : null;
        // console.log('qwer rend', dependentSolutionsSolved, hotspotDetailsState);
        return (<>
            {hotspotDetails ? 
                <ContentContainer> 
                    <SpacedContainer>
                        <BoxLeft widthPercent={50}>
                            <SpanBold>
                                {dependentSolutionsSolved ?
                                    item[FirestoreProps.TITLE]
                                    : "Access Denied"
                                }
                            </SpanBold>
                        </BoxLeft>
                        <BoxRight widthPercent={50}>
                            <form onSubmit={this.goToBack}>
                                <FormButton type="submit" value="Back" />
                            </form>
                        </BoxRight>
                    </SpacedContainer>
                    <Row>
                        <Column widthPercent={50}>
                            <ItemOutputs
                                hotspotDetails={hotspotDetails}
                                item={item}
                                items={items}
                                media={media}
                                storageUrls={storageUrls}
                                itemState={hotspotDetailsState}
                            />
                        </Column>
                        <Column widthPercent={50}>
                            {hotspotDetailsState === ItemStates.DEFAULT ?
                                <>
                                    <ItemInputs
                                        uniqueId={hotspotDetails[FirestoreProps.ID]} 
                                        inputs={hotspotDetails[FirestoreProps.INPUTS]}
                                        item={item}
                                        inventoryItems={inventoryItems}
                                        handleChange={this.handleChange} 
                                        handleSubmit={this.handleAnswerSubmit}
                                        handleInventoryItemChange={this.props.handleInventoryItemChange}
                                        />
                                    {hintState === HintState.NONE ?
                                        <form onSubmit={this.handleMinorHint}>
                                            <FormButton type="submit" value="Need a hint?" />
                                        </form>
                                        : null
                                    }
                                    {hintState === HintState.MINOR && hint !== null ?
                                        <>
                                            <p>{hint[HintState.MINOR]}</p>
                                            <form onSubmit={this.handleMajorHint}>
                                                <FormButton type="submit" value="Stronger hint?" />
                                            </form>
                                        </>
                                        : null
                                    }
                                    {hintState === HintState.MAJOR && hint !== null ?
                                        <p>{hint[HintState.MAJOR]}</p>
                                        : null
                                    }
                                </>
                                : null
                            }
                            {feedback && feedback.length > 0 ? 
                                <p>{feedback}</p>
                                : null
                            }
                            <SceneTimer
                                clientTimeDifference={clientTimeDifference}
                                sceneId={sceneId}
                                useSceneTime={true}
                                handleComplete={this.handleSceneTimerComplete}
                            />
                            <FormerActivityList 
                                hotspotDetailsId={hotspotDetails[FirestoreProps.ID]}
                            />
                        </Column>
                    </Row>
                </ContentContainer>
                :
                <p>Hmm, can't find that item, sorry.</p>
            }
        </>);
    };
};

const mapStateToProps = createStructuredSelector({
    eventPassCode: selectPassCode,
    hints: selectHints,
    hintState: selectHintState,
    media: selectMedia,
    storageUrls: selectStorageUrls,
    sceneIndex: selectSceneIndex,
});

const mapDispatchToProps = dispatch => ({
    addHint: value => dispatch(addHint(value)),
    setHintState: value => dispatch(setHintState(value)),
    setOwnUser: value => dispatch(setOwnUser(value)),
});

export default withItemUtils(
    withGameUtils(
        connect(mapStateToProps, mapDispatchToProps)(ItemPage)
    )
);
