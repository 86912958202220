import EventActionTypes from "./event.types";
import { setFirebaseElements } from "../redux.utils";

const INITIAL_STATE = {
    eventId: null,
    events: null,
};

const eventReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EventActionTypes.ADD_EVENTS:
            return { 
                ...state,
                events: setFirebaseElements(state.events, action.payload)
            };
        case EventActionTypes.SET_EVENT_ID:
            return { ...state, eventId: action.payload };
        case EventActionTypes.SET_EVENT:
            const event = action.payload;
            return { 
                ...state,
                eventId: event.id,
                events: setFirebaseElements(state.events, [event])
            };
        default:
            return state;
    };
};

export default eventReducer;