import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import BreakoutRoomUsers from "../components/breakout-room-users.component";
import { Column, ContentContainer, FormButton, Row } from "../global.styles";
import VideoPlayer from "../components/video-player.component";
import withIntroUtils from "./with-intro-utils";

import { InteractionTypes, RealTimeKeys, RoomsCodes, UserKeys } from "../firebase/realtime.types";
import { fetchInteractions, storeInteraction, storeUser } from "../firebase/realtime.utils";
import { CollectionNames, ItemStates, FirestoreProps, InternalProps, ServerResponseStates } from "../firebase/firestore.types";
import { fetchDatumByDocName, fetchDataByFieldPairs, storage, storageUrl } from "../firebase/firestore.utils";

import { selectImageMaps, selectScenes } from "../redux/product/product.selectors";
import { selectImageMapProperties, selectPageName } from "../redux/game/game.selectors";

import { 
    addImageMapProperties, 
    addInventoryItem, 
    addInventoryItemState, 
    completeInventoryItemsUsage,  
} from "../redux/game/game.actions";
import { addHotspots, addImageMap } from "../redux/product/product.actions";

import { ModalHeaders, ModalMessages } from "../modal.types";
import { PageTypes } from "./page.types";

class IntroPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            videoEnded: false,
            videoUrl: null,
            exitInteractionId: null,
            imageMap: null,
        };
        this.submitting = false;
    };

    async componentDidMount() {
        const { abbreviation, debug, pageName, sceneId, self } = this.props;
        const thread = this.props.getBreakoutRoomThread();
        const pageType = PageTypes.INTRO;
        const existingInteractions = await fetchInteractions(thread, self.id, pageType);
        if (existingInteractions.interactions) {
            this.props.addInteractions(existingInteractions.interactions);
        };
        this.setState({ exitInteractionId: existingInteractions.exitId });
        
        if (pageName === PageTypes.INTRO) {
                storeInteraction(
                self.id, 
                pageType, 
                InteractionTypes.ENTRY, 
                thread, 
                sceneId, 
                null,
                existingInteractions.entryId
            );
        };
        
        const scenes = this.props.scenes.filter(scene => scene.id === sceneId);
        if (scenes.length !== 1) {
            this.props.setModalMessages({ header: ModalHeaders.OOPS, message: ModalMessages.FATAL });
            return;
        };
        const scene = scenes[0];

        const videoDoc = debug ? 
            "thomas.mp4"
            : `${abbreviation}/${scene[FirestoreProps.INTRO_VIDEO]}`;
        storage.refFromURL(storageUrl).child(videoDoc)
            .getDownloadURL()
            .then(url => this.setState({ videoUrl: url }))
            .catch(error => {
                this.props.setModalMessages({ header: ModalHeaders.OOPS, message: ModalMessages.BAD_DATA + " (#598)" });
        });

        this.props.setServerResponseState(ServerResponseStates.WAITING);
        const imageMaps = await fetchDataByFieldPairs(
            CollectionNames.IMAGE_MAPS, 
            [{ 
                key: FirestoreProps.SCENE_ID, 
                value: scene.id
            }]
        );
        this.props.setServerResponseState(ServerResponseStates.DORMANT);
        if (imageMaps && imageMaps.length === 1) {
            const imageMap = imageMaps[0];
            this.setState({ imageMap });
            this.props.addImageMap(imageMap);
            this.props.setServerResponseState(ServerResponseStates.WAITING);
            const hotspots = await fetchDataByFieldPairs(
                CollectionNames.HOTSPOTS, 
                [{ 
                    key: FirestoreProps.IMAGE_MAP_ID, 
                    value: imageMap.id
                }]
            );
            this.props.setServerResponseState(ServerResponseStates.DORMANT);
            // console.log('qwer', hotspots);
            if (!hotspots) {
                this.props.setModalMessages({ header: ModalHeaders.OOPS, message: "no hotspots (#599)" });
                return;
            };
            this.props.addHotspots(hotspots);
            storage.refFromURL(storageUrl).child(`${abbreviation}/${imageMap.image}`)
                .getDownloadURL()
                .then(url => {
                    const preloadImage = new Image();
                    preloadImage.onload = () => {
                        this.props.addImageMapProperties({
                            url,
                            width: preloadImage.width,
                            height: preloadImage.height,
                        });
                    };
                    preloadImage.src = url;
                })
                .catch(error => {
                    this.props.setModalMessages({ header: ModalHeaders.OOPS, message: ModalMessages.BAD_DATA + " (#798)" });
            });
        } else {
            console.log('qwer image map fail');
        };

        if (scene[FirestoreProps.INVENTORY_ITEM_IDS]) {
            const inventoryItemIds = scene[FirestoreProps.INVENTORY_ITEM_IDS];
            const toRemove = inventoryItemIds[FirestoreProps.TO_REMOVE];
            if (toRemove && toRemove.length > 0) {
                this.props.completeInventoryItemsUsage(toRemove);
            };
            const toAdd = inventoryItemIds[FirestoreProps.TO_ADD];
            if (toAdd && toAdd.length > 0) {
                const { addInventoryItem, addInventoryItemState } = this.props;
                for (const inventoryItemId of toAdd) {
                    fetchDatumByDocName(
                        CollectionNames.ITEMS, 
                        inventoryItemId,
                        inventoryItem => {
                            // console.log('qwer inventoryItem', inventoryItem);
                            addInventoryItem(inventoryItem);
                            addInventoryItemState({
                                [FirestoreProps.ID]: inventoryItem[FirestoreProps.ID],
                                [InternalProps.STATE]: ItemStates.DEFAULT,
                            });
                            this.props.fetchStorageUrl(inventoryItem[FirestoreProps.THUMBNAIL]);
                            const mediaIds = inventoryItem[FirestoreProps.MEDIA_IDS];
                            if (mediaIds) {
                                this.props.assessMediaIdCollection(mediaIds[ItemStates.BLOCKED]);
                                this.props.assessMediaIdCollection(mediaIds[ItemStates.DEFAULT]);
                                this.props.assessMediaIdCollection(mediaIds[ItemStates.UNLOCKED]);
                            };
                        }
                    );
                };
            };
        };
        this.props.setPageName(pageType);
    };
    
    handleEnded = () => {
        this.setState({ videoEnded: true });
    };

    handlePlay = async e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        };
        this.submitting = true;
        const { eventId, self } = this.props;
        const updatedUser = await storeUser(
            self[UserKeys.NAME], 
            self[UserKeys.BREAKOUT_ROOM_NUMBER], 
            RoomsCodes.GAME,
            self[UserKeys.SCENE_INDEX], 
            eventId + "/" + RealTimeKeys.USERS, 
            self.id
        );
        if (updatedUser) {
            this.props.setOwnUser(updatedUser);
        } else {
            this.props.setModalMessages({ header: ModalHeaders.OOPS, message: ModalMessages.BAD_DATA + " (#935)" });
            return;
        };
    };

    render() {
        this.submitting = false;
        const { videoUrl, videoEnded } = this.state;
        const { imageMapProperties } = this.props;
        return (<ContentContainer>
            <Row>
                <Column widthPercent={66.67}>
                    {videoUrl ?
                        <VideoPlayer 
                            url={videoUrl} 
                            handleEnded={this.handleEnded}
                        />
                        : null
                    }
                </Column>
                <Column widthPercent={33.33}>
                    <BreakoutRoomUsers />
                    {videoEnded && imageMapProperties ?
                        <>
                            <br /> 
                            <form onSubmit={this.handlePlay}>
                                <FormButton type="submit" value="Play" />
                            </form>
                        </>
                        : null
                    }
                </Column>
            </Row>
        </ContentContainer>);
    };
};

const mapStateToProps = createStructuredSelector({
    imageMapProperties: selectImageMapProperties,
    imageMaps: selectImageMaps,
    scenes: selectScenes,
    pageName: selectPageName,
});

const mapDispatchToProps = dispatch => ({
    addHotspots: value => dispatch(addHotspots(value)),
    addImageMap: value => dispatch(addImageMap(value)),
    addImageMapProperties: value => dispatch(addImageMapProperties(value)),
    addInventoryItem: value => dispatch(addInventoryItem(value)),
    addInventoryItemState: value => dispatch(addInventoryItemState(value)),
    completeInventoryItemsUsage: value => dispatch(completeInventoryItemsUsage(value)),
});

export default withIntroUtils(
    connect(mapStateToProps, mapDispatchToProps)(IntroPage)
);
