import GameActionTypes from "./game.types";

export const addCompletedSceneId = value => ({
    type: GameActionTypes.ADD_COMPLETED_SCENE_ID,
    payload: value
});

export const addCompletedScenesIds = value => ({
    type: GameActionTypes.ADD_COMPLETED_SCENES_IDS,
    payload: value
});

export const addImageMapProperties = value => ({
    type: GameActionTypes.ADD_IMAGE_MAP_PROPERTIES,
    payload: value
});

export const addInventoryItem = value => ({
    type: GameActionTypes.ADD_INVENTORY_ITEM,
    payload: value
});

export const addInventoryItemState = value => ({
    type: GameActionTypes.ADD_INVENTORY_ITEM_STATE,
    payload: value
});

export const addSolvedSolution = value => ({
    type: GameActionTypes.ADD_SOLVED_SOLUTION,
    payload: value
});

export const addSolvedSolutions = value => ({
    type: GameActionTypes.ADD_SOLVED_SOLUTIONS,
    payload: value
});

export const setClientTimeDifference = value => ({
    type: GameActionTypes.SET_CLIENT_TIME_DIFFERENCE,
    payload: value
});

export const setHintState = value => ({
    type: GameActionTypes.SET_HINT_STATE,
    payload: value
});

export const setHotspotDetailsId = value => ({
    type: GameActionTypes.SET_HOTSPOT_DETAILS_ID,
    payload: value
});

export const setPageName = value => ({
    type: GameActionTypes.SET_PAGE_NAME,
    payload: value
});

export const setSceneId = value => ({
    type: GameActionTypes.SET_SCENE_ID,
    payload: value
});

export const setSceneIndex = value => ({
    type: GameActionTypes.SET_SCENE_INDEX,
    payload: value
});

export const completeInventoryItemsUsage = value => ({
    type: GameActionTypes.COMPLETE_INVENTORY_ITEMS_USAGE,
    payload: value
});
