export const AdminProps = {
    DESELECT_EVENT: "Deselect event",
    MONITOR: "Monitor event live",
    UPDATE: "Edit event details",
};

export const Metrics = {
    COLUMN_CHANGING_THRESHOLD: 770,
    INACTIVITY_MINUTES: 1.5,
    MIN_SCREEN_WIDTH: 415,
    MAX_COLUMNS: 12,
    MAX_HOURS: 24,
    MAX_INTERACTION_SECONDS: 4,
    MAX_MINUTES_OR_SECONDS: 60,
    MAX_SCREEN_WIDTH: 824,
    MILLIS_PER_SECOND: 1000,
    SECONDS_TO_SEE_CORRECT_ANSWERS: 6,
};

export const Generics = {
    SPLITTER: "|",
    PLACEHOLDER_IMAGE_URL: "https://jetmethods.com/wp-content/uploads/2013/08/placeholder.png",
};
