import TeamActionTypes from "./team.types";

export const setCaptainId = value => ({
    type: TeamActionTypes.SET_CAPTAIN_ID,
    payload: value
});

export const setOtherTeams = value => ({
    type: TeamActionTypes.ADD_OTHER_TEAMS,
    payload: value
});

export const setTeamName = value => ({
    type: TeamActionTypes.SET_TEAM_NAME,
    payload: value
});
