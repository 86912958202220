import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Switch, Route } from "react-router-dom";

import GamePage from "./game.component";
import IntroPage from "./intro.component";
import LobbyPage from "./lobby.component";
import LoungePage from "./lounge.component";

import withGlobalUtils from "./with-global-utils";

import { CollectionNames } from "../firebase/firestore.types";
import { createInteraction, storeInactivatedUser } from "../firebase/realtime.utils";
import { listenForUpdates, realTime, formatWithId } from "../firebase/firestore.utils";
import { InteractionTypes, RealTimeEvents, RealTimeKeys, RoomsCodes, UserKeys } from "../firebase/realtime.types";

import { selectSelf, selectSelfBreakoutRoomNumber } from "../redux/users/users.selectors";
import { selectEventId } from "../redux/event/event.selectors";
import { selectPageName, selectSceneId } from "../redux/game/game.selectors";

import { addOtherUser } from "../redux/users/users.actions";
import { setEvent } from "../redux/event/event.actions";

import { getDateFromClientTimeDifference } from "../app.utils";
import { ModalHeaders, ModalMessages } from "../modal.types";

class ScenePage extends React.Component {
    constructor(props) {
        super(props);
        
        this.sceneStartListener = null;
        this.usersListener = null;
    };

    async componentDidMount() {
        const { eventId } = this.props;
        const scenePage = this;
        try {
            this.usersListener = await realTime.ref(this.getUserThread());
            // this.usersListener.once(RealTimeEvents.VALUE).then(snap => scenePage.assessUser(snap));
            this.usersListener.on(RealTimeEvents.CHILD_ADDED, snap => scenePage.assessUser(snap));
            this.usersListener.on(RealTimeEvents.CHILD_CHANGED, snap => scenePage.assessUser(snap));
        } catch (error) {
            this.props.setModalMessages({ 
                header: ModalHeaders.OOPS, 
                message: ModalMessages.SUBSCRIPTION_FAIL + " (#795)" 
            });
        };

        this.sceneStartListener = await listenForUpdates(
            CollectionNames.EVENTS, 
            eventId,
            event => this.props.setEvent(formatWithId(eventId, event))
        );
    };
    
    componentWillUnmount() {
        if (this.usersListener) {
            this.usersListener.off();
            this.usersListener = null;
        };
        if (this.sceneStartListener) {
            this.sceneStartListener();
            this.sceneStartListener = null;
        };
    };

    assessUser = snap => {
        if (!snap) {
            return;
        };
        const rawUser = snap.val();
        if (!rawUser) {
            return;
        };
        // console.log('qwer', rawUser, this.props.breakoutRoomNumber);
        if (this.props.breakoutRoomNumber === rawUser[RealTimeKeys.BREAKOUT_ROOM]) {
            const userId = snap.key;
            const user = formatWithId(userId, rawUser);
            this.props.addOtherUser(user);
            // console.log('qwer active?', user[UserKeys.ACTIVE]);
            if (!user[UserKeys.ACTIVE]) {
                const { addInteraction, clientTimeDifference, pageName } = this.props;
                addInteraction(
                    createInteraction(
                        userId,
                        pageName,
                        InteractionTypes.INACTIVE,
                        getDateFromClientTimeDifference(clientTimeDifference),
                        this.props.sceneId
                    )
                );
            };
        };
    };

    getUserThread = () => `${this.props.eventId}/${RealTimeKeys.USERS}`;

    inactivateUser = () => {
        const { self } = this.props;
        console.log('qwer inactivating self');
        this.props.clearActiveInterval();
        storeInactivatedUser(self, this.getUserThread(), self.id);
    };

    render() {
        const roomCode = this.props.self[UserKeys.ROOM_CODE];
        return (<>
            <Switch>
                {roomCode === RoomsCodes.INTRO ? 
                    <Route exact path={`/:eventPassCode/:scene`} component={IntroPage} />
                    : null}
                {roomCode === RoomsCodes.GAME ? 
                    <Route exact path={`/:eventPassCode/:scene`} component={GamePage} />
                    : null}
                {roomCode === RoomsCodes.LOBBY ? 
                    <Route exact path={`/:eventPassCode/:scene`} component={LobbyPage} />
                    : null}
                {roomCode === RoomsCodes.LOUNGE ? 
                    <Route exact path={`/:eventPassCode/:scene`} component={LoungePage} />
                    : null}
            </Switch>
        </>);
    };
}

const mapStateToProps = createStructuredSelector({
    breakoutRoomNumber: selectSelfBreakoutRoomNumber,
    eventId: selectEventId,
    pageName: selectPageName,
    sceneId: selectSceneId,
    self: selectSelf,
});

const mapDispatchToProps = dispatch => ({
    addOtherUser: value => dispatch(addOtherUser(value)),
    setEvent: value => dispatch(setEvent(value)),
});

export default withGlobalUtils(
    connect(mapStateToProps, mapDispatchToProps)(ScenePage)
);
