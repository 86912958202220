import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Switch, Route } from "react-router-dom";

import InteractionsDialog from "./components/interactions-dialog.component";
import ModalOverlay from "./components/modal-overlay.component";

import { ServerResponseStates } from "./firebase/firestore.types";
import { InteractionKeys } from "./firebase/realtime.types";

import AdminLoginPage from "./pages/admin-login.component";
import HomePage from "./pages/home.component";
import EmailPage from "./pages/email.component";
import EmailConfirmationPage from "./pages/email-confirmation.component";
import ErrorPage from "./pages/error.component";
import ItemPage from "./pages/item.component";
import InventoryItemsPage from "./pages/inventory-items.component";
import NamePage from "./pages/name.component";
import ProductPage from "./pages/product.component";
import ProductCodePage from "./pages/product-code.component";
import RejoinPage from "./pages/rejoin.component";
import ScenePage from "./pages/scene.component";
import TempPage from "./pages/temp.component";
import UpdateEventPage from "./pages/update-event.component";
import MonitorEventPage from "./pages/monitor-event.component";

import { setModalMessages, setServerResponseState } from "./redux/admin/admin.actions";

import { selectPageName, selectSceneId } from "./redux/game/game.selectors";
import { selectServerResponseState } from "./redux/admin/admin.selectors";

import { PageTypes } from "./pages/page.types";
import { GlobalStyle } from "./global.styles";
import { ModalHeaders, ModalMessages } from "./modal.types";
import { selectInteractions } from "./redux/interactions/interactions.selectors";

class App extends React.Component {
    timeOutTimer = null;

    getInteractionsFiltersByPage = () => {
        const { pageName, sceneId } = this.props;
        const sceneIdObject = { [InteractionKeys.SCENE_ID]: sceneId };
        return pageName === PageTypes.LOBBY ?
            [
                { [InteractionKeys.PAGE]: PageTypes.LOBBY }, 
                sceneIdObject,
            ]
            :
            [sceneIdObject];
    };

    startTimeOutTimer = () => {
        this.stopTimeOutTimer();
        this.timeOutTimer = setInterval(this.timeOut, 5000);
        // console.log('qwer start');
    };

    stopTimeOutTimer = () => {
        if (!this.timeOutTimer) {
            return;
        }
        clearInterval(this.timeOutTimer);
        this.timeOutTimer = null;
        // console.log('qwer stop');
    };
    
    timeOut = e => {
        this.props.setModalMessages({
            header: ModalHeaders.OOPS, 
            message: ModalMessages.SERVER_TIME_OUT
        });
        this.props.setServerResponseState(ServerResponseStates.TIME_OUT);
        this.stopTimeOutTimer();
    };
    
    render() {
        const { sceneId, serverResponseState } = this.props;
        switch (serverResponseState) {
            case ServerResponseStates.WAITING:      this.startTimeOutTimer();       break;
            case ServerResponseStates.DORMANT:      this.stopTimeOutTimer();        break;
            default: break;
        };
        // console.log('qwer render', serverResponseState);
        return (<>
            <GlobalStyle />
            <div className="app-container">
                <Switch>
                    <Route exact path={`/${PageTypes.ADMIN}`} component={AdminLoginPage} />
                    <Route exact path={`/${PageTypes.ADMIN}/temp`} component={TempPage} />
                    <Route exact path={`/${PageTypes.ADMIN}/${PageTypes.UPDATE_EVENT}`} component={UpdateEventPage} />
                    <Route exact path={`/${PageTypes.ADMIN}/${PageTypes.MONITOR_EVENT}`} component={MonitorEventPage} />
                    <Route exact path="/" component={HomePage} />
                    <Route exact path={`/${PageTypes.ERROR}`} component={ErrorPage} />
                    <Route exact path={`/${PageTypes.PRODUCT}`} component={ProductCodePage} />
                    <Route exact path={`/${PageTypes.PRODUCT}/:productPassCode`} component={ProductPage} />
                    <Route exact path={`/${PageTypes.NAME}/:eventPassCode`} component={NamePage} />
                    <Route exact path={`/${PageTypes.EMAIL}/:eventPassCode`} component={EmailPage} />
                    <Route exact path={`/${PageTypes.EMAIL_CONFIRMATION}/:eventPassCode`} component={EmailConfirmationPage} />
                    <Route exact path={`/${PageTypes.REJOIN}/:eventPassCode/:userId`} component={RejoinPage} />
                    <Route exact path={`/:eventPassCode/:scene`} component={ScenePage} />
                    <Route exact path={`/:eventPassCode/:scene/${PageTypes.INVENTORY_ITEMS}`} component={InventoryItemsPage} />
                    <Route exact path={`/:eventPassCode/:scene/:itemTitle`} component={ItemPage} />
                </Switch>
            </div>
            <InteractionsDialog 
                filters={this.getInteractionsFiltersByPage()} 
                sceneId={sceneId}
            />
            <ModalOverlay />
        </>);    
    };
};

const mapStateToProps = createStructuredSelector({
    interactions: selectInteractions,
    pageName: selectPageName,
    sceneId: selectSceneId,
    serverResponseState: selectServerResponseState, 
});

const mapDispatchToProps = dispatch => ({
    setModalMessages: value => dispatch(setModalMessages(value)),
    setServerResponseState: value => dispatch(setServerResponseState(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);





















/*
TO DO
    herehere => test if create event admin page actually creates an event
    live event admin page...
        interactions
        other updates
        change breakout room for a user
    progressive hints for slow teams
    create event admin page...
    finishing page...
    clicking leave in header to clear out data everywhere for player in question
        reassign captaincy if captain (in other teams data too)
        remove from team somehow (in other teams data too)
    team and team-certification collections to self-delete 

FROM UNTITLED TXT
    view list of former interactions

BUGS
    interactions dialog appearing above modal overlay
    scene start date inputs appearing wrong for both update and create event admin scenarios
    going to another url and then coming back on android causes site to default to home page and go blank
    sometimes breakout rooms dropdown on name page has no options
    sometimes scene timer not starting up
    
CURRENT UNCAUGHT FAILURES
    all pages need assessment as to whether they redirect based upon state (perhaps remote state as a user may try to view in more than one browsers/tabs/etc)
    onTeamCertificationCreate will need its set()s and add()s error catching

INTERACTIONS IDEAS
    only one PERSON A is looking/doing/etc per dialog window
    limit the number of onscreen interactions to N




NICE TO HAVE / BEYOND THE MVP
    scaling for the svg
    modular scenes on update event page
    sound effects for buttons etc
    interactions dialog needs to reset its clock when new interactions appear (and/or tick more often?)
    there should only be one "PERSON A is looking/doing/etc..."" interaction per dialog window
    view list of former interactions on game page
    turn iventory item thumbnail urls into id'ed item refs
    preloading assets in lobby and intro screens is a bit of mess, refactor
    persist hints in redux
    auto-delete unneeded docs in team and team-certification collections in db
    choice of feedback messages for default ones like "That doesn't seem to do anything"
    make imagemaps into a item type so they can potentially endless recurse (you could have a scene that has several desks in a room and when you click a hotspot you focus in on a desk that is another image map with several items on it?)
    move the removal of inventory items from the intro page to the lounge page
    placeholders for loading images
    item-inputs.component has  objectSets.map(objectSet => loop that calls the same number of times as there are objectsin the set. this is waaay too much, simplify somehow
    add modal spinner whilst fetching data?
    move getInteractionsFiltersByPage from App.js inside InteractionsDialog
    rename challenges to items in firestore collection
    introduce shortcuts to firestore (eg 'afwt' instead of answersForWholeTeam) ?
    don't get firestore data if we've already got it (adding checks in reducers to stop existing addtions)
    change refs to .id to [FieldNames.ID]
    move realtime database location to europe
    disable some functions on video player intro page
    slight pause between team name being set and auto push to intro page
    TeamStates and CollectionNames are duplicated in index.ts and elsewhere, can we have just one?
    check whether game works with a minPlayersPerTeam of 1
    clear intervaland setTimer to { ...timer, handler: null } in scene timer ?
    setState often overridden with a page refresh so perhaps we need more page-centric reducers like the lobby one?
    lounge page client questions
    more challengetypes
    capacity to view past interactions on game page ?
    move real time interactions like timestamps and certification to the realtime database
    check clientTimeDifference on all pages ?
    spinning modal whilst await/asycns are ongoing ?
    addInteraction is called *a lot* should this be reduced ?
    refactor the subpages structure of login and scene pages so that they are less piecemeal and more consistent
    shared/repeated functionality (ie: setPageName, handleChange, getInventoryItems in item and intro pages) needs addressing with HOCs (search for "// HOC" in this project to find examples of repeated code)
    (server side?) functions for filtering:
        fetching single users
        hints by severity 
        users by breakout room
        challenges/etc by scene

MISC OTHER
    we will probably need actual human support for users in game time
    gdpr

NOMENCLATURE
    product: a ccc offering eg enigma challenge
    event: a pre-booked experience eg enigma challenge for siemens in maidenhead at 12pm on sep 4
    game: the totality of an event eg the act of playing enigma challenge with siemens in maidenhead etc.
    scene: a coherent part of a game eg a room to escape from in a game comprised of multiple rooms
    challenge: a puzzle in a scene eg codebreaking a lock in an escape room
*/
