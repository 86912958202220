import React from "react";

import { FormButton, FormLabel, FormTextField } from "../global.styles";

const EventCodeForm = ({ eventCode, handleChange, handleSubmit }) => (
    <>
        <form onSubmit={handleSubmit}>
            <FormLabel>Event code:</FormLabel>
            <FormTextField 
                name="eventCode" 
                type="text" 
                value={eventCode} 
                onChange={handleChange} 
                required 
            />
            {/* <br /> */}
            <FormButton type="submit" value="Submit" />
        </form>
    </>
);

export default EventCodeForm;
