import React from "react";
import { FormButton, FormLabel, FormTextField, SpanBold } from "../global.styles";

const AdminLoginForm = ({ emailAddress, password, handleChange, handleSubmit }) => (
    <div>
        <p><SpanBold>Staff login</SpanBold></p>
        <form onSubmit={handleSubmit}>
            <FormLabel>Email:</FormLabel>
            <FormTextField
                name="emailAddress" 
                type="text" 
                value={emailAddress} 
                onChange={handleChange} 
                label="email" 
                required={true}
            />
            <FormLabel>Password:</FormLabel>
            <FormTextField 
                name="password" 
                type="password" 
                value={password} 
                onChange={handleChange} 
                label="password" 
                required={true}
            />
            <FormButton type="submit" value="Submit" />
        </form>
    </div>
);

export default AdminLoginForm;
