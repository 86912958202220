import React from "react";

const ProductCodeForm = ({ productCode, handleChange, handleSubmit }) => (
    <div>
        <form onSubmit={handleSubmit}>
            <input 
                 
                name="productCode" 
                type="text" 
                value={productCode} 
                onChange={handleChange} 
                // label="code" 
                required 
            />
            <br />
            <input type="submit" value=">>>" />
        </form>
    </div>
);

export default ProductCodeForm;
