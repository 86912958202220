import TeamActionTypes from "./team.types";

const INITIAL_STATE = {
    captainId: null,
    otherTeams: null,
    teamName: null,
};

const teamReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TeamActionTypes.SET_CAPTAIN_ID:
            return { ...state, captainId: action.payload };
        case TeamActionTypes.ADD_OTHER_TEAMS:
            return { ...state, otherTeams: action.payload };
        case TeamActionTypes.SET_TEAM_NAME:
            return { ...state, teamName: action.payload };
        default:
            return state;
        }
};

export default teamReducer;