import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import ProductCodeForm from "../components/product-code-form.component";
import withUserUtils from "./with-user-utils";

import { selectProductPassCode } from "../redux/product/product.selectors";

import { PageTypes } from "./page.types";


class ProductCodePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            productCode: "",
        };
        this.submitting = false;
    };

    async componentDidMount() {
        this.props.setPageName(PageTypes.PRODUCT_CODE);
    };

    goToProduct = () => {
        const { history, productCode } = this.props;
        const location = `/${PageTypes.PRODUCT}/${productCode}`;
        history.push(location);
    };

    handleChange = e => {
        const { value, name } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = async e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        this.goToProduct();
    };

    render() {
        this.submitting = false;
        return (<div>
            <p>Product code:</p>
            <ProductCodeForm
                productCode={this.state.productCode}
                handleChange={this.handleChange} 
                handleSubmit={this.handleSubmit} 
            />
        </div>);
    };
};

const mapStateToProps = createStructuredSelector({
    productPassCode: selectProductPassCode,
});

// const mapDispatchToProps = dispatch => ({
// });

export default withUserUtils(
    connect(mapStateToProps)(ProductCodePage)
);
