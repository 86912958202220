import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { 
    Column, 
    ContentContainer, 
    FormButton, 
    FormLabel, 
    FormSelect, 
    FormTextField, 
    Row 
} from "../global.styles";
import DateFormSection from "../components/date-form-section.component";
import withAdminUtils from "./with-admin-utils";

import { addFirestoreDatum, fetchDataByFieldPairs, updateFirestoreDatum } from "../firebase/firestore.utils";
import { CollectionNames, FirestoreProps, InternalProps } from "../firebase/firestore.types";
import { InteractionTypes, RealTimeKeys } from "../firebase/realtime.types";
import { storeInteraction } from "../firebase/realtime.utils";

import { selectAdminId } from "../redux/admin/admin.selectors";
import { selectScenes } from "../redux/product/product.selectors";

import { addScenes } from "../redux/product/product.actions";

import { Generics, Metrics } from "../app.types";
import { PageTypes, UpdateEventTypes } from "./page.types";
import ManageEventChooser from "../components/manage-event-chooser.component";

class UpdateEventPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            amountBreakoutRooms: 0,
            client: "",
            minPlayersPerTeam: 0,
            passCode: "",
            productId: "",
            videoMeetingId: "",
            startDate: [],
            startHour: [],
            startMinute: [],
            sceneStartDates: [],
            sceneStartHours: [],
            sceneStartMinutes: [],
        };
        this.submitting = false;
        this.dateNull = "null";
    };

    async componentDidMount() {
        const { scenes } = this.props;
        if (!scenes) {
            fetchDataByFieldPairs(
                CollectionNames.SCENES,
                null, 
                fetchedScenes => {
                    if (fetchedScenes) {
                        this.props.addScenes(fetchedScenes);
                    };        
                }
            );
        };

        this.resetState();
        this.props.setPageName(PageTypes.UPDATE_EVENT);
    };

    getDeconstructedDate = totalSeconds => {
        if (!totalSeconds) {
            return { date: null, hours: null, minutes: null };
        };
        const totalDate = new Date(totalSeconds * Metrics.MILLIS_PER_SECOND);
        const minutes = totalDate.getMinutes();
        const hours = totalDate.getHours();
        const dateTime = totalSeconds - (minutes * 60) - (hours * 3600);
        const date = new Date(dateTime * Metrics.MILLIS_PER_SECOND);
        return { date, hours, minutes };
    };

    getReconstructedDate = index => {
        const { sceneStartDates, sceneStartHours, sceneStartMinutes } = this.state;
        const sceneStartDate = sceneStartDates[index];
        if (!sceneStartDate || sceneStartDate === undefined || sceneStartDate === this.dateNull) {
            return null;
        };
        console.log('qwer', sceneStartDate, sceneStartHours, sceneStartMinutes);
        const setStartHour = sceneStartHours[index];
        const setStartMinute = sceneStartMinutes[index];
        const startHour = setStartHour ? setStartHour : 0;
        const startMinute = setStartMinute ? setStartMinute : 0;
        return new Date(
            sceneStartDate.getTime()
            + (Number(startHour) * 3600000)
            + (Number(startMinute) * 60000)
        );
    };

    getScenesByProductId = () => {
        const { event, scenes } = this.props;
        if (!scenes || !event) {
            return null;
        }
        const productId = event[FirestoreProps.PRODUCT_ID];
        return scenes
            .filter(scene => scene.productId === productId)
            .sort((a, b) => a[FirestoreProps.INDEX] - b[FirestoreProps.INDEX]);
    };

    getProduct = productId => {
        const { products } = this.props;
        const filteredProducts = products.filter(product => product.id === productId);
        return filteredProducts.length === 1 ? filteredProducts[0] : null;
    };

    handleCancel = e => {
        e.preventDefault();
        this.resetState();
    };

    handleChange = e => {
        const { value, name } = e.target;
        // console.log('qwer', name,value);
        this.setState({ [name]: value });
    };
    
    handleDateChange = e => {
        const { name, value } = e.target;
        const keys = name.split(Generics.SPLITTER);
        const key = keys[0];
        const index = +keys[1];
        return;
        // herehere HERE refactoring start dates and times
        // switch (key) {
        //     case UpdateEventTypes.START_DATE:
        //         // const ...this.state[key]
        //     case UpdateEventTypes.SCENE_START_DATES:
        //         const collection = [...this.state[key]];
        //         break;
        //     default: return;
        // }
        // console.log('qwer', key, index, collection);
        // return;
        // key === UpdateEventTypes.START_DATES ?
        //     this.updateDate(collection, key, index, value)
        //     : this.updateTime(collection, key, index, value);
    };

    handleProductSelectChange = e => {
        this.handleChange(e);
        const { sceneStartDates, sceneStartHours, sceneStartMinutes } = this.state;
        const scenes = this.getScenesByProductId();
    };

    handleSubmit = async e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        }
        const { productId } = this.state;
        const product = this.getProduct(this.state.productId);
        if (!product) {
            return;
        }
        this.submitting = true;
        const scenes = this.getScenesByProductId();
        const { sceneStartDates } = this.state;
        let sceneStarts = {};
        let i;
        const len = sceneStartDates.length - 1;
        // console.log('qwer', sceneStarts.length)
        for (i=0; i<len; i++) {
            const sceneStart = this.getReconstructedDate(i);
            const iterator = i;
            if (sceneStart) {
                const filteredScenes = scenes.filter(scene => scene[FirestoreProps.INDEX] === iterator);
                if (filteredScenes.length === 1) {
                    sceneStarts[filteredScenes[0].id] = sceneStart;
                };
            };
        };
        console.log('qwer', this.state.amountBreakoutRooms, this.state.client, this.state.minPlayersPerTeam, this.state.passCode, productId, this.getReconstructedDate(0), sceneStarts, this.state.videoMeetingId);
        return;
        const amountBreakoutRooms = +(this.state.amountBreakoutRooms.trim());
        const minPlayersPerTeam = +(this.state.minPlayersPerTeam.trim());
        const eventData = {
            [FirestoreProps.AMOUNT_BREAKOUT_ROOMS]: isNaN(amountBreakoutRooms) ? 0 : amountBreakoutRooms,
            [FirestoreProps.CLIENT]: this.state.client.trim(),
            [FirestoreProps.MIN_PLAYERS_PER_TEAM]: isNaN(minPlayersPerTeam) ? 0 : minPlayersPerTeam,
            [FirestoreProps.PASS_CODE]: this.state.passCode.trim(),
            productId: productId.trim(),
            [FirestoreProps.START_DATE]: this.getReconstructedDate(0),
            // [FirestoreProps.SCENE_STARTS]: sceneStarts ? sceneStarts.trim() null,
            videoMeetingId: this.state.videoMeetingId.trim(),
        };
        const { adminId, event } = this.props;
        const eventId = event ? 
            event[FirestoreProps.ID]
            : null;
        const success = eventId ?
            await updateFirestoreDatum(CollectionNames.EVENTS, eventData, eventId)
            : await addFirestoreDatum(CollectionNames.EVENTS, eventData);
        console.log('qwer', success);
        if (success) {
            storeInteraction(
                adminId, 
                PageTypes.UPDATE_EVENT, 
                InteractionTypes.EVENT_UPDATED, 
                eventId + "/" + RealTimeKeys.ADMIN
            );
        };
        this.submitting = false;
        // console.log('qwer', sceneStartDates);
        // console.log('qwer', eventData);
        // this.props.history.push(`/${PageTypes.ADMIN}`)
    };

    resetState = async () => {
        console.log('qwer resetState')
        const { event } = this.props;
        const scenesByProductId = this.getScenesByProductId();
        if (!scenesByProductId) {
            return;
        };
        const storedStartDate = event[FirestoreProps.START_DATE];
        let startDate = 0;
        let startHour = 0;
        let startMinute = 0;
        if (storedStartDate) {
            const deconstructedStartDate = this.getDeconstructedDate(storedStartDate);
            if (deconstructedStartDate) {
                startDate = deconstructedStartDate.date;
                startHour = deconstructedStartDate.hours;
                startMinute = deconstructedStartDate.minutes;
            };
        };

        // const eventSeconds  = startDate[InternalProps.SECONDS];
        const sceneStarts = event[FirestoreProps.SCENE_STARTS];
        let sceneStartDates = [];
        let sceneStartHours = [];
        let sceneStartMinutes = [];
        let i;
        // const len = scenesByProductId.length;
        // console.log('qwer', event)
        for (const sceneByProductId of scenesByProductId) {
            // let totalSeconds = null;
            // if (i > 0) {
                const sceneStart = sceneStarts[sceneByProductId[FirestoreProps.ID]];
                // const totalSeconds = 
                // console.log('qwer', sceneStart)
            // } else {
                // totalSeconds = eventSeconds;
            // };
            // console.log('qwer', i, totalSeconds)
            const deconstructedDate = this.getDeconstructedDate(sceneStart ? sceneStart.seconds : 0);
            sceneStartDates.push(deconstructedDate.date ? deconstructedDate.date : this.dateNull);
            sceneStartHours.push(deconstructedDate.hours ? deconstructedDate.hours : 0);
            sceneStartMinutes.push(deconstructedDate.minutes ? deconstructedDate.minutes : 0);
        };
        this.setState({ 
            amountBreakoutRooms: event[FirestoreProps.AMOUNT_BREAKOUT_ROOMS],
            client: event[FirestoreProps.CLIENT],
            minPlayersPerTeam: event[FirestoreProps.MIN_PLAYERS_PER_TEAM],
            passCode: event[FirestoreProps.PASS_CODE],
            productId: event.productId,
            videoMeetingId: event.videoMeetingId,
            startDate,
            startHour,
            startMinute,
            sceneStartDates,
            sceneStartHours,
            sceneStartMinutes,
        });
    };
    
    updateDate = (collection, key, index, value) => {
        const deconstructedDate = this.getDeconstructedDate(value / Metrics.MILLIS_PER_SECOND).date
        const hasDate = deconstructedDate !== null; 
        collection[index] = hasDate ? deconstructedDate : this.dateNull;
        let newState = { [key]: collection };
        if (!hasDate) {
            const hoursCollection = [...this.state[UpdateEventTypes.START_HOURS]];
            const minutesCollection = [...this.state[UpdateEventTypes.START_MINUTES]];
            hoursCollection[index] = 0;
            minutesCollection[index] = 0;
            newState = { 
                ...newState, 
                [UpdateEventTypes.START_HOURS]: hoursCollection,
                [UpdateEventTypes.START_MINUTES]: minutesCollection,
            };
        };
        // console.log('qwer', newState);
        this.setState(newState);
    };

    updateTime = (collection, key, index, value) => {
        const newValue = +value;
        collection[index] = newValue;
        let newState = { [key]: collection };
        // console.log('qwer', newState);
        this.setState(newState);
    };

    render() {
        const { adminId, event, products, scenes } = this.props;
        const { 
            productId, 
            startDate,
            startHour,
            startMinute,
            sceneStartDates, 
            sceneStartHours, 
            sceneStartMinutes 
        } = this.state;
        const eventId = event ? 
            event[FirestoreProps.ID]
            : null;
        const eventProductId = event ? 
            event[FirestoreProps.PRODUCT_ID]
            : null;
        const hasEventId = eventId !== null && eventId !== undefined && eventId !== "";
        const filteredScenes = scenes.filter(scene => scene[FirestoreProps.PRODUCT_ID] === productId);

        console.log('qwer', filteredScenes) 
        // console.log('qwer dates', sceneStartDates)
        // console.log('qwer hours', sceneStartHours)
        // console.log('qwer minutes', sceneStartMinutes)
        // console.log('qwer', "|", this.state.amountBreakoutRooms, "|", this.state.client, "|", this.state.passCode, "|", this.state.products, "|", this.state.startDate, "|", this.state.startHour, "|", this.state.sceneStartMinutes, "|", this.state.minPlayersPerTeam, "|", this.state.videoMeetingId)
        return (<ContentContainer>
            {adminId && eventId ? 
                <ManageEventChooser 
                    handleChooseEventSubmit={this.props.handleChooseEventSubmit}
                    handleManageEventClick={this.props.handleManageEventClick}
                />
                : null
            }
            <Row>
                <form onSubmit={this.handleSubmit}>
                    <Column widthPercent={50}>
                        <FormLabel>Client name:</FormLabel>
                        <FormTextField 
                            name="client" 
                            type="text" 
                            value={this.state.client} 
                            onChange={this.handleChange} 
                            required={true}
                        />
                        <FormLabel>Product:</FormLabel>
                        {products ? 
                            <>
                                <FormSelect 
                                    name="productId"
                                    onChange={this.handleProductSelectChange} 
                                    // value={productId}
                                    defaultValue={eventProductId}
                                >
                                    <option 
                                        key={InternalProps.NULL_OPTION} 
                                        value={InternalProps.NULL_OPTION}
                                    >
                                        ---
                                    </option>
                                    {products.map(product => {
                                        const productId = product[FirestoreProps.ID];
                                        return <option 
                                            key={productId} 
                                            value={productId}
                                        >
                                            {product.name}
                                        </option>
                                    })}
                                </FormSelect>
                            </>
                            : null

                        }
                        <FormLabel>Min. players per team:</FormLabel>
                        <FormTextField 
                            name="minPlayersPerTeam" 
                            type="text" 
                            value={this.state.minPlayersPerTeam} 
                            onChange={this.handleChange} 
                            required={true}
                        />
                        <FormLabel>Amount of breakout rooms:</FormLabel>
                        <FormTextField 
                            name="amountBreakoutRooms" 
                            type="text" 
                            value={this.state.amountBreakoutRooms} 
                            onChange={this.handleChange} 
                            required={true}
                        />
                        <FormLabel>Video meeting id:</FormLabel>
                        <FormTextField 
                            name="videoMeetingId" 
                            type="text" 
                            value={this.state.videoMeetingId} 
                            onChange={this.handleChange} 
                        />
                        {hasEventId ? 
                            <p>Pass code: {this.state.passCode}</p>
                            :
                            <>
                                <FormLabel>Pass code:</FormLabel>
                                <FormTextField 
                                    name="passCode" 
                                    type="text" 
                                    value={this.state.passCode} 
                                    onChange={this.handleChange} 
                                    required={true}
                                />
                            </>
                        }
                    </Column>
                    <Column widthPercent={50}>
                        <div>
                            <label>Event start</label>
                            <DateFormSection
                                index={-1}
                                startDate={startDate}
                                startHour={startHour}
                                startMinute={startMinute}
                                isStartDate={true}
                                handleChange={this.handleDateChange}
                            />
                            <hr />
                            <br />
                        </div> 
                        {sceneStartDates.map((sceneStartDate, index) => {
                            // console.log('qwer', sceneStartDate);
                            return <div key={index}>
                                <label>{`Scene ${index} start`}</label>
                                <DateFormSection
                                    index={index}
                                    startDate={sceneStartDate}
                                    startHour={sceneStartHours[index]}
                                    startMinute={sceneStartMinutes[index]}
                                    isStartDate={false}
                                    handleChange={this.handleDateChange}
                                />
                                <br />
                            </div> 
                        })}
                        <FormButton type="submit" value="Submit" />
                        <FormButton type="button" value="Reset" onClick={this.handleCancel} />
                    </Column>
                </form>
            </Row>
        </ContentContainer>);
    };
}


const mapStateToProps = createStructuredSelector({
    adminId: selectAdminId,
    scenes: selectScenes,
});

const mapDispatchToProps = dispatch => ({
    addScenes: value => dispatch(addScenes(value)),
});

export default withAdminUtils(
    connect(mapStateToProps, mapDispatchToProps)(UpdateEventPage)
);
