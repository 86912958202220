import React from "react";
import { connect } from "react-redux";

import { FormSelect } from "../global.styles";

import { FirestoreProps, InternalProps } from "../firebase/firestore.types";

import { selectInteractions } from "../redux/interactions/interactions.selectors";

import { getNumUses, getValidInventoryItems } from "./components.utils";

const InventoryItemsSelect = ({ interactions, selectedItem, inventoryItems, handleChange }) => {
    if (!inventoryItems || inventoryItems.length === 0) {
        return null;
    };
    // console.log("qwer rerend");
    const selectedItemId = selectedItem[FirestoreProps.ID];
    const validInventoryItems = getValidInventoryItems(inventoryItems, interactions, selectedItemId);
    const nullOption = InternalProps.NULL_OPTION;
    return validInventoryItems ? 
        <div>
            {selectedItem ?
                <p>Items you could use on: {selectedItem[FirestoreProps.TITLE]}</p>
                : null
            }
            <FormSelect 
                name="inventoryItem" 
                onChange={handleChange}
            >
                <option key={nullOption} value={nullOption} defaultValue>---</option>
                {validInventoryItems.map(inventoryItem => {
                    const numUses = getNumUses(inventoryItem, interactions);
                    const title = inventoryItem[FirestoreProps.TITLE];
                    const label = numUses ? 
                        `${title} (uses remaining: ${numUses})`
                        : title;
                    return inventoryItem[FirestoreProps.ID] !== selectedItemId ?
                        <option 
                            key={inventoryItem.id} 
                            value={inventoryItem.id}
                        >
                            {label}
                        </option>
                        : null;
                })}
            </FormSelect>
        </div>
        : null;
};

const mapStateToProps = (state, ownProps) => ({
    interactions: selectInteractions(state),
    selectedItem: ownProps.selectedItem,
    inventoryItems: ownProps.inventoryItems,
    handleChange: ownProps.handleChange,
});

export default connect(mapStateToProps)(InventoryItemsSelect);
