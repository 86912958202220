import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import withInventoryItemUtils from "./with-inventory-item-utils";

import { fetchDataByFieldPairs } from "../firebase/firestore.utils";
import { storeInteraction } from "../firebase/realtime.utils";

import { selectHotspotDetailsId, selectInventoryItems } from "../redux/game/game.selectors";

import { numberHasValue } from "../app.utils";

import { ChallengeTypes, CollectionNames, FirestoreProps, ServerResponseStates } from "../firebase/firestore.types";
import { InteractionTypes, RealTimeKeys } from "../firebase/realtime.types";
import { ModalHeaders, ModalMessages } from "../modal.types";
import { PageTypes } from "./page.types";

const withItemUtils = WrappedComponent => {
    class WithItemUtils extends React.Component {
        checkSolutions = async (solutions, documentId, inventoryItemId) => {
            // console.log('qwer', inventoryItemId !== null, solutions? solutions.length : null )
            const { hotspotDetailsId, sceneId, self } = this.props;
            const thread = `${this.props.eventId}/${RealTimeKeys.BREAKOUT_ROOM}${this.props.breakoutRoomNumber}`
            const inventoryItemUsed = inventoryItemId !== null;
            const selfId = self.id;
            const pageType = PageTypes.ITEM;
            if (solutions) {
                if (solutions.length === 1) {
                    const solution = solutions && solutions.length === 1 ? 
                        solutions[0] 
                        : "";
                    let solutionType;
                    switch (solution[FirestoreProps.CHALLENGE_TYPE]) {
                        case ChallengeTypes.TEAM:
                            solutionType = InteractionTypes.TEAM_CHALLENGE_CORRECT;
                            break;
                        case ChallengeTypes.USER:
                            solutionType = InteractionTypes.USER_CHALLENGE_CORRECT;
                            break;
                        case ChallengeTypes.INFO:
                            solutionType = InteractionTypes.INFO_FOUND;
                            break;
                        default: 
                            solutionType = null;
                            break;
                    };
                    // console.log('qwer solutionType', solutionType);
                    // console.log('qwer solutionType', solutionType);
                    if (solutionType) {
                        const result = await storeInteraction(
                            selfId, 
                            pageType, 
                            solutionType, 
                            thread,
                            sceneId,
                            { 
                                [FirestoreProps.DOCUMENT_ID]: documentId, 
                                [FirestoreProps.SOLUTION_ID]: solution[FirestoreProps.ID],
                            },
                        );
                        if (result) {
                            this.props.addSolvedSolution(solution);
                            if (inventoryItemUsed) {
                                const item = this.getInventoryItemById(inventoryItemId);
                                if (item) {
                                    const numUses = item[FirestoreProps.NUM_USES];
                                    if (numberHasValue(numUses)) {
                                        await storeInteraction(
                                            selfId,
                                            pageType,
                                            InteractionTypes.INVENTORY_ITEM_USED,
                                            thread,
                                            sceneId,
                                            { [FirestoreProps.ITEM_ID]: item.id },
                                        );
                                    };
                                };
                            };
                            this.props.fetchInventoryItemsByHotspotDetailsId(hotspotDetailsId);
                            this.setState({ feedback: "" }); ///
                            // this.goToBackInABit();
                        } else {
                            this.props.setModalMessages({ header: ModalHeaders.OOPS, message: ModalMessages.BAD_DATA + " (#726)" });
                        };    
                    };
                } else {
                    this.props.setModalMessages({ header: ModalHeaders.OOPS, message: ModalMessages.BAD_DATA + " (#710)" });
                };
            } else {
                const interactionType = inventoryItemUsed ? 
                    InteractionTypes.INVENTORY_ITEM_MISUSED
                    : InteractionTypes.CHALLENGE_INCORRECT;
                const feedback = inventoryItemUsed ? 
                    "That doesn't seem to do anything"
                    : "It's good, but it's not right!";
                // console.log('qwer feedback', feedback);
                storeInteraction(
                    selfId, 
                    pageType, 
                    interactionType, 
                    thread,
                    sceneId,
                    { [FirestoreProps.DOCUMENT_ID]: documentId },
                );
                this.setState({ feedback });///
                // if (!inventoryItemUsed) {
                //     this.goToBackInABit();
                // }
            };
        };

        getInventoryItemById = itemId => {
            const { inventoryItems } = this.props;
            const filteredItems = inventoryItems.filter(item => item[FirestoreProps.ID] === itemId);
            // console.log('qwer', itemId, items);
            return filteredItems.length === 1 ? filteredItems[0] : null;
        };
    
        handleInventoryItemChange = async e => {
            // this.handleChange(e);
            const { hotspotDetailsId } = this.props;
            const inventoryItemId = e.target.value;
            console.log('qwer handleInventoryItemChange A', hotspotDetailsId, inventoryItemId);
            this.props.setServerResponseState(ServerResponseStates.WAITING);
            const solutions = await fetchDataByFieldPairs(
                CollectionNames.SOLUTIONS, 
                [
                    { key: FirestoreProps.DOCUMENT_ID, value: hotspotDetailsId },
                    { key: FirestoreProps.CORRECT_SOLUTION, value: inventoryItemId },
                ]
            );
            this.props.setServerResponseState(ServerResponseStates.DORMANT);
            console.log('qwer handleInventoryItemChange B', solutions);
            this.checkSolutions(solutions, hotspotDetailsId, inventoryItemId);
        };    
        
        render() {
            return <WrappedComponent 
                checkSolutions={this.checkSolutions}
                handleInventoryItemChange={this.handleInventoryItemChange}
                {...this.props} 
            />
        };
    };

    const mapStateToProps = createStructuredSelector({
        hotspotDetailsId: selectHotspotDetailsId,
        inventoryItems: selectInventoryItems,
    });
    
    // const mapDispatchToProps = dispatch => ({
    // });
    
    return withInventoryItemUtils(
        connect(mapStateToProps)(WithItemUtils)
    );
};

export default withItemUtils;