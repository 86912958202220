import React from "react";
import { connect } from "react-redux";

import { ClickableDiv, SpacedContainer } from "../global.styles";

import { FirestoreProps } from "../firebase/firestore.types";

import { selectInventoryItems } from "../redux/game/game.selectors";
import { selectInteractions } from "../redux/interactions/interactions.selectors";

import { getValidInventoryItems } from "./components.utils";
import { selectStorageUrls } from "../redux/product/product.selectors";
import { ThumbnailImage } from "./components.styles";
import { Generics } from "../app.types";

const InventoryItemsList = ({ 
    interactions, 
    inventoryItems, 
    storageUrls,
    handleClick,
}) => {
    if (!inventoryItems || inventoryItems.length === 0) {
        return null;
    };
    const validInventoryItems = getValidInventoryItems(inventoryItems, interactions);
    return validInventoryItems ? 
        <div>
            {validInventoryItems.map(inventoryItem => {
                const inventoryItemId = inventoryItem[FirestoreProps.ID];
                // console.log('qwer', inventoryItemId, inventoryItem);
                const thumbnailFileName = inventoryItem[FirestoreProps.THUMBNAIL];
                const filteredStorageUrls = thumbnailFileName ? 
                    storageUrls.filter(storageUrl => {
                        return storageUrl[FirestoreProps.ID] === thumbnailFileName
                    })
                    : null;
                const thumbnailUrl = filteredStorageUrls && filteredStorageUrls.length === 1 ?
                    filteredStorageUrls[0][FirestoreProps.URL]
                    : Generics.PLACEHOLDER_IMAGE_URL;
                return <SpacedContainer key={inventoryItemId}>
                    <ClickableDiv id={inventoryItemId} onClick={handleClick}>
                        <ThumbnailImage id={inventoryItemId} src={thumbnailUrl} alt="" />
                        <span id={inventoryItemId}>{inventoryItem[FirestoreProps.TITLE]}</span>
                    </ClickableDiv>
                </SpacedContainer>;
            })}
        </div>
        : null;
};

const mapStateToProps = (state, ownProps) => ({
    interactions: selectInteractions(state),
    inventoryItems: selectInventoryItems(state),
    storageUrls: selectStorageUrls(state),
    handleClick: ownProps.handleClick,
});

export default connect(mapStateToProps)(InventoryItemsList);
