import React from "react";
import { connect } from "react-redux";

import ReactSwipe from "react-swipe";
import { BorderedBox, BoxCenter, SpacedContainer } from "../global.styles";
import { MapInteractionCSS } from "react-map-interaction";

import { FirestoreProps } from "../firebase/firestore.types";

import { selectInventoryItemStateById } from "../redux/game/game.selectors";

import { getMediumView, getNumUses } from "./components.utils";
import { selectMedia, selectStorageUrls } from "../redux/product/product.selectors";
import { selectInteractions } from "../redux/interactions/interactions.selectors";

import { MediaTypes } from "./item/item-io.types";

const InventoryItemDetails = ({ 
    inventoryItem,
    inventoryItemState, 
    interactions,
    media, 
    storageUrls, 
}) => {
    let swiper;
    const getMediumById = id => {
        const filteredMedia = media.filter(medium => medium[FirestoreProps.ID] === id);
        return filteredMedia.length === 1 ?
            filteredMedia[0]
            : null;
    };
    const getView = (mediumId, storageUrls, allowPinch) => {
        const medium = getMediumById(mediumId);
        if (!medium) {
            return null;
        };
        const mediumView = getMediumView(medium, mediumId, storageUrls, true);
        return allowPinch && medium[FirestoreProps.TYPE] === MediaTypes.IMAGE ? 
            <BorderedBox key={mediumId}>
                <MapInteractionCSS
                    minScale={1}
                >
                    {mediumView}
                </MapInteractionCSS>
            </BorderedBox>
            : mediumView;
    };
    const allMediaIds = inventoryItem[FirestoreProps.MEDIA_IDS];
    const mediaIds = allMediaIds ? 
        allMediaIds[inventoryItemState]
        : null;
    const hasMultipleMedia = mediaIds !== null && mediaIds.length > 1;
    const numUses = getNumUses(inventoryItem, interactions);
    // console.log('qwer',hasMultipleMedia, mediaIds);
    return <SpacedContainer>
        <BoxCenter widthPercent={100}>
            <p>{inventoryItem[FirestoreProps.TITLE]}</p>
            {hasMultipleMedia ?
                <>
                    <ReactSwipe
                        swipeOptions={{ continuous: false }}
                        ref={element => swiper = element}
                    >
                        {mediaIds.map(mediumId => getView(mediumId, storageUrls, false))}
                    </ReactSwipe>
                    <button onClick={() => swiper.next()}>Next</button>
                    <button onClick={() => swiper.prev()}>Previous</button>
                </>
                : 
                <>
                    {mediaIds ? 
                        mediaIds.map(mediumId => getView(mediumId, storageUrls, true))
                        : null
                    }
                </>
            }
            {numUses ?
                <span>Uses remaining: {numUses}</span>
                : null
            }
        </BoxCenter>
    </SpacedContainer>;
};

const mapStateToProps = (state, ownProps) => ({
    inventoryItem: ownProps.inventoryItem,
    inventoryItemState: selectInventoryItemStateById(ownProps.inventoryItem[FirestoreProps.ID])(state),
    interactions: selectInteractions(state),
    media: selectMedia(state),
    storageUrls: selectStorageUrls(state),
});

export default connect(mapStateToProps)(InventoryItemDetails);
