import React from "react";
import { connect } from "react-redux";

import { Column, FormButtonSmall, Row } from "../global.styles";
import ChooseEventSelect from "./choose-event-select.component";

import { AdminProps } from "../app.types";

import { selectPageName } from "../redux/game/game.selectors";

import { PageTypes } from "../pages/page.types";

const ManageEventChooser = ({ pageName, handleChooseEventSubmit, handleManageEventClick }) => {
    const buttonLabel = pageName === PageTypes.UPDATE_EVENT ? 
        AdminProps.MONITOR
        : AdminProps.UPDATE;
    return (<>
        <Row>
            <Column widthPercent={100}>
                <ChooseEventSelect 
                    handleSubmit={handleChooseEventSubmit}
                />
            </Column>
        </Row>
        <Row>
            <Column widthPercent={100}>
                <FormButtonSmall 
                    type="button" 
                    value={buttonLabel}
                    onClick={handleManageEventClick} 
                    />
                <FormButtonSmall 
                    type="button" 
                    value={AdminProps.DESELECT_EVENT}
                    onClick={handleManageEventClick} 
                />
            </Column>
        </Row>
    </>);
};

const mapStateToProps = (state, ownProps) => ({
    pageName: selectPageName(state),
    handleChooseEventSubmit: ownProps.handleChooseEventSubmit, 
    handleManageEventClick: ownProps.handleManageEventClick,
});

export default connect(mapStateToProps)(ManageEventChooser);