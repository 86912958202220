import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
// import { CollectionNames } from "../firebase/firestore.types";
// import { addFirestoreData, fetchDataByFieldPairs } from "../firebase/firestore.utils";

class TempPage extends React.Component {
    async componentDidMount() {
        
        // const results = await fetchDataByFieldPairs(CollectionNames.MEDIA);
        
        console.log('qwer temp page');

        // for (const res of results) {
        //     await addFirestoreData(
        //         'media', {
        //             value: res.value, 
        //             type: res.type
        //         }, 
        //         res.id)
        // }

    };

    render() {
        return (<>
            <p>temp</p>
            <p>temp</p>

        </>);
    };
};

const mapStateToProps = createStructuredSelector({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TempPage);
