import { FirestoreProps } from "../../firebase/firestore.types";
import { ResponseElements } from "./item-io.types";

export const formatChallengeResponses = (responses, key, uniqueId) => {
    const connector = ResponseElements.CONNECTOR;
    const separator = ResponseElements.SEPARATOR;
    const group = ResponseElements.GROUP;
    const input = ResponseElements.INPUT;
    const valueKey = "value";
    let sortedResponses = [];

    for (const name in responses) {
        if (responses.hasOwnProperty(name)) {
            let singleResponse = { [valueKey]: responses[name] };
            const pairs = name.split(separator);
            for (let i=0; i<pairs.length; i++) {
                const pair = pairs[i].split(connector);
                singleResponse[pair[0]] = pair[1];
            };
            sortedResponses.push(singleResponse);
        };
    };
    sortedResponses.sort((first, second) => first[input].localeCompare(second[input]));

    let amalgamatedResponses = [{ key: key, value: uniqueId }];
    let prevKey = "";
    let currentValue = "";
    let groupKey = null;

    for (let i=0; i<sortedResponses.length; i++) {
        const sortedResponse = sortedResponses[i];
        groupKey = sortedResponse[group];
        if (prevKey !== groupKey) {
            if (currentValue.length > 0) {
                amalgamatedResponses.push({ key: `${FirestoreProps.CORRECT_SOLUTION}`, value: currentValue });
            }
            currentValue = "";
        };
        prevKey = sortedResponse[group];
        currentValue += sortedResponse[valueKey];
    };
    amalgamatedResponses.push({key: `${FirestoreProps.CORRECT_SOLUTION}`, value: currentValue });

    return amalgamatedResponses;
};