import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Footer from "./footer.component";
import Header from "./header.component";
import ModalWindow from "./modal-window.component";
import { ModalContainer, SpanBold } from "../global.styles";

import { InteractionTypes, RealTimeKeys } from "../firebase/realtime.types";
import { ServerResponseStates } from "../firebase/firestore.types";

import { selectEventId } from "../redux/event/event.selectors";
import { selectModalMessages } from "../redux/admin/admin.selectors";
import { selectPageName, selectSceneId } from "../redux/game/game.selectors";
import { selectSelfBreakoutRoomNumber, selectSelfId } from "../redux/users/users.selectors";

import { setModalMessages, setServerResponseState } from "../redux/admin/admin.actions";

import { PageTypes } from "../pages/page.types";
import { storeInteraction } from "../firebase/realtime.utils";

const ModalOverlay = ({ breakoutRoomNumber, eventId, modalMessages, pageName, selfId, sceneId }) => {
    const handleLogOut = async () => {
        await storeInteraction(
            selfId, 
            pageName, 
            InteractionTypes.LEFT_GAME, 
            `${eventId}/${RealTimeKeys.BREAKOUT_ROOM}${breakoutRoomNumber}`,
            sceneId
        );
        window.location = "/";
    };
    
    const handleModalClose = () => {
        this.props.setModalMessages(null);
        if (this.props.serverResponseState === ServerResponseStates.TIME_OUT) {
            this.props.setServerResponseState(ServerResponseStates.DORMANT);
        };
    };
    
    const inGame = pageName === PageTypes.LOBBY 
        || pageName === PageTypes.INTRO
        || pageName === PageTypes.GAME
        || pageName === PageTypes.LOUNGE;

    return (<ModalContainer>
        <Header 
            title={pageName} 
            handleLogOut={inGame ? handleLogOut : null} 
        />
        <Footer />
        {modalMessages ?
            <ModalWindow handleClose={handleModalClose}>
                <p><SpanBold>{modalMessages.header}</SpanBold></p>
                <p>{modalMessages.message}</p>
            </ModalWindow>
            : null
        }
    </ModalContainer>);
};

const mapDispatchToProps = dispatch => ({
    setModalMessages: value => dispatch(setModalMessages(value)),
    setServerResponseState: value => dispatch(setServerResponseState(value)),
});

const mapStateToProps = createStructuredSelector({
    breakoutRoomNumber: selectSelfBreakoutRoomNumber,
    eventId: selectEventId,
    modalMessages: selectModalMessages,
    pageName: selectPageName,
    sceneId: selectSceneId,
    selfId: selectSelfId,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalOverlay);
