import React from "react";
import { connect } from "react-redux";

import { selectHotspotDetailsBySceneId, selectHotspotsBySceneId } from "../../redux/product/product.selectors";

import { FirestoreProps } from "../../firebase/firestore.types";
import { Generics } from "../../app.types"
import { ModalHeaders, ModalMessages } from "../../modal.types";
import "./image-map.styles.scss";

const ImageMap = ({ 
    handlePolygonClick, 
    handleModalMessage, 
    challenges, 
    hotspots, 
    url,
    width,
    height, 
}) => {
    if (!hotspots) {
        return <div>no hotspots</div>
    };
    if (!challenges) {
        return <div>no challenges</div>
    };
    return (
        <div>
            <svg id="svg-view-box" viewBox={`0 0 ${width} ${height}`}>
                <image 
                    id="svgImage" 
                    width={width} 
                    height={height} 
                    href={url} 
                />
                <g>
                    {hotspots.map(hotspot => {
                        const results = challenges.filter(challenge => challenge.hotspotId === hotspot.id);
                        if (results.length !== 1) {
                            handleModalMessage(ModalHeaders.OOPS, ModalMessages.BAD_DATA + " (#799)");
                            return null;
                        }
                        // const type = hotspot.type.toLowerCase();
                        const points = hotspot.points;
                        const result = results[0];
                        const id = result[FirestoreProps.ID] + Generics.SPLITTER + result[FirestoreProps.DESCRIPTION];
                        // polygon.setAttribute("onclick", "handlePolygonClick(this)");

                        switch (hotspot.type.toLowerCase()) {
                            case "rect":
                                return (
                                    <rect
                                        key={result.id}
                                        id={id} 
                                        x={points.x}
                                        y={points.y}
                                        width={points.width}
                                        height={points.height}
                                        className="hotspotable"
                                        onClick={handlePolygonClick}
                                    />
                                );
                            case "ellipse":
                                return (
                                    <ellipse 
                                        key={result.id}
                                        id={id}
                                        cx={points.cx}
                                        cy={points.cy}
                                        rx={points.rx}
                                        ry={points.ry}
                                        className="hotspotable"
                                        onClick={handlePolygonClick}
                                    />
                                );
                            case "polygon":
                                return (
                                    <polygon 
                                        key={result.id}
                                        id={id}
                                        points={points.value} 
                                        className="hotspotable"
                                        onClick={handlePolygonClick}
                                    />
                                );
                            case "polyline":
                                return (
                                    <polyline 
                                        key={result.id}
                                        id={id}
                                        points={points.value} 
                                        className="hotspotable"
                                        onClick={handlePolygonClick}
                                    />
                                );
                            case "path":
                                return (
                                    <path 
                                        key={result.id}
                                        id={id}
                                        d={points.d} 
                                        className="hotspotable"
                                        onClick={handlePolygonClick}
                                    />
                                );
                            default: return null;
                        };
                    })}
                </g>
            </svg>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => ({
    handlePolygonClick: ownProps.handlePolygonClick,
    handleModalMessage: ownProps.handleModalMessage,
    challenges: selectHotspotDetailsBySceneId(ownProps.sceneId)(state),
    hotspots: selectHotspotsBySceneId(ownProps.sceneId)(state),
    url: ownProps.url,
    width: ownProps.width,
    height: ownProps.height,
});

export default connect(mapStateToProps)(ImageMap);