import React from "react";
// import { connect } from "react-redux";
// import { createStructuredSelector } from "reselect";

import { 
    BoxLeft,
    BoxRight,
    Column, 
    ContentContainer, 
    FormButton, 
    Row, 
    SpacedContainer, 
    SpanBold 
} from "../global.styles";
import InventoryItemDetails from "../components/inventory-item-details.component";
import InventoryItemsList from "../components/inventory-items-list.component";
import InventoryItemsSelect from "../components/inventory-items-select.component";
import withItemUtils from "./with-item-utils";

import { FirestoreProps } from "../firebase/firestore.types";

import { PageTypes } from "./page.types";

class InventoryItemsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            feedback: null,
            selectedInventoryItem: null,
        };
        this.submitting = false;
    };

    async componentDidMount() {
        this.props.setPageName(PageTypes.INVENTORY_ITEMS);
    };

    goToBack = () => {
        const { eventPassCode, sceneIndex, history } = this.props;
        history.push(`/${eventPassCode}/${PageTypes.SCENE}${sceneIndex + 1}`);
    };

    // HOC
    // handleInventoryItemChange = async e => {
    //     // this.handleChange(e);
    //     // const { hotspotDetailsId } = this.props;
    //     this.setState({ feedback: null });
    //     const { selectedInventoryItem } = this.state;
    //     const selectedInventoryItemId = selectedInventoryItem[FirestoreProps.ID];
    //     const inventoryItemId = e.target.value;
    //     // console.log('qwer handleInventoryItemChange', inventoryItemId);
    //     this.props.setServerResponseState(ServerResponseStates.WAITING);
    //     const solutions = await fetchDataByFieldPairs(
    //         CollectionNames.SOLUTIONS, 
    //         [
    //             { key: FirestoreProps.DOCUMENT_ID, value: selectedInventoryItemId },
    //             { key: FirestoreProps.CORRECT_SOLUTION, value: inventoryItemId },
    //         ]
    //     );
    //     this.props.setServerResponseState(ServerResponseStates.DORMANT);
    //     console.log('qwer', solutions);
    //     this.props.checkSolutions(solutions, inventoryItemId);
    // };

    handleInventoryItemClick = e => {
        e.preventDefault();
        // console.log('qwer can submit?', !this.submitting);
        if (this.submitting) {
            return;
        };
        this.submitting = true;
        this.setState({ feedback: null });
        const { inventoryItems } = this.props;
        const inventoryItemId = e.target.id;
        const filteredInventoryItems = inventoryItems.filter(inventoryItem => {
            return inventoryItem[FirestoreProps.ID] === inventoryItemId;
        });
        // console.log('qwer', e.target);
        if (filteredInventoryItems.length === 1) {
            const selectedInventoryItem = filteredInventoryItems[0];
            if (
                !this.state.selectedInventoryItem || 
                this.state.selectedInventoryItem[FirestoreProps.ID] !== selectedInventoryItem[FirestoreProps.ID]
            ) {
                // console.log('qwer selecting');
                this.setState({ selectedInventoryItem });
            };  
        };
        this.submitting = false;
    }

    render() {
        const { inventoryItems } = this.props;
        const { feedback, selectedInventoryItem } = this.state;
        // console.log('qwer rend');
        return (<ContentContainer>
            <SpacedContainer>
                <BoxLeft widthPercent={50}>
                    <SpanBold>Inventory</SpanBold>
                </BoxLeft>
                <BoxRight widthPercent={50}>
                    <form onSubmit={this.goToBack}>
                        <FormButton type="submit" value="Back" />
                    </form>
                </BoxRight>
            </SpacedContainer>
            <Row>
                <Column widthPercent={50}>
                    <InventoryItemsList 
                        handleClick={this.handleInventoryItemClick}
                    />
                    {selectedInventoryItem ?
                        <InventoryItemsSelect
                            inventoryItems={inventoryItems}
                            selectedItem={selectedInventoryItem}
                            handleChange={this.props.handleInventoryItemChange}
                        />
                        : null
                    }
                    {feedback ? 
                        <p>{feedback}</p>
                        : null
                    }
                </Column>
                <Column widthPercent={50}>
                    {selectedInventoryItem ?
                        <InventoryItemDetails 
                            inventoryItem={selectedInventoryItem}
                        />
                        : null
                    }
                </Column>
            </Row>
        </ContentContainer>);
    };
};

// const mapStateToProps = createStructuredSelector({
//     // clientTimeDifference: selectClientTimeDifference,
//     // event: selectEvent,
// });

// const mapDispatchToProps = dispatch => ({
// });

export default withItemUtils(InventoryItemsPage);
//     connect(mapStateToProps, mapDispatchToProps)(InventoryItemsPage)
// );
