import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import Countdown from "./countdown.component";

import { getNow } from "../firebase/firestore.utils";

import { selectMinutesBySceneId } from "../redux/product/product.selectors";
import { selectSceneStartBySceneId } from "../redux/event/event.selectors";

import { Metrics } from "../app.types";

const SceneTimer = ({ 
    clientTimeDifference, 
    countFromMinute,
    sceneStart, 
    sceneMinutes, 
    useSceneTime, 
    handleComplete,
    handleWarning, 
    warningMinutes,
}) => {
    const maxSeconds = Metrics.MAX_MINUTES_OR_SECONDS;
    const sceneTime = useSceneTime ? sceneMinutes * maxSeconds : 0;
    const adjustedNow = () => clientTimeDifference !== null ? getNow() - clientTimeDifference : null;
    const secondsDifference = now => sceneStart ? sceneStart.seconds + sceneTime - now : null;
    const calcTimes = (timer, seconds) => {
        return { 
            ...timer,
            minutes: Math.floor(seconds / maxSeconds), 
            seconds: seconds % maxSeconds 
        };
    };
    const clientNow = adjustedNow();
    const seconds = secondsDifference(clientNow);
    let isMounted = useRef(null);
    let initialTimer = (clientNow !== null && seconds !== null) ? 
        calcTimes(null, seconds)
        : { handler: null, minutes: null, seconds: null };
    const [timer, setTimer] = useState(initialTimer);
    // console.log('qwer start', seconds);
    
    const tick = e => {
        // console.log('qwer tick', isMounted.current);
        if (isMounted.current) {
            setTimer(prev => {
                const clientNow = adjustedNow();
                const seconds = secondsDifference(clientNow);
                const result = (clientNow !== null && seconds !== null) ? 
                    calcTimes(prev, seconds)
                    : prev;
                // console.log('qwer tick', prev.minutes, prev.seconds);
                if (warningMinutes
                    && prev.seconds === 0 
                    && warningMinutes.indexOf(prev.minutes) > -1
                    && handleWarning) {
                    handleWarning(prev);
                };
                if (prev.minutes < 0 || (prev.minutes === 0 && prev.seconds === 1)) {
                    // clearInterval(timer.handler);
                    if (handleComplete) {
                        handleComplete();
                    };
                };
                return result;
            });
        };
    };
    
    const hasInitialMinutes = initialTimer.minutes !== null;
    const hasTimedMinutes = timer.minutes !== null;

    if (
        (hasInitialMinutes && !hasTimedMinutes) 
        || (!hasInitialMinutes && hasTimedMinutes)
        || (hasInitialMinutes && hasTimedMinutes && initialTimer.minutes !== timer.minutes)
    ) {
        setTimer({ 
            ...timer,
            minutes: initialTimer.minutes,
            seconds: initialTimer.seconds,
        });
    };

    if (!timer.handler) {
        // console.log('qwer start');
        const handler = setInterval(tick, Metrics.MILLIS_PER_SECOND);
        setTimer({ ...timer, handler });
    };

    useEffect(() => {
        // console.log('qwer mount');
        isMounted.current = true;
        return () => {
            // console.log('qwer unmount');
            isMounted.current = false;
            if (timer.handler) {
                clearInterval(timer.handler);
            };
        };
    }, [timer.handler, isMounted]);

    const showTime = 
        timer.minutes >= 0 
        && (
            timer.minutes < countFromMinute
            || (
                timer.minutes === countFromMinute
                && timer.seconds === 0
            )
        );
    const sceneStartDate = !useSceneTime && !showTime ?
        new Date(sceneStart.seconds * Metrics.MILLIS_PER_SECOND)
        : null;
    const sceneStartPrefix = sceneStart && clientNow > sceneStart.seconds ?
        "Started at"
        : "Starts at"
    
    if (useSceneTime) {
        return (<>
            <div>
                <span>
                    <p>Time left: <Countdown timer={timer} /></p>
                </span>
            </div>
        </>);
    } else {
        return (<>
            {timer.minutes === null ?
                <p>no start time set</p>
                : 
                <>
                    {showTime ?
                        <p>Starts in: <Countdown timer={timer} /></p>
                        :
                        <>
                            {sceneStartDate ? 
                                <p>{sceneStartPrefix}: {sceneStartDate.getHours()}:{String(sceneStartDate.getMinutes()).padStart(2, "0")}</p>
                                : null
                            }
                        </>
                    }
                </>
            }
            {/* {isOver ?
                <form onSubmit={handleSubmit}>
                    <input type="submit" value="Play!" />
                </form>
                : null
            } */}
            {/* {timer.minutes > 0 || (timer.minutes === 0 && timer.seconds > 0) ? */}
        </>);
    };
};

const mapStateToProps = (state, ownProps) => ({
    clientTimeDifference: ownProps.clientTimeDifference,
    countFromMinute: ownProps.countFromMinute,
    sceneMinutes: selectMinutesBySceneId(ownProps.sceneId)(state),
    sceneStart: selectSceneStartBySceneId(ownProps.sceneId)(state),
    useSceneTime: ownProps.useSceneTime,
    handleComplete: ownProps.handleComplete,
    // handleTick: ownProps.handleTick,
    handleWarning: ownProps.handleWarning,
    warningMinutes: ownProps.warningMinutes,
});

export default connect(mapStateToProps)(SceneTimer);
