import { createSelector } from "reselect";
import { FirestoreProps } from "../../firebase/firestore.types";
import { RealTimeKeys, UserKeys } from "../../firebase/realtime.types";

const userReducer = state => state.user;

export const selectEmail = createSelector(
    [userReducer],
    reducer => reducer.email
);

export const selectUsers = createSelector(
    [userReducer],
    reducer => reducer.users
);

export const selectSelfId = createSelector(
    [userReducer],
    reducer => reducer.selfId
);

export const selectSelf = createSelector(
    [selectUsers, selectSelfId],
    (users, selfId) =>  {
        if (!users) {
            return null;
        }
        let results = users.filter(user => user.id === selfId);
        return results.length === 1 ? results[0] : null;
    }
);

export const selectSelfBreakoutRoomNumber = createSelector(
    [selectSelf],
    user => user ? user[RealTimeKeys.BREAKOUT_ROOM] : null
);

// ------------

export const selectBreakoutRoomUsers = createSelector(
    [selectUsers, selectSelfBreakoutRoomNumber],
    (users, breakoutRoomNumber) => {
        if (!users || !breakoutRoomNumber) {
            return null;
        };
        const breakoutRoomUsers = users.filter(user => user[RealTimeKeys.BREAKOUT_ROOM] === breakoutRoomNumber)
        return breakoutRoomUsers.length > 0 ? breakoutRoomUsers : null;
    }
);

export const selectBreakoutRoomUserIds = createSelector(
    [selectBreakoutRoomUsers],
    breakoutRoomUsers => breakoutRoomUsers ? breakoutRoomUsers.map(user => user.id) : null
);

export const selectNumBreakoutRoomUsers = createSelector(
    [selectBreakoutRoomUsers],
    breakoutRoomUsers => breakoutRoomUsers ? breakoutRoomUsers.length : 0
);

export const selectUsersByBreakoutRoomNumber = breakoutRoomNumber => createSelector(
    [selectUsers],
    users => {
        if (!users || !breakoutRoomNumber) {
            return null;
        };
        const filteredUsers = users.filter(user => {
            return user[UserKeys.BREAKOUT_ROOM_NUMBER] === breakoutRoomNumber
        });
        return filteredUsers.length > 0 ?
            filteredUsers
            : null;
    }
);

export const selectUserIdsByBreakoutRoomNumber = breakoutRoomNumber => createSelector(
    [selectUsersByBreakoutRoomNumber(breakoutRoomNumber)],
    breakoutRoomUsers => {
        return breakoutRoomUsers ? 
            breakoutRoomUsers.map(breakoutRoomUser => breakoutRoomUser[FirestoreProps.ID])
            : null;
    }
);
