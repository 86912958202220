import { createSelector } from "reselect";

const lobbyReducer = state => state.lobby;

export const selectAllCertifiedTeammateIds = createSelector(
    [lobbyReducer],
    reducer => reducer.allCertifiedTeammateIds
);

export const selectCaptainVoteIds = createSelector(
    [lobbyReducer],
    reducer => reducer.captainVoteIds
);

export const selectTeamState = createSelector(
    [lobbyReducer],
    reducer => reducer.teamState
);

// ------------

export const selectCertifiedTeammateIds = createSelector(
    [selectAllCertifiedTeammateIds],
    allCertifiedTeammateIds => {
        let uniqueTeammateIds = [];
        for (const key in allCertifiedTeammateIds) {
            if (uniqueTeammateIds.indexOf(key) === -1) {
                uniqueTeammateIds.push(key);
            };
            const certifiedTeammateIdsByKey = allCertifiedTeammateIds[key];
            for (const i in certifiedTeammateIdsByKey) {
                const certifiedTeammateId = certifiedTeammateIdsByKey[i];
                if (uniqueTeammateIds.indexOf(certifiedTeammateId) === -1) {
                    uniqueTeammateIds.push(certifiedTeammateId);
                };
            };
        };
        return uniqueTeammateIds.length > 0 ? uniqueTeammateIds : null;
    }
);

export const selectNumCaptainVoteIds = createSelector(
    [selectCaptainVoteIds],
    captainVoteIds => {
        let numCaptainVotes = 0;
        if (!captainVoteIds) {
            return numCaptainVotes;
        };
        for (const key in captainVoteIds) {
            if (captainVoteIds.hasOwnProperty(key)){
                numCaptainVotes += 1;
            };
        };
        return numCaptainVotes;
    }
);

export const selectNumCertifiedTeammates = createSelector(
    [selectCertifiedTeammateIds],
    certifiedTeammateIds => certifiedTeammateIds ? certifiedTeammateIds.length : 0
);

export const selectNumTeammatesWhoveCertified = createSelector(
    [selectAllCertifiedTeammateIds],
    [selectAllCertifiedTeammateIds],
    allCertifiedTeammateIds => {
        let numTeammatesWhoveCertified = 0;
        for (const key in allCertifiedTeammateIds) {
            if (allCertifiedTeammateIds.hasOwnProperty(key)){
                numTeammatesWhoveCertified += 1;
            };
        };
        return numTeammatesWhoveCertified;
    }
);

// export const selectCaptainVotingUnderway = (numBreakoutRoomUsers, captainId) => createSelector(
//     [selectAllCertifiedTeammateIds],
//     allCertifiedTeammateIds => {
//         if (!allCertifiedTeammateIds) {
//             return false;
//         }
//         // console.log('qwer start', allCertifiedTeammateIds)
//         let numTotalCertifiedTeammates = 0;
//         let numCertifiedTeammatesPerUser = 0;
//         let teammatesAgree = true;
//         let count = -1;
//         for (const key in allCertifiedTeammateIds) {
//             numTotalCertifiedTeammates += 1;
//             numCertifiedTeammatesPerUser = allCertifiedTeammateIds[key].length;
//             if (count < 0) {
//                 count = numCertifiedTeammatesPerUser;
//                 // console.log('qwer set count', count)
//             } else if (count !== numCertifiedTeammatesPerUser) {
//                 // console.log('qwer count mismatch', numCertifiedTeammatesPerUser)
//                 teammatesAgree = false;
//             };
//         };
//         // console.log('qwer', numTotalCertifiedTeammates, numBreakoutRoomUsers, numCertifiedTeammatesPerUser, teammatesAgree, captainId)
//         switch (numTotalCertifiedTeammates) {
//             case 0:     
//                 return false;
//             case 1:
//                 return numCertifiedTeammatesPerUser === 0 && captainId === null;
//             default:    
//                 return numTotalCertifiedTeammates === numBreakoutRoomUsers ? 
//                     teammatesAgree && captainId === null 
//                     : false;
//         };
//     }
// );

// ------------

export const selectCaptainVoteIdByUserId = userId => createSelector(
    [selectCaptainVoteIds],
    captainVoteIds => captainVoteIds ? 
        captainVoteIds[userId] !== undefined ? captainVoteIds[userId] : null
        : null
);

export const selectCertifiedTeammatesByUserId = userId => createSelector(
    [selectAllCertifiedTeammateIds],
    allCertifiedTeammateIds => allCertifiedTeammateIds ? allCertifiedTeammateIds[userId] : null
);

// +1 to include self
export const selectNumCertifiedTeammatesByUserId = userId => createSelector(
    [selectCertifiedTeammatesByUserId(userId)],
    certifiedTeammatesByUserId => certifiedTeammatesByUserId ? certifiedTeammatesByUserId.length + 1 : 0
);
