import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Column, ContentContainer, Row } from "../global.styles";
import EventCodeForm from "../components/event-code-form.component";
import withUserUtils from "./with-user-utils";

import { auth, fetchDataByFieldPairs } from "../firebase/firestore.utils";
import { CollectionNames, FirestoreProps, InternalProps, ServerResponseStates } from "../firebase/firestore.types";
import { RealTimeKeys, RoomsCodes } from "../firebase/realtime.types";
import { storeUser } from "../firebase/realtime.utils";

import { selectPassCode } from "../redux/event/event.selectors";
import { selectProductId, selectProductName, selectProductPassCode } from "../redux/product/product.selectors";
import { selectSelfId } from "../redux/users/users.selectors";

import { addProduct, addProductId } from "../redux/product/product.actions";
import { setOwnUser } from "../redux/users/users.actions";
import { setEvent } from "../redux/event/event.actions";

import { getDateIsToday, getMinutesSinceStart } from "./page.utils";
import { ModalMessages, ModalHeaders } from "../modal.types";
import { PageTypes, ProductPageStages } from "./page.types";


class ProductPage extends React.Component {
    constructor(props) {
        super(props);
        // console.log('qwer ProductPage init', this.state);
        this.state = {
            ...this.state,
            stage: ProductPageStages.NEW_USER_ON_SITE,
            eventCode: "",
            bookedEvent: null,
        };
        this.submitting = false;
        this.emailValidator = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    async componentDidMount() {
        auth.signInAnonymously()
            .catch(error => {
                this.props.setModalMessages({ header: ModalHeaders.OOPS, message: "could not log in you in (#787)" });
            });
        this.props.setServerResponseState(ServerResponseStates.WAITING);
        const products = await fetchDataByFieldPairs(
            CollectionNames.PRODUCTS, 
            [{ 
                key: FirestoreProps.PASS_CODE, 
                value: this.props.match.params.productPassCode
            }]
        );
        this.props.setServerResponseState(ServerResponseStates.DORMANT);
        const newStatus = products !== null && products.length === 1 ? 
            ProductPageStages.PRODUCT_LOADED
            : ProductPageStages.NO_SUCH_PRODUCT;
        if (newStatus === ProductPageStages.PRODUCT_LOADED) {
            const product = products[0];
            this.props.addProduct(product);
            this.props.addProductId(product[FirestoreProps.ID]);
        };
        this.setState({ stage: newStatus });

        this.props.setPageName(PageTypes.PRODUCT);
    };

    goToEmail = () => {
        const { eventPassCode, history } = this.props;
        const location = `/${PageTypes.EMAIL}/${eventPassCode}`;
        history.push(location);
    };

    handleChange = e => {
        const { value, name } = e.target;
        this.setState({ [name]: value });
    };

    handleEventCodeSubmit = async e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        this.props.setServerResponseState(ServerResponseStates.WAITING);
        const events = await fetchDataByFieldPairs(
            CollectionNames.EVENTS, 
            [{ 
                key: FirestoreProps.PASS_CODE, 
                value: this.state.eventCode.trim() 
            },{
                key: FirestoreProps.PRODUCT_ID, 
                value: this.props.productId.trim() 
            }]
        );
        this.props.setServerResponseState(ServerResponseStates.DORMANT);
        let stillMounted = true;
        if (events && events.length === 1) {
            const { clientTimeDifference } = this.props;
            const bookedEvent = events[0];
            const bookedSeconds = bookedEvent[FirestoreProps.START_DATE][InternalProps.SECONDS];
            const eventDateIsToday = await getDateIsToday(clientTimeDifference, bookedSeconds);
            let newStatus = undefined;
            if (!eventDateIsToday) {
                newStatus = ProductPageStages.EVENT_COMPLETE;
            } else {
                this.props.setClientTimeDifference(clientTimeDifference);
                const minutesSinceStart = await getMinutesSinceStart(clientTimeDifference, bookedSeconds);
                newStatus = minutesSinceStart < 0 ? 
                    ProductPageStages.EVENT_YET_TO_START
                    : ProductPageStages.EVENT_FETCHED;
                // console.log('qwer', bookedEvent.startDate.seconds);
                // console.log('qwer', new Date(bookedEvent.startDate.seconds * 1000));
                // console.log('qwer', newStatus);
                if (newStatus === ProductPageStages.EVENT_FETCHED) {
                    const blankUser = await storeUser(
                        "", 
                        0, 
                        RoomsCodes.NONE, 
                        0, 
                        bookedEvent.id + "/" + RealTimeKeys.USERS
                    );
                    if (blankUser) {
                        this.props.setOwnUser(blankUser);
                        this.props.setEvent(bookedEvent);
                        stillMounted = false;
                        this.goToEmail();
                    } else {
                        this.props.setModalMessages({ 
                            header: ModalHeaders.OOPS, 
                            message: ModalMessages.BAD_DATA + " (#692)" 
                        });
                    };
                };
            };
            if (stillMounted) {
                this.setState({ bookedEvent, stage: newStatus });
            };
    } else {
            this.props.setModalMessages({ header: ModalHeaders.OOPS, message: "Hmm, can't find that event, sorry" });
        };
    };

    render() {
        this.submitting = false;
        const { stage, bookedEvent } = this.state;
        // console.log('qwer prod rend', this.state)
        return (<ContentContainer>
            <Row>
                <Column widthPercent={50}>
                    {stage === ProductPageStages.NEW_USER_ON_SITE ? 
                        <p>Loading event...</p> 
                        : null
                    }
                    {stage === ProductPageStages.NO_SUCH_PRODUCT ? 
                        <p>No event with code: {this.props.match.params.productPassCode}</p>
                        : null
                    }
                    {stage === ProductPageStages.PRODUCT_LOADED ? 
                        <>
                            <p>{this.props.productName}</p>
                            <EventCodeForm
                                eventCode={this.state.eventCode}
                                handleChange={this.handleChange} 
                                handleSubmit={this.handleEventCodeSubmit} 
                            />
                        </>
                        : null
                    }
                    {bookedEvent ?
                        <p>{this.props.productName}, {bookedEvent[FirestoreProps.CLIENT]}</p>
                        : null
                    }
                    {stage === ProductPageStages.EVENT_YET_TO_START ? 
                        <>
                            <p>Event yet to start.</p>
                        </>
                        : null
                    }
                    {stage === ProductPageStages.EVENT_COMPLETE ? 
                        <p>Event is over.</p>
                        : null
                    }
                </Column>
            </Row>
        </ContentContainer>);
    };
};

const mapStateToProps = createStructuredSelector({
    eventPassCode: selectPassCode,
    productId: selectProductId,
    productName: selectProductName,
    productPassCode: selectProductPassCode,
    selfId: selectSelfId,
});

const mapDispatchToProps = dispatch => ({
    addProduct: value => dispatch(addProduct(value)),
    addProductId: value => dispatch(addProductId(value)),
    setEvent: value => dispatch(setEvent(value)),
    setOwnUser: value => dispatch(setOwnUser(value)),
});

export default withUserUtils(
    connect(mapStateToProps, mapDispatchToProps)(ProductPage)
);
