import { isMobileOnly, isTablet } from "react-device-detect";
import styled, { createGlobalStyle, css } from "styled-components";
import { Metrics } from "./app.types";

export const SizableBox = css`
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
`;

const InternalBox = css`
    ${SizableBox}
    padding: ${() => contentPadding()};
    width: ${props => props.widthPercent}%;
`;

const InternalFormElement = css`
    font-weight: bold;
    border-width: 1px;
    border-radius: 0.25em;
`;

const InternalFormButton = css`
    ${InternalFormElement}
    font-size: 1em;
    padding: 0.3em;
    background-color: peru;
    color: white;
    border-color: white;
    cursor: pointer;
`;

const InternalFormLabel = styled.label`
    ${SizableBox}
    margin-top: 0.5em;
`;

const InternalFormSelect = css`
    ${InternalFormElement}
    font-weight: bold;
    font-size: 1.25em;
    border-color: darkslategrey;
    background-color: white;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
`;

const InternalSelectedElement = css`
    background-color: firebrick;
`;

const InternalTableCell = css`
    position: table-cell;
    display: inline-block;
    vertical-align: top;
    width: ${props => props.widthPercent}%;
    display: inline-block;
    background-color: honeydew;
`;


const SpacedBetween = css`
    display: flex;
    justify-content: space-between;
`;

export const InternalTextField = css`
    ${SizableBox}
    ${InternalFormElement}
    padding: 0.25em;
    font-size: 1.25em;
    color: darkslategrey;
    border-color: darkslategrey;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
`;
// ------------

const InternalModalContainer = css`
    ${SpacedBetween}
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: 200;
`;

export const HeaderAndFooterContainer = css`
    ${InternalModalContainer}
    height: 2em;
    padding: 8px;
    text-align: center;
    font-weight: bold;
    color: white;
    background-color: lightslategray;
    pointer-events: auto;

    @media screen and (min-width: ${Metrics.MIN_SCREEN_WIDTH}px) {
        @media screen and (max-width: ${Metrics.MAX_SCREEN_WIDTH}px) {
            display: ${props => isMobileOnly ? "none" : "flex"};
        }
    }
`;

// ------------

export const GlobalStyle = createGlobalStyle`
    body {
        font-family: "Arial", "Helvetica Neue", "Helvetica", sans-serif;
        font-size: ${props => {
            switch (true) {
                case isMobileOnly:      return "1.25em";
                case isTablet:          return "1.75em";
                default:                return "1em";
            };
        }};
    }

    label {
        ${SizableBox}
        width: 100%;
        display: block;
        margin-top: 0.5em;
    }

    p {
        padding: 0px;
    }
`;

// ------------

export const BorderedBox = styled.div`
    ${SizableBox}
    border: 1px solid #415D5D;
`;

export const BoxCenter = styled.div`
    ${InternalBox}
    text-align: center;
`;

export const BoxLeft = styled.div`
    ${InternalBox}
    text-align: left;
`;

export const BoxRight = styled.div`
    ${InternalBox}
    text-align: right;
`;

export const ClickableDiv = styled.div`
    cursor: pointer;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
`

export const Column = styled.div`
    ${SizableBox}
    float: left;
    width: 100%;
    padding: ${() => contentPadding()};

    @media only screen and (min-width: ${Metrics.COLUMN_CHANGING_THRESHOLD}px) {
        width: ${props => props.widthPercent}%;
    } 
`;

export const ContentContainer = styled.div`
    padding: ${() => contentPadding()};
    padding-top: ${() => headerAndFooterlessVerticalPadding()};
    padding-bottom: ${() => headerAndFooterlessVerticalPadding()};

    @media screen and (min-width: ${Metrics.MIN_SCREEN_WIDTH}px) {
        @media screen and (max-width: ${Metrics.MAX_SCREEN_WIDTH}px) {
            padding-top: ${() => isMobileOnly ? contentPadding() : headerAndFooterlessVerticalPadding()};
            padding-bottom: ${() => isMobileOnly ? contentPadding() : headerAndFooterlessVerticalPadding()};
        }
    }
`;

export const FormButton = styled.input`
    ${InternalFormButton}
    width: 100%;
`;

export const FormButtonSelected = styled.input`
    ${InternalFormButton}
    ${InternalSelectedElement}
    width: 100%;
`;

export const FormButtonSmall = styled.input`
    ${InternalFormButton}
    width: 50%;
`;

export const FormButtonIcon = styled.button`
    ${InternalFormButton}
    width: 100%;
`;

export const FormButtonIconSmall = styled.button`
    ${InternalFormButton}
`;

export const FormLabel = styled.label`
    ${InternalFormLabel}
    display: block;
    width: 100%;
`;

export const FormLabelHalf = styled.label`
    ${InternalFormLabel}
    width: 50%;
`;

export const FormSelect = styled.select`
    ${InternalFormSelect}
    width: 100%;
    padding: 0.25em;
`;

export const FormSelectHalf = styled.select`
    ${InternalFormSelect}
    width: 50%;
    padding: 0.25em;
`;

export const FormSelectSmall = styled.select`
    ${InternalFormSelect}
    width: 3em;
`;

export const FormTextField = styled.input`
    ${SizableBox}
    ${InternalFormElement}
    ${InternalTextField}
    width: 100%;
`;

export const FormTextFieldHalf = styled.input`
    ${SizableBox}
    ${InternalFormElement}
    ${InternalTextField}
    width: 50%;
`;

export const InteractionDialogText = css`
    font: italic 16px Arial, sans-serif;
    color: lemonchiffon;
`;

export const ModalContainer = styled.div`
    ${InternalModalContainer}
    position: fixed;
    top: 0px;
    bottom: 0px;
    pointer-events: none;
`;

export const Row = styled.div`
    background-color: aliceblue;
    &::after {
        content: "";
        clear: both;
        display: table;
    }
`;

export const SpacedContainer = styled.div`
    ${SpacedBetween}
    width: 100%
`;

export const SpanBold = styled.span`
    font-weight: bold;
`;

export const SpanCentered = styled.span`
    text-align: center;
`;

export const SpanItalic = styled.span`
    font-style: italic;
`;

export const TableCell = styled.div`
    ${InternalTableCell}
`;

export const TableCellCentered = styled.div`
    ${InternalTableCell}
    text-align: center;
`;

export const TableContainer = styled.div`
    width: 100%;
    display: table;
    background-color: beige;
`;

// ------------

const contentPadding = () => isTablet ? "0.4em" : "0.2em";
const headerAndFooterlessVerticalPadding = () => "3.2em";
