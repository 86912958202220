import { createSelector } from "reselect";
import { FirestoreProps, InternalProps } from "../../firebase/firestore.types";

const gameReducer = state => state.game;

export const selectCompletedScenesIds = createSelector(
    [gameReducer],
    (reducer) => reducer.completedScenesIds
);

export const selectClientTimeDifference = createSelector(
    [gameReducer],
    (reducer) => reducer.clientTimeDifference
);

export const selectHintState = createSelector(
    [gameReducer],
    (reducer) => reducer.hintState
);

export const selectHotspotDetailsId = createSelector(
    [gameReducer],
    (reducer) => reducer.hotspotDetailsId
);

export const selectImageMapProperties = createSelector(
    [gameReducer],
    (reducer) => reducer.imageMapProperties
);

export const selectInventoryItems = createSelector(
    [gameReducer],
    (reducer) => reducer.inventoryItems
);

export const selectInventoryItemStates = createSelector(
    [gameReducer],
    (reducer) => reducer.inventoryItemStates
);

export const selectPageName = createSelector(
    [gameReducer],
    reducer => reducer.pageName
);

export const selectSceneId = createSelector(
    [gameReducer],
    reducer => reducer[FirestoreProps.SCENE_ID]
);

export const selectSceneIndex = createSelector(
    [gameReducer],
    (reducer) => reducer.sceneIndex
);

export const selectSolvedSolutions = createSelector(
    [gameReducer],
    (reducer) => reducer.solvedSolutions
);

// ------------

export const selectInventoryItemStateById = inventoryItemId => createSelector(
    [selectInventoryItemStates],
    inventoryItemStates => {
        if (!inventoryItemId || !inventoryItemStates) {
            return null;
        };
        const filteredInventoryItemStates = inventoryItemStates.filter(inventoryItemState => {
            return inventoryItemState[FirestoreProps.ID] === inventoryItemId
        });
        return filteredInventoryItemStates.length === 1 ?
            filteredInventoryItemStates[0][InternalProps.STATE]
            : null;
    }
);
