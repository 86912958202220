import React from "react";
import { connect } from "react-redux";
// import { createStructuredSelector } from "reselect";

import withUserUtils from "./with-user-utils";

import { CollectionNames, FirestoreProps, ServerResponseStates } from "../firebase/firestore.types";
import { RealTimeKeys, InteractionKeys, InteractionTypes, RoomsCodes, UserKeys } from "../firebase/realtime.types";
import { fetchDataByFieldPairs, storage, storageUrl } from "../firebase/firestore.utils";
import { fetchDocuments, fetchInteractions } from "../firebase/realtime.utils";

import { 
    addCompletedScenesIds,
    addImageMapProperties, 
    addSolvedSolutions,
    setSceneId, 
    setSceneIndex 
} from "../redux/game/game.actions";
import { 
    addHotspotDetails, 
    addHotspots, 
    addImageMap, 
    addProduct, 
    addProductId, 
    addScenes 
} from "../redux/product/product.actions";
import { addInteractions } from "../redux/interactions/interactions.actions";
import { resetApp } from "../redux/root/root.actions";
import { setEvent } from "../redux/event/event.actions";
import { setOwnUser } from "../redux/users/users.actions";

import { getMinutesSinceStart } from "./page.utils";

import { ModalHeaders, ModalMessages } from "../modal.types";
import { PageTypes } from "./page.types";

class RejoinPage extends React.Component {
    async componentDidMount() {

        this.props.resetApp();

        const events = await this.getData(
            CollectionNames.EVENTS, 
            FirestoreProps.PASS_CODE, 
            this.props.match.params.eventPassCode,
            true
        );
        if (!events) {
            this.props.setModalMessages({ 
                header: ModalHeaders.OOPS, 
                message: "no event with that code (#621)" 
            });
            return;
        };
        const event = events[0];
        // console.log('qwer event', event);

        const { clientTimeDifference } = this.props;
        const minutesSinceStart = await getMinutesSinceStart(clientTimeDifference, event.startDate.seconds);
        if (minutesSinceStart < 0) {
            this.props.setModalMessages({ 
                header: ModalHeaders.OOPS, 
                message: "this event has finished (#622)" 
            });
            return;
        };
        
        const users = await fetchDocuments(
            event.id + "/" + RealTimeKeys.USERS, 
            this.props.match.params.userId
        );
        const user = users && users.length === 1 ? 
            users[0]
            : null;
        if (!user) {
            this.props.setModalMessages({ 
                header: ModalHeaders.OOPS, 
                message: "no user with that id (#623)" 
            });
            return;
        };
        const sceneIndex = user[UserKeys.SCENE_INDEX];

        const products = await this.getData(
            CollectionNames.PRODUCTS, 
            FirestoreProps.PASS_CODE, 
            this.props.match.params.productPassCode,
            true
        );
        if (!products) {
            this.props.setModalMessages({ 
                header: ModalHeaders.OOPS, 
                message: "no product with that id (#624)" 
            });
            return;
        };
        const product = products[0];
        const productId = product.id;
        // console.log('qwer product', product);

        const scenes = await this.getData(
            CollectionNames.SCENES, 
            FirestoreProps.PRODUCT_ID, 
            productId,
            false
        );
        if (!scenes) {
            this.props.setModalMessages({ 
                header: ModalHeaders.OOPS, 
                message:  "no scenes with that product id (#625)" 
            });
            return;
        };
        const scenesBySceneIndex = scenes.filter(scene => scene[FirestoreProps.INDEX] === sceneIndex);
        if (scenesBySceneIndex.length !== 1) {
            this.props.setModalMessages({ 
                header: ModalHeaders.OOPS, 
                message:  "no single scenes with that scene index (#626)" 
            });
            return;
        };
        const scene = scenesBySceneIndex[0];
        // console.log('qwer scene', scene);

        const imageMaps = await this.getData(
            CollectionNames.IMAGE_MAPS, 
            FirestoreProps.SCENE_ID, 
            scene.id,
            true
        );
        if (!imageMaps) {
            this.props.setModalMessages({ 
                header: ModalHeaders.OOPS, 
                message:  "no image map for that scene id (#628)" 
            });
            return;
        };
        const imageMap = imageMaps[0];

        // console.log('qwer data id', event.id + "/" + RealTimeKeys.BREAKOUT_ROOM + user[RealTimeKeys.BREAKOUT_ROOM]);
        const interactionsData = await fetchInteractions(
            event.id + "/" + RealTimeKeys.BREAKOUT_ROOM + user[RealTimeKeys.BREAKOUT_ROOM], 
            user.id
        );
        const interactions = interactionsData.interactions;
        
        const challengesAnswered = this.getInteractions(
            interactions, 
            InteractionTypes.USER_CHALLENGE_CORRECT
        ).map(interaction => interaction.id).concat(
            this.getInteractions(
                interactions, 
                InteractionTypes.TEAM_CHALLENGE_CORRECT
            ).map(interaction => interaction.id)
        );

        const scenesCompleted = this.getInteractions(
            interactions, 
            InteractionTypes.SCENE_COMPLETE
        ).map(interaction => interaction.id);
        
        const roomCode = user[UserKeys.ROOM_CODE];
        const wasInGame = roomCode === RoomsCodes.GAME;
        const wasInIntro = wasInGame || roomCode === RoomsCodes.INTRO;

        let challenges = null;
        if (wasInIntro) {
            challenges = await this.getData(
                CollectionNames.HOTSPOT_DETAILS, 
                FirestoreProps.SCENE_ID, 
                scene.id,
                false
            );
            if (!challenges) {
                this.props.setModalMessages({ header: ModalHeaders.OOPS, message:  "no challenges that scene id (#627)" });
                return;
            };
        };
        
        let hotspots = null;
        if (wasInGame) {
            hotspots = await this.getData(
                CollectionNames.HOTSPOTS, 
                FirestoreProps.IMAGE_MAP_ID, 
                imageMap.id,
                false
            );
            if (!hotspots) {
                this.props.setModalMessages({ header: ModalHeaders.OOPS, message:  "no hotspots for that image map id (#629)" });
                return;
            };
        };

        // console.log('qwer was in intro and game', wasInIntro, wasInGame);
        // console.log('qwer user', user);
        // console.log('qwer challenge ids', challengesAnswered);
        // console.log('qwer scene ids', sceneChallengesAnswered);
        // console.log('qwer challenges', challenges);
        // console.log('qwer hotspots', hotspots);

        storage.refFromURL(storageUrl).child(`${this.props.abbreviation}/${imageMap.image}`)
            .getDownloadURL()
            .then(url => {
                const preloadImage = new Image();
                const product = products[0];
                preloadImage.onload = () => {
                    this.props.setClientTimeDifference(clientTimeDifference);
                    this.props.setEvent(event);
                    this.props.setOwnUser(user);
                    this.props.addProduct(product);
                    this.props.addProductId(product[FirestoreProps.ID]);
                    this.props.addScenes(scenes);
                    this.props.setSceneId(scene.id);
                    this.props.setSceneIndex(user[UserKeys.SCENE_INDEX]);
                    this.props.addImageMap(imageMap);
                    this.props.addImageMapProperties({
                        url,
                        width: preloadImage.width,
                        height: preloadImage.height,
                    });
                    this.props.addInteractions(interactions);
                    console.log('qwer NEED TO ADD SOLUTIONS NOT CHALLENGE IDS')
                    this.props.addSolvedSolutions(challengesAnswered);
                    this.props.addCompletedScenesIds(scenesCompleted);
                    if (challenges) {
                        this.props.addHotspotDetails(challenges);
                    };
                    if (hotspots) {
                        this.props.addHotspots(hotspots);
                    };
                    const { history, sceneIndex } = this.props;
                    const location = `/${event.passCode}/${PageTypes.SCENE}${sceneIndex + 1}`;
                    history.push(location);
                };
                preloadImage.src = url;
            })
            .catch(error => {
                this.props.setModalMessages({ header: ModalHeaders.OOPS, message: ModalMessages.BAD_DATA + " (#298)" });
        });
    };

    getData = async (collectionName, key, value, isSingleItem) => {
        this.props.setServerResponseState(ServerResponseStates.WAITING);
        const results = await fetchDataByFieldPairs(collectionName, [{ key, value }]);
        this.props.setServerResponseState(ServerResponseStates.DORMANT);
        return results && (!isSingleItem || results.length === 1) ? results : null;
    };

    getInteractions = (interactions, interactionType, pageType) => {
        if (!interactions) {
            return [];
        }
        return interactions.filter(interaction => {
            return interaction[InteractionKeys.TYPE] === interactionType
            && (!pageType || interaction[InteractionKeys.PAGE] === pageType)
        });
    };

    render() {
        return (<div>
            <p>Attempting to rejoin the game...</p>
        </div>);
    };
};

// const mapStateToProps = createStructuredSelector({
// });

const mapDispatchToProps = dispatch => ({
    addCompletedScenesIds: value => dispatch(addCompletedScenesIds(value)),
    addHotspotDetails: value => dispatch(addHotspotDetails(value)),
    addHotspots: value => dispatch(addHotspots(value)),
    addImageMap: value => dispatch(addImageMap(value)),
    addImageMapProperties: value => dispatch(addImageMapProperties(value)),
    addInteractions: value => dispatch(addInteractions(value)),
    addProduct: value => dispatch(addProduct(value)),
    addProductId: value => dispatch(addProductId(value)),
    addScenes: value => dispatch(addScenes(value)),
    addSolvedSolutions: value => dispatch(addSolvedSolutions(value)),
    resetApp: () => dispatch(resetApp()),
    setEvent: value => dispatch(setEvent(value)),
    setOwnUser: value => dispatch(setOwnUser(value)),
    setSceneId: value => dispatch(setSceneId(value)),
    setSceneIndex: value => dispatch(setSceneIndex(value)),
});

export default withUserUtils(
    connect(null, mapDispatchToProps)(RejoinPage)
);
