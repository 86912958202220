import React from "react";
import { connect } from "react-redux";

import { FirestoreProps, InternalProps } from "../firebase/firestore.types";

import { FormSelect } from "../global.styles";
import { setEventId } from "../redux/event/event.actions";

import { selectAmountBreakoutRooms, selectEventId, selectEvents } from "../redux/event/event.selectors";
import { selectProducts } from "../redux/product/product.selectors";

import { Metrics } from "../app.types";

const ChooseEventSelect = ({ amountBreakoutRooms, eventId, events, products, handleSubmit }) => {
    const nullOption = InternalProps.NULL_OPTION;
    let breakoutRoomNumbers = [];
    let i;
    for (i=0; i<amountBreakoutRooms; i++) {
        breakoutRoomNumbers.push(i + 1);
    };
    return (<>
        {events ?
            <form>
                <FormSelect 
                    name="eventId"
                    onChange={handleSubmit} 
                    defaultValue={eventId}
                >
                    {!eventId ? 
                        <option key={nullOption} value={nullOption}>Choose Event</option>
                        : null
                    }
                    {events.map(event => {
                        const filteredProducts = products ? 
                            products.filter(product => product[FirestoreProps.ID] === event[FirestoreProps.PRODUCT_ID])
                            : null;
                        if (!filteredProducts || filteredProducts.length !== 1){
                            return null;
                        };
                        const date = new Date(event[FirestoreProps.START_DATE][InternalProps.SECONDS] * Metrics.MILLIS_PER_SECOND);
                        const eventId = event[FirestoreProps.ID];
                        return filteredProducts.length === 1 ?
                            <option 
                                key={eventId} 
                                value={eventId}
                            >
                                {`${filteredProducts[0][FirestoreProps.ABBREVIATION]} for ${event[FirestoreProps.CLIENT]} (${date.toDateString()})`}
                            </option>
                            : null
                    })}
                </FormSelect>
                {/* <FormButton 
                    type="button" 
                    value="Cancel" 
                    onClick={handleCancel} 
                /> */}
            </form>
            :
            <p>loading events...</p>
        }
    </>);
}

const mapStateToProps = (state, ownProps) => ({
    amountBreakoutRooms: selectAmountBreakoutRooms(state),
    events: selectEvents(state),
    eventId: selectEventId(state),
    // handleCancel: ownProps.handleCancel, 
    handleSubmit: ownProps.handleSubmit, 
    products: selectProducts(state),
});

const mapDispatchToProps = dispatch => ({
    setEventId: value => dispatch(setEventId(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseEventSelect);
