import { createSelector } from "reselect";
import { FirestoreProps } from "../../firebase/firestore.types";

const eventReducer = state => state.event;

export const selectEvents = createSelector(
    [eventReducer],
    reducer => reducer.events
);

export const selectEventId = createSelector(
    [eventReducer],
    reducer => reducer.eventId
);

// ------------

export const selectEvent = createSelector(
    [selectEvents, selectEventId],
    (events, eventId) => {
        if (!events || !eventId) {
            return null;
        }
        const filteredEvents = events.filter(event => event.id === eventId);
        return filteredEvents.length === 1 ? filteredEvents[0] : null;
    }
);
    
// export const selectAbbreviation = createSelector(
//     [selectEvent],
//     event => event ? event.abbreviation : null
// );

export const selectAmountBreakoutRooms = createSelector(
    [selectEvent],
    event => event ? event[FirestoreProps.AMOUNT_BREAKOUT_ROOMS] : null
);

export const selectClient = createSelector(
    [selectEvent],
    event => event ? event[FirestoreProps.CLIENT] : null
);

export const selectMinPlayersPerTeam = createSelector(
    [selectEvent],
    event => event ? event[FirestoreProps.MIN_PLAYERS_PER_TEAM] : null
);

export const selectPassCode = createSelector(
    [selectEvent],
    event => event ? event[FirestoreProps.PASS_CODE] : null
);

export const selectSceneStarts = createSelector(
    [selectEvent],
    event => event ? event[FirestoreProps.SCENE_STARTS] : null
);

export const selectStartDate = createSelector(
    [selectEvent],
    event => event ? event.startDate : null
);

export const selectVideoMeetingId = createSelector(
    [selectEvent],
    event => event ? event.videoMeetingId : null
);

// ------------

export const selectSceneStartBySceneId = sceneId => createSelector(
    [selectSceneStarts],
    sceneStarts => sceneStarts ? sceneStarts[sceneId] : null
);
