import React from "react";
import { connect } from "react-redux";

import { selectInteractionsByHotspotDetailsId } from "../redux/interactions/interactions.selectors";
import { selectUsers } from "../redux/users/users.selectors";

import { InteractionKeys, UserKeys } from "../firebase/realtime.types";
import { TableCell, TableContainer } from "../global.styles";
import { getActionByInteractionType } from "./components.utils";

const FormerActivityList = ({ interactions, users }) => {
    // console.log('qwer BEFORE', interactions);
    interactions.sort((a, b) => {
        return b[InteractionKeys.TIMESTAMP] - a[InteractionKeys.TIMESTAMP];
        // return b[InteractionKeys.TYPE].localeCompare(a[InteractionKeys.TYPE])
        //     || a[InteractionKeys.TIMESTAMP] - b[InteractionKeys.TIMESTAMP];
    });
    // console.log('qwer AFTER', interactions);
    return (<>
        {interactions && interactions.length ?
            <>
                <TableContainer>
                    <TableCell widthPercent={30}>player</TableCell>
                    <TableCell widthPercent={55}>action</TableCell>
                    <TableCell widthPercent={15}>time</TableCell>
                </TableContainer>
                <hr />
                {interactions.map(interaction => {
                    const interactionUserId = interaction[InteractionKeys.USER_ID];
                    const filteredUsers = users.filter(user => user.id === interactionUserId);
                    const user = filteredUsers.length === 1 ? filteredUsers[0] : null;
                    if (user) {
                        const interactionDate = new Date(interaction[InteractionKeys.TIMESTAMP]);
                        return <TableContainer key={interaction.id}>
                            <TableCell widthPercent={30}>{user[UserKeys.NAME]}</TableCell>
                            <TableCell widthPercent={55}>{getActionByInteractionType(interaction[InteractionKeys.TYPE])}</TableCell>
                            <TableCell widthPercent={15}>
                                    {String(interactionDate.getHours()).padStart(2, "0")}:{String(interactionDate.getMinutes()).padStart(2, "0")}
                                </TableCell>
                        </TableContainer>;
                    } else {
                        return null;
                    };
                })}
            </>
            :
            <p>You're the first here!</p>
        }
        </>);
};

const mapStateToProps = (state, ownProps) => ({
    interactions: selectInteractionsByHotspotDetailsId(ownProps.hotspotDetailsId)(state),
    users: selectUsers(state),
});

export default connect(mapStateToProps)(FormerActivityList);
