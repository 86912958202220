import ProductActionTypes from "./product.types";
import { setFirebaseElement, setFirebaseElements } from "../redux.utils";

const INITIAL_STATE = {
    hints: null,
    hotspots: null,
    hotspotsDetails: null,
    imageMaps: null,
    items: null,
    media: null,
    storageUrls: null,
    productId: null,
    products: null,
    scenes: null,
    solutionDependencies: null,
};

const productReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ProductActionTypes.ADD_HOTSPOTS:
            return {
                ...state,
                hotspots: setFirebaseElements(state.hotspots, action.payload)
            };
        case ProductActionTypes.ADD_HOTSPOT_DETAILS:
            return {
                ...state,
                hotspotsDetails: setFirebaseElements(state.hotspotsDetails, action.payload)
            };
        case ProductActionTypes.ADD_HINT:
            return {
                ...state,
                hints: setFirebaseElement(state.hints, action.payload)
            };
        case ProductActionTypes.ADD_IMAGE_MAP:
            return {
                ...state,
                imageMaps: setFirebaseElement(state.imageMaps, action.payload)
            };
        case ProductActionTypes.ADD_ITEM:
            return {
                ...state,
                items: setFirebaseElement(state.items, action.payload),
            };
        case ProductActionTypes.ADD_MEDIUM:
            return {
                ...state,
                media: setFirebaseElement(state.media, action.payload),
            };
        case ProductActionTypes.ADD_PRODUCT:
            return {
                ...state,
                products: setFirebaseElement(state.products, action.payload)
            };
        case ProductActionTypes.ADD_PRODUCT_ID:
            return {
                ...state,
                productId: action.payload,
            };
        case ProductActionTypes.ADD_PRODUCTS:
            return {
                ...state,
                products: setFirebaseElements(state.products, action.payload)
            };
        case ProductActionTypes.ADD_SCENES:
            return {
                ...state,
                scenes: setFirebaseElements(state.scenes, action.payload)
            };
        case ProductActionTypes.ADD_SOLUTION_DEPENDENCIES:
            return {
                ...state,
                solutionDependencies: setFirebaseElements(state.solutionDependencies, action.payload)
            };
        case ProductActionTypes.ADD_STORAGE_URL:
            return {
                ...state,
                storageUrls: setFirebaseElement(state.storageUrls, action.payload),
            };
        default:
            return state;
    };
};

export default productReducer;
