import React from "react";

import { FormButton, FormSelect, TableCell, TableContainer } from "../../global.styles";
import InventoryItemsSelect from "../inventory-items-select.component";
import { ItemInputTypes, ChallengeOptionsSets, ChallengeOptionsSetTypes, ResponseElements } from "./item-io.types";

const ItemInputs = ({ 
    uniqueId, 
    item,
    inputs, 
    inventoryItems, 
    handleChange, 
    handleSubmit, 
    handleInventoryItemChange 
}) => {
    let parentCount = 0;
    let childCount = 0;
    let grandChildCount = 0;
    const group = ResponseElements.GROUP;
    const inputType = ResponseElements.INPUT;
    const connector = ResponseElements.CONNECTOR;
    const separator = ResponseElements.SEPARATOR;
    // console.log('qwer inputs', inputs)
    return inputs && inputs.length > 0 ? 
        <>
            {inputs && inputs.length > 0 ? 
                <form onSubmit={handleSubmit}>
                    {inputs.map(input => {
                        // console.log('qwer input', input)
                        switch (input.type) {
                            case ItemInputTypes.SELECT:
                                const objectSets = input.value.map(value => {
                                    switch (value) {
                                        case ChallengeOptionsSetTypes.ALPHA:            return ChallengeOptionsSets.ALPHA;
                                        case ChallengeOptionsSetTypes.ALPHANUMERIC:     return ChallengeOptionsSets.ALPHANUMERIC;
                                        case ChallengeOptionsSetTypes.NUMERIC:          return ChallengeOptionsSets.NUMERIC;
                                        default:                                        return ChallengeOptionsSets.EMPTY;
                                    };
                                });
                                return <TableContainer key={uniqueId + "_" + parentCount++}>
                                    {objectSets.map(objectSet => {
                                        const selectName = `${group}${connector}${input.key}${separator}${inputType}${connector}${childCount}`;
                                        handleChange({ target: { name: selectName, value: objectSet[0] } });
                                        return <TableCell key={selectName} widthPercent={100 / objectSets.length}>
                                            <FormSelect
                                                key={uniqueId + "_" + childCount++} 
                                                name={selectName} 
                                                onChange={handleChange}>
                                                {objectSet.map(object => {
                                                    return <option key={uniqueId + "_" + grandChildCount++} value={object}>{object}</option>
                                                })}
                                            </FormSelect>
                                        </TableCell>
                                    })}
                                </TableContainer>
                            // case ItemInputTypes.RADIO:
                            //     count += 1;
                            //     // console.log("radio group", uniqueId + "_" + count);
                            //     return <div key={uniqueId + "_" + count}>
                            //         <p>{input.title}</p>
                            //         {input.value.split(",").map((value) => {
                            //             const trimmedValue = value.trim();
                            //             count += 1;
                            //             const radioInputName = uniqueId + "_" + count;
                            //             // console.log("radio button", radioInputName);
                            //             return <div key={radioInputName}>
                            //                 <input type="radio" id={radioInputName} name={radioInputName} value={trimmedValue}></input>
                            //                 <label key={radioInputName} htmlFor={radioInputName}>{trimmedValue}</label>
                            //             </div>
                            //         })}
                            //     </div>
                            // case ItemInputTypes.TEXT:
                            //     count += 1;
                            //     // console.log("textfield", uniqueId + "_" + count);
                            //     return <div key={uniqueId + "_" + count}>
                            //         <label htmlFor={inputName}>{input.title}</label>
                            //         <input 
                            //             type="text" 
                            //             id={inputName}
                            //             name="someAnswer" 
                            //         //    value={arrayAnswer[0]} 
                            //             onChange={handleChange} 
                            //             // label="code" 
                            //             required 
                            //         />
                            //     </div>
                            default:
                                return <p key={uniqueId + "_" + parentCount}>{`ERROR no case for input type: ${input.type}`}</p>
                        }
                    })}
                    <FormButton type="submit" value="Answer" />
                </form>
                : null
            }
        </>
        :
        <>
            {inventoryItems && inventoryItems.length > 0 ?
                <InventoryItemsSelect
                    inventoryItems={inventoryItems}
                    selectedItem={item}
                    handleChange={handleInventoryItemChange}
                />
                : null
            }
        </>
};

export default ItemInputs;
