import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { 
    fetchClientTimeDifference, 
    formatWithId, 
    realTime, 
    storage, 
    storageUrl 
} from "../firebase/firestore.utils";

import { selectAllIds, selectDebug } from "../redux/admin/admin.selectors";
import { selectClientTimeDifference } from "../redux/game/game.selectors";
import { selectEvent } from "../redux/event/event.selectors";
import { selectProductAbbreviation } from "../redux/product/product.selectors";

import { addInteraction } from "../redux/interactions/interactions.actions";
import { addStorageUrl } from "../redux/product/product.actions";
import { setClientTimeDifference } from "../redux/game/game.actions";
import { setPageName } from "../redux/game/game.actions";
import { setModalMessages, setServerResponseState } from "../redux/admin/admin.actions";

import { ModalHeaders, ModalMessages } from "../modal.types";
import { RealTimeEvents } from "../firebase/realtime.types";
import { FirestoreProps } from "../firebase/firestore.types";

const withGlobalUtils = WrappedComponent => {
    class WithGlobalUtils extends React.Component {
        constructor(props) {
            super(props);
            this.interactionsListeners = {};
        };
        
        async componentDidMount() {
            // console.log('qwer componentDidMount', this.props.clientTimeDifference)
            if (!this.props.clientTimeDifference) {
                const clientTimeDifference = await fetchClientTimeDifference();
                this.props.setClientTimeDifference(clientTimeDifference);
            };
        };
    
        componentWillUnmount() {
            this.ignoreInteractions();
        };
        
        addInteractionBySnap = snap => {
            if (!snap) {
                return null;
            };
            const interaction = formatWithId(snap.key, snap.val());
            this.props.addInteraction(interaction);
            return interaction;
        };

        fetchStorageUrl = fileName => {
            const { abbreviation, addStorageUrl } = this.props;
            if (this.props.allIds.indexOf(fileName) > -1) {
                return;
            }
            storage.refFromURL(storageUrl).child(`${abbreviation}/${fileName}`)
                .getDownloadURL()
                .then(url => {
                    addStorageUrl({ id: fileName, url });
                })
                .catch(error => {
                    this.props.setModalMessages({ 
                        header: ModalHeaders.OOPS, 
                        message: ModalMessages.BAD_DATA + " (#9433)" 
                    });
                });
        };
        
        getEventThread = () => `${this.props.event[FirestoreProps.ID]}`;
        
        ignoreInteractions = () => {
            Object.keys(this.interactionsListeners).forEach(key => {
                this.interactionsListeners[key].off();
            });
            this.interactionsListeners = {};
        };

        listenForInteractions = (thread, callback) => {
            // console.log('qwer', thread, this.interactionsListeners);
            try {
                const interactionListener = realTime.ref(thread);
                // console.log('qwer', thread, this);
                interactionListener.on(
                    RealTimeEvents.CHILD_ADDED, 
                    snap => callback(snap)
                );
                this.interactionsListeners[thread] = interactionListener;
            } catch (error) {
                console.log('qwer', error)
                // this.props.setModalMessages({ 
                //     header: ModalHeaders.OOPS + " (#794)", 
                //     message: error.message  
                // });
            };
        };

        render() {
            return <WrappedComponent 
                addInteractionBySnap={this.addInteractionBySnap}
                fetchStorageUrl={this.fetchStorageUrl}
                getEventThread={this.getEventThread}
                ignoreInteractions={this.ignoreInteractions}
                listenForInteractions={this.listenForInteractions}
                {...this.props} 
            />
        };
    };

    const mapStateToProps = createStructuredSelector({
        abbreviation: selectProductAbbreviation,
        allIds: selectAllIds,
        clientTimeDifference: selectClientTimeDifference,
        debug: selectDebug,
        event: selectEvent,
    });
    
    const mapDispatchToProps = dispatch => ({
        addInteraction: value => dispatch(addInteraction(value)),
        addStorageUrl: value => dispatch(addStorageUrl(value)),
        setPageName: value => dispatch(setPageName(value)),
        setClientTimeDifference: value => dispatch(setClientTimeDifference(value)),
        setModalMessages: value => dispatch(setModalMessages(value)),
        setServerResponseState: value => dispatch(setServerResponseState(value)),
    });
    
    return connect(mapStateToProps, mapDispatchToProps)(WithGlobalUtils)
};

export default withGlobalUtils;