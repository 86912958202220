const GameActionTypes = {
    ADD_SOLVED_SOLUTION: "ADD_SOLVED_SOLUTION",
    ADD_SOLVED_SOLUTIONS: "ADD_SOLVED_SOLUTIONS",
    ADD_COMPLETED_SCENE_ID: "ADD_COMPLETED_SCENE_ID",
    ADD_COMPLETED_SCENES_IDS: "ADD_COMPLETED_SCENES_IDS",
    ADD_IMAGE_MAP_PROPERTIES: "ADD_IMAGE_MAP_PROPERTIES",
    ADD_INVENTORY_ITEM: "ADD_INVENTORY_ITEM",
    ADD_INVENTORY_ITEM_STATE: "ADD_INVENTORY_ITEM_STATE",
    COMPLETE_INVENTORY_ITEMS_USAGE: "COMPLETE_INVENTORY_ITEMS_USAGE",
    SET_HOTSPOT_DETAILS_ID: "SET_HOTSPOT_DETAILS_ID",
    SET_CLIENT_TIME_DIFFERENCE: "SET_CLIENT_TIME_DIFFERENCE",
    SET_HINT_STATE: "SET_HINT_STATE",
    SET_PAGE_NAME: "SET_PAGE_NAME",
    SET_SCENE_ID: "SET_SCENE_ID",
    SET_SCENE_INDEX: "SET_SCENE_INDEX",
};

export default GameActionTypes;