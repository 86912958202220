export const RealTimeEvents = {
    CHILD_ADDED: "child_added",
    CHILD_CHANGED: "child_changed",
    CHILD_MOVED: "child_moved",
    CHILD_REMOVED: "child_removed",
    VALUE: "value",
};

export const RealTimeKeys = {
    ADMIN: "ad",
    BREAKOUT_ROOM: "br",
    USERS: "us",
};

export const InteractionKeys = {
    PAGE: "pg",
    SCENE_ID: "si",
    TIMESTAMP: "ts",
    TYPE: "ty",
    USER_ID: "ui",
    OTHER_VALUES: "ov",
};

export const InteractionTypes = {
    CHALLENGE_INCORRECT: "CF",
    CLICKED_OBJECT: "Cl",
    ENTRY: "En",
    EVENT_UPDATED: "EU",
    HELP: "Hp",
    INFO_FOUND: "IF",
    INACTIVE: "Ia",
    INVENTORY_ITEM: "II",
    INVENTORY_ITEM_USED: "IU",
    INVENTORY_ITEM_MISUSED: "IM",
    LEFT_GAME: "LG",
    MAJOR_HINT: "H2",
    MINOR_HINT: "H1",
    SCENE_CHALLENGE_CORRECT: "SC",
    SCENE_CHALLENGE_INCORRECT: "SI",
    USER_CHALLENGE_CORRECT: "UC",
    TEAM_CHALLENGE_CORRECT: "TC",
    TIMER_WARNING: "TW",
};

export const RoomsCodes = {
    INTRO: "in",
    GAME: "gm",
    LOBBY: "lb",
    LOUNGE: "ln",
    NONE: "",
};

export const UserKeys = {
    ACTIVE: "ac",
    BREAKOUT_ROOM_NUMBER: "br",
    NAME: "nm",
    ROOM_CODE: "rc",
    SCENE_INDEX: "sx",
};

