import React from "react";
import { connect } from "react-redux";

import { Column, ContentContainer, Row } from "../global.styles";
import AdminLoginForm from "../components/admin-login-form.component";
import ChooseEventSelect from "../components/choose-event-select.component";
import withAdminUtils from "./with-admin-utils";

import { auth } from "../firebase/firestore.utils";
import { CollectionNames, FirestoreProps } from "../firebase/firestore.types";

import { setAdminId } from "../redux/admin/admin.actions";

import { ModalHeaders } from "../modal.types";

import { PageTypes } from "./page.types";
import CreateEventForm from "../components/create-event-form.component";

class AdminLoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emailAddress: "",
            password: "",
            event: null,
        };
        this.submitting = false;
    };

    componentDidMount() {
        const { events, products, fetchCollection } = this.props;
        auth.onAuthStateChanged(async user => {
            let adminId = null;
            if (user) {
                if (user.isAnonymous) {
                    auth.signOut();
                } else {
                    adminId = user[FirestoreProps.UNIQUE_ID];
                    // this.setState({ loggedIn: true })
                    if (!events) {
                        fetchCollection(CollectionNames.EVENTS);
                    };
                    if (!products) {
                        fetchCollection(CollectionNames.PRODUCTS);
                    };
                };
            };
            this.props.setAdminId(adminId);
            this.submitting = false;
        });
        this.props.setPageName(PageTypes.ADMIN);
    };

    handleCancel = e => {
        e.preventDefault();
        this.setState({
            emailAddress: "",
            password: "",
        });
    };

    handleChange = e => {
        const { value, name } = e.target;
        this.setState({ [name]: value });
    };

    handleEmailSubmit = e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        const { emailAddress, password } = this.state;
        auth.signInWithEmailAndPassword(emailAddress.trim(), password.trim())
            .catch(error => {
                this.props.setModalMessages({ 
                    header: ModalHeaders.OOPS, 
                    message: `${error.message} (#687)` 
                });
                this.submitting = false;
            });
    };

    render() {
        // herehere is the use of adminId/state as a container for a logged in prop secure enough? perhaps i can just query a firestore prop instead
        const { adminId } = this.props;
        // console.log('qwer', loggedIn);
        return (<ContentContainer>
            <Row>
                {adminId ?
                    <>
                        <Column widthPercent={50}>
                            <ChooseEventSelect 
                                handleSubmit={this.props.handleChooseEventSubmit}
                            />
                        </Column>
                        <Column widthPercent={50}>
                            <CreateEventForm 
                                handleSubmit={this.props.handleChooseEventSubmit}
                            />
                        </Column>
                    </>
                    :
                    <Column widthPercent={100}>
                        <AdminLoginForm
                            emailAddress={this.state.emailAddress}
                            password={this.state.password}
                            handleChange={this.handleChange} 
                            handleSubmit={this.handleEmailSubmit} 
                        />
                    </Column>
                }
            </Row>
        </ContentContainer>);
    };
}

// const mapStateToProps = createStructuredSelector({
//     adminId: selectAdminId,
//});

const mapDispatchToProps = dispatch => ({
    setAdminId: value => dispatch(setAdminId(value)),
});

export default withAdminUtils(
    connect(null, mapDispatchToProps)(AdminLoginPage)
);
