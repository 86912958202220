import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
import { FirestoreProps } from "../../firebase/firestore.types";

import adminReducer from "../admin/admin.reducer";
import eventReducer from "../event/event.reducer";
import gameReducer from "../game/game.reducer";
import interactionsReducer from "../interactions/interactions.reducer";
import lobbyReducer from "../lobby/lobby.reducer";
import productReducer from "../product/product.reducer";
import teamReducer from "../team/team.reducer";
import userReducer from "../users/users.reducer";

import RootActionTypes from "./root.types";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["admin", "event", "game", "interactions", "lobby", "product", "team", "user"]
};

const appReducer = combineReducers({
    admin: adminReducer,
    event: eventReducer,
    game: gameReducer,
    interactions: interactionsReducer,
    lobby: lobbyReducer,
    product: productReducer,
    team: teamReducer,
    user: userReducer
});

const rootReducer = (state, action) => {
    // console.log('qwer ROOOOT', action.type);
    if (action[FirestoreProps.TYPE] === RootActionTypes.RESET_REDUX) {
        state = undefined;
    };
    return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
