import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import withGlobalUtils from "./with-global-utils";

import { FirestoreProps, InternalProps } from "../firebase/firestore.types";
import { RealTimeKeys } from "../firebase/realtime.types";

import { selectSelfBreakoutRoomNumber } from "../redux/users/users.selectors";

import { getDateIsToday } from "./page.utils";

const withUserUtils = WrappedComponent => {
    class WithUserUtils extends React.Component {
        async componentDidMount() {
            const { event } = this.props;
            // console.log('qwer universal', event);
            if (event) {
                const { clientTimeDifference } = this.props;
                const eventSeconds = event[FirestoreProps.START_DATE][InternalProps.SECONDS];
                const eventDateIsToday = await getDateIsToday(clientTimeDifference, eventSeconds);
                if (!eventDateIsToday) {
                    window.location = "/";
                };
            };
        };

        getBreakoutRoomThread = () => `${this.props.getEventThread()}/${RealTimeKeys.BREAKOUT_ROOM}${this.props.breakoutRoomNumber}`;

        render() {
            return <WrappedComponent 
                getBreakoutRoomThread={this.getBreakoutRoomThread}
                {...this.props} 
            />
        };
    };

    const mapStateToProps = createStructuredSelector({
        breakoutRoomNumber: selectSelfBreakoutRoomNumber,
    });
    
    return withGlobalUtils(
        connect(mapStateToProps)(WithUserUtils)
    );
};

export default withUserUtils;