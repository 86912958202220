import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import SceneTimer from "../components/scene-timer.component";
import withUserUtils from "./with-user-utils";

import { InteractionTypes, RealTimeKeys, RoomsCodes, UserKeys } from "../firebase/realtime.types";
import { fetchInteractions, storeInteraction, storeUser } from "../firebase/realtime.utils";

import { selectPageName, selectSceneId, selectSceneIndex } from "../redux/game/game.selectors";
import { selectEventId,  } from "../redux/event/event.selectors";
import { selectSelf } from "../redux/users/users.selectors";
import { selectScenes } from "../redux/product/product.selectors";

import { setHintState, setSceneId, setSceneIndex } from "../redux/game/game.actions";

import { ModalHeaders, ModalMessages } from "../modal.types";
import { HintState, PageTypes } from "./page.types";
import { FirestoreProps } from "../firebase/firestore.types";

class LoungePage extends React.Component {
    constructor(props) {
        super(props);

        // this.state = {
        //     exitInteractionId: null,
        // };
        this.submitting = false;
    };

    async componentDidMount() {
        const { pageName, sceneId, user } = this.props;
        const thread = this.props.getBreakoutRoomThread();
        const pageType = PageTypes.LOUNGE;
        const interactions = await fetchInteractions(thread, user.id, pageType);
        
        // this.setState({ exitInteractionId: interactions.exitId });
        
        if (
            pageName === PageTypes.GAME 
            || pageName === PageTypes.ITEM
            || pageName === PageTypes.INVENTORY_ITEMS
        ) {
            storeInteraction(
                user.id, 
                pageType, 
                InteractionTypes.ENTRY, 
                thread, 
                sceneId, 
                null,
                interactions.entryId
            );
        };
        this.props.setHintState(HintState.NONE);
        this.props.setPageName(pageType);
    };

    // componentWillUnmount() {
    //     const { sceneId, user } = this.props;
    //     storeInteraction(
    //         user.id, 
    //         PageTypes.LOUNGE, 
    //         InteractionTypes.EXIT, 
    //         this.props.getBreakoutRoomThread(), 
    //         sceneId, 
    //         null,
    //         this.state.exitInteractionId
    //     );
    // };

    getNextSceneId = () => {
        const { sceneIndex, scenes } = this.props;
        const nextScenes = scenes.filter(scene => scene[FirestoreProps.INDEX] === sceneIndex + 1);
        return nextScenes.length === 1 ? nextScenes[0].id : null;
    };

    handlePlaySubmit = async e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        const nextSceneId = this.getNextSceneId();
        if (nextSceneId) {
            this.props.setSceneIndex(this.props.sceneIndex + 1);
            this.props.setSceneId(nextSceneId);
        } else {
            console.log('qwer THE GAME IS OVER...');
            return;
        };
        const { eventId, user } = this.props;
        const updatedUser = await storeUser(
            user[UserKeys.NAME], 
            user[UserKeys.BREAKOUT_ROOM_NUMBER], 
            RoomsCodes.INTRO,
            user[UserKeys.SCENE_INDEX], 
            eventId + "/" + RealTimeKeys.USERS, 
            user.id
        );
        if (updatedUser) {
            this.props.setOwnUser(updatedUser);
        } else {
            this.props.setModalMessages({ header: ModalHeaders.OOPS, message: ModalMessages.BAD_DATA + " (#934)" });
            return;
        };
     };
    
    render() {
        const { clientTimeDifference } = this.props;
        const nextSceneId = this.getNextSceneId();
        // console.log('qwer', this.props.sceneId, nextSceneId);
        return <div>
            <p>Lounge</p>
            <SceneTimer
                clientTimeDifference={clientTimeDifference}
                sceneId={nextSceneId}
                useSceneTime={false}
                handleSubmit={this.handlePlaySubmit}
            />
        </div>;
    };
};

const mapStateToProps = createStructuredSelector({
    eventId: selectEventId,
    pageName: selectPageName,
    sceneId: selectSceneId,
    sceneIndex: selectSceneIndex,
    scenes: selectScenes,
    user: selectSelf,
});

const mapDispatchToProps = dispatch => ({
    setHintState: value => dispatch(setHintState(value)),
    setSceneId: value => dispatch(setSceneId(value)),
    setSceneIndex: value => dispatch(setSceneIndex(value)),
});

export default withUserUtils(
    connect(mapStateToProps, mapDispatchToProps)(LoungePage)
);
