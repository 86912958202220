import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Column, ContentContainer, Row } from "../global.styles";
import OtherTeamsList from "../components/other-teams-list.component";
import TrafficLightList from "../components/traffic-light-list.component";
import SceneTimer from "../components/scene-timer.component";
import withIntroUtils from "./with-intro-utils";

import { 
    CollectionNames, 
    ItemStates, 
    FirestoreProps, 
    TeamStates, 
    InternalProps, 
    ServerResponseStates 
} from "../firebase/firestore.types";
import { 
    addFirestoreData, 
    fetchDatumByDocName, 
    fetchDataByFieldPairs, 
    listenForUpdates, 
    updateFirestoreDatum 
} from "../firebase/firestore.utils";
import { InteractionTypes, RealTimeKeys, UserKeys, RoomsCodes } from "../firebase/realtime.types";
import { 
    createInteraction, 
    fetchDocuments, 
    fetchInteractions, 
    storeInteraction, 
    storeUser 
} from "../firebase/realtime.utils";

import { 
    selectBreakoutRoomUserIds, 
    selectNumBreakoutRoomUsers, 
    selectUsers,
} from "../redux/users/users.selectors";
import { selectPageName } from "../redux/game/game.selectors";
import { selectMinPlayersPerTeam} from "../redux/event/event.selectors";
import { selectNumCertifiedTeammates, selectTeamState } from "../redux/lobby/lobby.selectors";

import { addHotspotDetails, addItem, addSolutionDependencies } from "../redux/product/product.actions";
import { addOtherUser } from "../redux/users/users.actions";
import { setAllCertifiedTeammateIds, setCaptainVoteIds, setTeamState } from "../redux/lobby/lobby.actions";
import { setCaptainId, setOtherTeams, setTeamName } from "../redux/team/team.actions";

import { ModalHeaders, ModalMessages } from "../modal.types";
import { PageTypes } from "./page.types";

import { getDateFromClientTimeDifference } from "../app.utils";

class LobbyPage extends React.Component {

    constructor(props) {
        super(props);
        
        this.selfTeamListener = null;
        this.otherTeamsListener = null;
        this.state = {
            formTeamName: "",
            awaitingServer: false,
        };
        this.sceneTimerComplete = false;
        this.submitting = false;
    };

    async componentDidMount() {
        // console.log('qwert', this.props);
        const { pageName, sceneId, self } = this.props;
        const selfId = self.id;
        const thread = this.props.getBreakoutRoomThread();
        const pageType = PageTypes.LOBBY;
        const interactions = await fetchInteractions(thread, selfId, pageType);
        
        if (interactions && interactions.interactions) {
            this.props.addInteractions(interactions.interactions);
        };
        if (pageName === PageTypes.EMAIL) {
            storeInteraction(
                selfId, 
                pageType, 
                InteractionTypes.ENTRY, 
                thread, 
                sceneId, 
                null,
                interactions.entryId
            );
        };
        this.selfTeamListener = await listenForUpdates(
            CollectionNames.TEAM, 
            this.getRoomId(),
            team => this.updateTeam(team)
        );
        this.otherTeamsListener = await listenForUpdates(
            CollectionNames.TEAM, 
            this.getSharedRoomId(),
            otherTeams => this.updateOtherTeams(otherTeams)
        );
        this.props.setPageName(pageType);
    };

    componentWillUnmount() {
        if (this.selfTeamListener) {
            this.selfTeamListener();
        };
        if (this.otherTeamsListener) {
            this.otherTeamsListener();
        };
    };

    assessInventoryItemIdCollection = collection => {
        if (!collection) {
            return;
        }
        for (const itemId of collection) {
            // console.log('qwer itemt ID', itemId);
            fetchDatumByDocName(
                CollectionNames.ITEMS, 
                itemId,
                item => {
                    // console.log('qwer itemt', item);
                    this.assessItem(item);
                }
            );
        };
    };

    assessItem = item => {
        if (!item) {
            return;
        };
        if (this.props.allIds.indexOf(item[FirestoreProps.ID]) > -1) {
            return;
        };
        const { assessMediaIdCollection } = this.props;
        this.props.addItem(item);
        const mediaIds = item[FirestoreProps.MEDIA_IDS];
        if (mediaIds !== undefined) {
            assessMediaIdCollection(mediaIds[ItemStates.BLOCKED]);
            assessMediaIdCollection(mediaIds[ItemStates.DEFAULT]);
            assessMediaIdCollection(mediaIds[ItemStates.UNLOCKED]);
        };
        const inventoryItemIds = item[FirestoreProps.INVENTORY_ITEM_IDS];
        // console.log('qwer item deets', item.id, mediaIds, inventoryItemIds)
        if (inventoryItemIds !== undefined) {
            this.assessInventoryItemIdCollection(inventoryItemIds[ItemStates.BLOCKED]);
            this.assessInventoryItemIdCollection(inventoryItemIds[ItemStates.DEFAULT]);
            this.assessInventoryItemIdCollection(inventoryItemIds[ItemStates.UNLOCKED]);
        };

    }

    getSharedRoomId = () => `${this.props.eventId}-0`;

    getRoomId = () => `${this.props.eventId}-${this.props.breakoutRoomNumber}`;
    
    goToIntro = async () => {
        // console.log('qwer', this.submitting, this.props.teamState, this.sceneTimerComplete);
        if (
            this.submitting 
            || this.props.teamState !== TeamStates.COMPLETE
            || !this.sceneTimerComplete
        ) {
            return;
        }
        this.submitting = true;
        const { eventId, self } = this.props;
        this.props.setServerResponseState(ServerResponseStates.WAITING);
        const hotspotsDetails = await fetchDataByFieldPairs(
            CollectionNames.HOTSPOT_DETAILS, 
            [{ 
                key: FirestoreProps.SCENE_ID, 
                value: this.props.sceneId
            }]
        );
        this.props.setServerResponseState(ServerResponseStates.DORMANT);
        if (hotspotsDetails) {
            this.props.addHotspotDetails(hotspotsDetails);
            for (const hotspotDetails of hotspotsDetails) {
                const item = await fetchDatumByDocName(
                    CollectionNames.ITEMS, 
                    hotspotDetails[FirestoreProps.ITEM_ID]
                );
                this.assessItem(item);
                const hotspotDetailsId = hotspotDetails[FirestoreProps.ID];
                const reversedHotspotDetailsId = [...hotspotDetailsId].reverse().join('');
                if (this.props.allIds.indexOf(reversedHotspotDetailsId) < 0) {
                    const solutionDependencies = await fetchDataByFieldPairs(
                        CollectionNames.SOLUTION_DEPENDENCIES, 
                        [
                            { key: FirestoreProps.HOTSPOT_DETAILS_ID, value: hotspotDetailsId },
                        ],
                    );
                    this.props.addSolutionDependencies(solutionDependencies ?
                        solutionDependencies
                        : [{ 
                            [FirestoreProps.ID]: reversedHotspotDetailsId,
                            [FirestoreProps.HOTSPOT_DETAILS_ID]: hotspotDetailsId,
                            [FirestoreProps.DEPENDENT_SOLUTION_IDS]: null,
                        }]
                    );    
                };
            };
        };
        const updatedUser = await storeUser(
            self[UserKeys.NAME], 
            self[UserKeys.BREAKOUT_ROOM_NUMBER], 
            RoomsCodes.INTRO,
            self[UserKeys.SCENE_INDEX], 
            eventId + "/" + RealTimeKeys.USERS, 
            self.id
        );
        if (updatedUser) {
            this.props.setOwnUser(updatedUser);
        } else {
            this.props.setModalMessages({ 
                header: ModalHeaders.OOPS, 
                message: ModalMessages.BAD_DATA + " (#932)" 
            });
            return;
        };
    };

    handleCaptainSubmit = async e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        const { minPlayersPerTeam, self } = this.props;
        const selfId = self.id;
        // console.log('qwer', e.target.id);
        this.props.setServerResponseState(ServerResponseStates.WAITING);
        const success = await addFirestoreData(
            CollectionNames.TEAM_CAPTAIN, 
            {
                captainVoteId: e.target.id,
                roomId: this.getRoomId(),
                selfId: selfId,
                sharedRoomId: this.getSharedRoomId(),
                singlePlayerTeamsPermitted: minPlayersPerTeam === 1,
            },
            selfId
        );
        this.props.setServerResponseState(ServerResponseStates.DORMANT);
        // console.log('qwer', success);
        if (success) {
            // console.log('qwer TT');
            this.setState({ awaitingServer: true });
        };
    };

    handleCertificationReject = () => {
        this.props.setModalMessages({ 
            header: "That's fine", 
            message: "Just wait until it does match your Zoom team then press 'Yes'" 
        });
    };

    handleCertificationSubmit = e => {
        // console.log('qwer');
        e.preventDefault();
        this.submitCertification(false);
    };

    handleChange = e => {
        const { value, name } = e.target;
        this.setState({ [name]: value });
    };

    handleSceneTimerComplete = () => {
        // console.log('qwer handleSceneTimerComplete');
        this.sceneTimerComplete = true;
        this.goToIntro();
    };

    handleSceneTimerWarning = timer => {
        // console.log('qwer', timer)
        const { addInteraction, clientTimeDifference, sceneId, self } = this.props;
        addInteraction(
            createInteraction(
                self.id, 
                PageTypes.LOBBY, 
                InteractionTypes.TIMER_WARNING,
                getDateFromClientTimeDifference(clientTimeDifference),
                sceneId,
                { [InternalProps.TIMER_MINUTES]: timer.minutes },
            )
        );
    };

    handleTeamNameSubmit = async e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        const name = this.state.formTeamName.trim();
        const roomId = this.getRoomId();
        // console.log('qwer', this.state.formTeamName);
        this.props.setServerResponseState(ServerResponseStates.WAITING);
        updateFirestoreDatum(
            CollectionNames.TEAM, 
            { 
                teams: {
                    [roomId]: { name },
                }
            }, 
            this.getSharedRoomId(),
        );
        const success = await updateFirestoreDatum(
            CollectionNames.TEAM, 
            { 
                name,
                state: TeamStates.COMPLETE,
            },
            roomId
        );
        this.props.setServerResponseState(ServerResponseStates.DORMANT);
        // console.log('qwer TT');
        if (success) {
            this.setState({ formTeamName: "" });
        };
        this.goToIntro();
    };

    submitCertification = async autocertifySelf => {
        if (this.submitting) {
            return;
        };
        const { numBreakoutRoomUsers, numCertifiedTeammates } = this.props;
        const everyoneCertified = numCertifiedTeammates === numBreakoutRoomUsers;
        // console.log('qwer userData', userData );
        // console.log('qwer everyone', everyoneCertified, this.state.awaitingServer);
        if (autocertifySelf && everyoneCertified) {
            return;
        };
        this.submitting = true;
        const { breakoutRoomUserIds, minPlayersPerTeam, self } = this.props;
        const selfId = self.id;
        const selfIdIndex = breakoutRoomUserIds.indexOf(selfId);
        if (selfIdIndex > -1) {
            const newBreakoutRoomUserIds = [...breakoutRoomUserIds];
            if (selfIdIndex > -1) {
                newBreakoutRoomUserIds.splice(selfIdIndex, 1);
            };
            const selfData =                 {
                otherIds: newBreakoutRoomUserIds,
                roomId: this.getRoomId(),
                selfId: selfId,
                sharedRoomId: this.getSharedRoomId(),
                singlePlayerTeamsPermitted: minPlayersPerTeam === 1,
                autocertifySelf
            };
            this.props.setServerResponseState(ServerResponseStates.WAITING);
            const success = await addFirestoreData(CollectionNames.TEAM_CERTIFICATION, selfData, selfId);
            this.props.setServerResponseState(ServerResponseStates.DORMANT);
            // if (success && !autocertifySelf) {
            if (success) {
                // console.log('qwer TT');
                this.setState({ awaitingServer: true });
            };
        };
    };

    updateOtherTeams = otherTeams => {
        const { addOtherUser, eventId, users } = this.props;
        // console.log('qwer otherTeams', otherTeams);
        if (otherTeams.teams) {
            let filteredOtherTeams = otherTeams.teams;
            delete filteredOtherTeams[this.getRoomId()];
            // console.log('qwer filteredOtherTeams', filteredOtherTeams)
            Object.entries(filteredOtherTeams).map(async ([key, value], i) => {
                const captainId = value.captainId;
                const filteredUsers = users.filter(user => user.id === captainId);
                if (filteredUsers.length < 1) {
                    // console.log('qwer captain get', captainId)
                    const captainUsers = await fetchDocuments(
                        `${eventId}/${RealTimeKeys.USERS}`, 
                        captainId
                    );
                    // console.log('qwer user', captainUsers)
                    if (captainUsers && captainUsers.length === 1) {
                        addOtherUser(captainUsers[0]);
                    };
                };
            });
            // console.log('qwer', newUsers, newUsers);
            this.props.setOtherTeams(filteredOtherTeams);
        };
        // console.log('qwer FF');
        this.setState({ awaitingServer: false });
    };

    updateTeam = team => {
        // console.log('qwer TEAM', team);
        this.props.setAllCertifiedTeammateIds(team.teammateIds);
        // this.props.setTeamCertified(team.teamCertified);
        this.props.setCaptainVoteIds(team.captainVoteIds);
        this.props.setCaptainId(team.captainId);
        this.props.setTeamName(team.name);
        this.props.setTeamState(team.state);
        // console.log('qwer FF');
        this.setState({ awaitingServer: false });
        switch (team.state) {
            case TeamStates.ELECTING_CAPTAIN:
            case TeamStates.CHOOSING_NAME:
            case TeamStates.COMPLETE:
                this.submitCertification(true);
                break;
            default: break;
        };
    };
    
    render() {
        this.submitting = false;
        const { awaitingServer } = this.state;
        const { clientTimeDifference, numBreakoutRoomUsers, sceneId, self } = this.props;
        // console.log('qwer REND', sceneId );
        return (<ContentContainer>
            <Row>
                <Column widthPercent={50}>
                    <SceneTimer
                        clientTimeDifference={clientTimeDifference}
                        countFromMinute={3}
                        sceneId={sceneId}
                        useSceneTime={false}
                        handleComplete={this.handleSceneTimerComplete}
                        handleWarning={this.handleSceneTimerWarning}
                        warningMinutes={[7,6,5,4]}
                    />
                    <TrafficLightList 
                        awaitingServer={awaitingServer}
                        handleCaptainSubmit={this.handleCaptainSubmit} 
                        handleCertificationSubmit={this.handleCertificationSubmit}
                        handleCertificationReject={this.handleCertificationReject}
                        handleTeamNameChange={this.handleChange}
                        handleTeamNameSubmit={this.handleTeamNameSubmit}
                        numBreakoutRoomUsers={numBreakoutRoomUsers}
                        sceneId={sceneId}
                        selfId={self.id}
                        formTeamName={this.state.formTeamName}
                    />
                </Column>
                <Column widthPercent={50}>
                    <OtherTeamsList />
                </Column>
            </Row>
        </ContentContainer>);
    };
}

const mapStateToProps = createStructuredSelector({
    breakoutRoomUserIds: selectBreakoutRoomUserIds,
    minPlayersPerTeam: selectMinPlayersPerTeam,
    numBreakoutRoomUsers: selectNumBreakoutRoomUsers,
    numCertifiedTeammates: selectNumCertifiedTeammates,
    pageName: selectPageName,
    teamState: selectTeamState,
    users: selectUsers,
});

const mapDispatchToProps = dispatch => ({
    addHotspotDetails: value => dispatch(addHotspotDetails(value)),
    addItem: value => dispatch(addItem(value)),
    addOtherUser: value => dispatch(addOtherUser(value)),
    addSolutionDependencies: value => dispatch(addSolutionDependencies(value)),
    setCaptainId: value => dispatch(setCaptainId(value)),
    setCaptainVoteIds: value => dispatch(setCaptainVoteIds(value)),
    setAllCertifiedTeammateIds: value => dispatch(setAllCertifiedTeammateIds(value)),
    setTeamState: value => dispatch(setTeamState(value)),
    setOtherTeams: value => dispatch(setOtherTeams(value)),
    setTeamName: value => dispatch(setTeamName(value)),
});

export default withIntroUtils(
    connect(mapStateToProps, mapDispatchToProps)(LobbyPage)
);
