import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import { Metrics } from "../app.types";

import { HeaderAndFooterContainer } from "../global.styles";

export const FooterContainer = styled.div`
    ${HeaderAndFooterContainer}
    bottom: 0px;
`;

export const HeaderContainer = styled.div`
    ${HeaderAndFooterContainer}
    top: 0px;
`;

export const InteractionsContainer = styled.div`
    position: fixed;
    bottom: 3.5em;
    right: 0.25em;
    width: 17em;
    z-index: 300;

    @media screen and (min-width: ${Metrics.COLUMN_CHANGING_THRESHOLD}px) {
        bottom: ${props => isMobileOnly ? "0.25em" : "3.5em"};
    }
`;

export const InteractionsDialogContainer = styled.div`
    width: ${() => interactionsDialogWidth()};
    padding: 0.5em;
    border-radius: 1em;
    background-color: rgba(0, 0, 0, 0.75);
    text-align: center;
`;

export const InteractionDialogContainer = styled.div`
    width: ${() => interactionsDialogWidth()};
    text-align: center;
    font-style: italic;
    color: wheat;
`;

export const ModalBackgroundContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 400;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const ModalForegroundContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${() => `${modalSize()}px`};
    height: ${() => `${modalSize()}px`};
    margin-left: ${() => `-${modalSize() / 2}px`};
    margin-top: ${() => `-${modalSize() / 2}px`};
    z-index: 500;
    background: white;
    border-radius: 12px;
    text-align: center;
    pointer-events: auto;
`;

export const ThumbnailImage = styled.img`
    vertical-align: middle;
    width: 48px;
    height: 48px;
    padding: 4px;
    padding-right: 8px;
`;

// ------------

const interactionsDialogWidth = () => "16em";
const modalSize = () => 256;
