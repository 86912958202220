import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { BoxLeft, BoxRight, SpacedContainer, FormButton } from "../global.styles";
import { FooterContainer } from "./components.styles";

import { InteractionTypes, RealTimeKeys } from "../firebase/realtime.types";
import { storeInteraction } from "../firebase/realtime.utils";

import { selectDebug } from "../redux/admin/admin.selectors";
import { selectEventId } from "../redux/event/event.selectors";
import { selectPageName, selectSceneId } from "../redux/game/game.selectors";
import { selectSelfBreakoutRoomNumber, selectSelfId } from "../redux/users/users.selectors";

import { setModalMessages } from "../redux/admin/admin.actions";

import { PageTypes } from "../pages/page.types";

class Footer extends React.Component {
    goToAdmin = () => {
        window.location = "/admin";
    };

    goToHome = () => {
        window.location = "/";
    };
    
    handleHelp = () => {
        const { breakoutRoomNumber, eventId, pageName, sceneId, selfId, setModalMessages } = this.props;
        // addFirestoreData(CollectionNames.HELP, { eventId, selfId });
        storeInteraction(
            selfId, 
            pageName, 
            InteractionTypes.HELP, 
            `${eventId}/${RealTimeKeys.BREAKOUT_ROOM}${breakoutRoomNumber}`,
            sceneId
        );
        setModalMessages({ 
            header: "Help is on its way!", 
            message:  "Rodger that over"
        });
    };
    
    render() {
        const { debug } = this.props;
        let buttonLabel = debug ? "admin" : null;
        if (!buttonLabel) {
            switch (this.props.pageName) {
                case PageTypes.GAME:
                case PageTypes.INTRO:
                case PageTypes.ITEM:
                case PageTypes.INVENTORY_ITEMS:
                case PageTypes.LOBBY:
                case PageTypes.LOUNGE:
                    buttonLabel = "help";
                    break;
                default: break;
            };    
        };
        const helpButtonWidth = buttonLabel ? 25 : 0;
        return (<FooterContainer>
            <SpacedContainer>
                <BoxLeft widthPercent={75 - helpButtonWidth}>
                    <span>(0.13.1)</span>
                </BoxLeft>
                {buttonLabel ? 
                    <BoxRight widthPercent={helpButtonWidth}>
                        <FormButton 
                            type="button" 
                            value={buttonLabel}
                            onClick={buttonLabel === "help" ? this.handleHelp : this.goToAdmin} 
                        />
                    </BoxRight>
                    : null
                }
                <BoxRight widthPercent={25}>
                    <FormButton type="button" value="home" onClick={this.goToHome} />
                </BoxRight>
            </SpacedContainer>
        </FooterContainer>);
    }
};

const mapStateToProps = createStructuredSelector({
    breakoutRoomNumber: selectSelfBreakoutRoomNumber,
    debug: selectDebug,
    eventId: selectEventId,
    pageName: selectPageName,
    sceneId: selectSceneId,
    selfId: selectSelfId,
});

const mapDispatchToProps = dispatch => ({
    setModalMessages: value => dispatch(setModalMessages(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
