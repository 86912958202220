import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import ConfirmEmailForm from "../components/confirm-email-form.component";
import withUserUtils from "./with-user-utils";

import { sendEmail } from "../firebase/firestore.utils";

import { selectDebug } from "../redux/admin/admin.selectors";
import { selectClient, selectPassCode } from "../redux/event/event.selectors";
import { selectProductName } from "../redux/product/product.selectors";
import { selectEmail, selectSelfId } from "../redux/users/users.selectors";

import { PageTypes } from "./page.types";


class EmailConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.submitting = false;
    };

    async componentDidMount() {
        this.props.setPageName(PageTypes.EMAIL_CONFIRMATION);
    };

    goToPage = page => {
        const { eventPassCode, history } = this.props;
        const location = `/${page}/${eventPassCode}`;
        history.push(location);
    };

    handleConfirmEmailCancel = e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        this.goToPage(PageTypes.EMAIL);
    };

    handleConfirmEmailSubmit = e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        const { client, email, productName, eventPassCode, selfId} = this.props;
        sendEmail(email, productName, client, eventPassCode, selfId);
        this.goToPage(PageTypes.NAME);
    };
    
    render() {
        this.submitting = false;
        const { client, productName } = this.props;
        return (<div>
            <p><span>{productName}</span> with <span>{client}</span></p>
            <ConfirmEmailForm
                emailAddress={this.props.email}
                handleCancel={this.handleConfirmEmailCancel}
                handleSubmit={this.handleConfirmEmailSubmit} 
            />
        </div>);
    };
};

const mapStateToProps = createStructuredSelector({
    client: selectClient,
    debug: selectDebug,
    email: selectEmail,
    eventPassCode: selectPassCode,
    productName: selectProductName,
    selfId: selectSelfId,
});

// const mapDispatchToProps = dispatch => ({
// });

export default withUserUtils(
    connect(mapStateToProps)(EmailConfirmation)
);
