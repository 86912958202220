import React from "react";
import { connect } from "react-redux";

import { Column, ContentContainer, FormButton, Row } from "../global.styles";
import withUserUtils from "./with-user-utils";  

import { resetApp } from "../redux/root/root.actions";

import { PageTypes } from "./page.types";

class HomePage extends React.Component {
    componentDidMount() {
        this.props.resetApp();
        this.props.setPageName(PageTypes.HOME);
    };

    goToHome = e => {
        e.preventDefault();
        const { history } = this.props;
        const location = "/product/888";
        history.push(location);
    };

    render() {
        return (<ContentContainer>
            <Row>
                <Column widthPercent={50}>
                    <p>Where do you want to go today?</p>
                </Column>
                <Column widthPercent={50}>
                    <form onSubmit={this.goToHome}>
                        <FormButton type="submit" value="restart" />
                    </form>
                </Column>
            </Row>
        </ContentContainer>);
    };
};

const mapDispatchToProps = dispatch => ({
    resetApp: () => dispatch(resetApp()),
});

export default withUserUtils(
    connect(null, mapDispatchToProps)(HomePage)
);
