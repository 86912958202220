// export const AdminLoginPageTypes = {
//     LOGGED_IN: "LOGGED_IN",
//     LOGGED_OUT: "LOGGED_OUT",
// };

export const HintState = {
    IRRELEVANT: "ir",
    NONE: "nn",
    MINOR: "mn",
    MAJOR: "mj",
};

export const PageTypes = {
    // CHOOSE_EVENT: "choose-event",
    ADMIN: "admin",
    EMAIL: "email",
    EMAIL_CONFIRMATION: "emailConfirmation",
    ERROR: "404",
    GAME: "game",
    HOME: "home",
    INTRO: "intro",
    ITEM: "item",
    INVENTORY_ITEMS: "inventory-items",
    LOBBY: "lobby",
    LOUNGE: "lounge",
    MONITOR_EVENT: "monitor-event",
    NAME: "name",
    PRODUCT: "product",
    PRODUCT_CODE: "productCode",
    REJOIN: "rejoin",
    SCENE: "scene",
    UPDATE_EVENT: "update-event",
};

export const ProductPageStages = {
    EVENT_COMPLETE: "EVENT_COMPLETE",
    EVENT_FETCHED: "EVENT_FETCHED",
    EVENT_YET_TO_START: "EVENT_YET_TO_START",
    NEW_USER_ON_SITE: "NEW_USER_ON_SITE",
    PRODUCT_LOADED: "PRODUCT_LOADED",
    NO_SUCH_PRODUCT: "NO_SUCH_PRODUCT",
};

export const UpdateEventTypes = {
    SCENE_START_DATES: "sceneStartDates",
    SCENE_START_HOURS: "sceneStartHours",
    SCENE_START_MINUTES: "sceneStartMinutes",
    START_DATE: "startDate",
    START_HOUR: "startHour",
    START_MINUTE: "startMinute",
};
