import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import withUserUtils from "./with-user-utils";

import { CollectionNames, FirestoreProps } from "../firebase/firestore.types";
import { fetchDatumByDocName } from "../firebase/firestore.utils";

import { selectEventId } from "../redux/event/event.selectors";
import { selectSceneId } from "../redux/game/game.selectors";
import { selectSelf } from "../redux/users/users.selectors";

import { addMedium } from "../redux/product/product.actions";
import { addInteractions } from "../redux/interactions/interactions.actions";
import { setOwnUser } from "../redux/users/users.actions";

import { MediaTypes } from "../components/item/item-io.types";

const withIntroUtils = WrappedComponent => {
    class WithIntroUtils extends React.Component {
        assessMediaIdCollection = inventoryItemCollection => {
            // console.log('qwer assessMediaIdCollection');
            if (!inventoryItemCollection) {
                return;
            }
            const { addMedium } = this.props;
            for (const mediumId of inventoryItemCollection) {
                if (this.props.allIds.indexOf(mediumId) < 0) {
                    fetchDatumByDocName(
                        CollectionNames.MEDIA, 
                        mediumId,
                        medium => {
                            // console.log('qwer inventoryItem medium', medium);
                            addMedium({ 
                                ...medium,
                                url: null,
                            });
                            const mediumType = medium[FirestoreProps.TYPE];
                            if (
                                mediumType === MediaTypes.IMAGE
                                || mediumType === MediaTypes.VIDEO
                            ) {
                                this.props.fetchStorageUrl(medium[FirestoreProps.VALUE]);
                            };
                        }
                    );
                };
            };
        };    

        render() {
            return <WrappedComponent 
                assessMediaIdCollection={this.assessMediaIdCollection}
                {...this.props} 
            />
        };
    };
    
    const mapStateToProps = createStructuredSelector({
        eventId: selectEventId,
        sceneId: selectSceneId,
        self: selectSelf,
    });

    const mapDispatchToProps = dispatch => ({
        addInteractions: value => dispatch(addInteractions(value)),
        addMedium: value => dispatch(addMedium(value)),
        setOwnUser: value => dispatch(setOwnUser(value)),
    });
    
    return withUserUtils(
        connect(mapStateToProps, mapDispatchToProps)(WithIntroUtils)
    );
};

export default withIntroUtils;