import EventActionTypes from "./event.types";

export const addEvents = value => ({
    type: EventActionTypes.ADD_EVENTS,
    payload: value
});

export const setEvent = value => ({
    type: EventActionTypes.SET_EVENT,
    payload: value
});

export const setEventId = value => ({
    type: EventActionTypes.SET_EVENT_ID,
    payload: value
});
