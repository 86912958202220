import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { 
    InteractionsContainer, 
    InteractionsDialogContainer, 
    InteractionDialogContainer 
} from "./components.styles";

import { InteractionKeys, InteractionTypes, UserKeys } from "../firebase/realtime.types";

import { selectAdminId } from "../redux/admin/admin.selectors";
import { selectClientTimeDifference } from "../redux/game/game.selectors";
import { selectHotspotDetailsBySceneId } from "../redux/product/product.selectors";
import { selectInteractionsByKeyValue } from "../redux/interactions/interactions.selectors";
import { selectSelfId, selectUsers } from "../redux/users/users.selectors";

// import { getInteractionText } from "./components.utils";

import { Metrics } from "../app.types";

import { getDateFromClientTimeDifference } from "../app.utils";
import { selectDebug } from "../redux/admin/admin.selectors";
import { FirestoreProps, InternalProps } from "../firebase/firestore.types";

const InteractionsDialog = ({ 
    adminId, 
    clientTimeDifference, 
    challenges, 
    debug,
    interactions, 
    selfId, 
    users 
}) => {
    let isMounted = useRef(null);
    let smallestSecondsDifference = Number.MAX_SAFE_INTEGER;
    const millisPerSecond = Metrics.MILLIS_PER_SECOND;
    const now = getDateFromClientTimeDifference(clientTimeDifference);
    const filteredInteractions = interactions ? 
        interactions.filter(interaction => {
            // console.log('qwer ', new Date(interaction.ts));
            const secondsDifference = (now - interaction[InteractionKeys.TIMESTAMP]) / millisPerSecond;
            // console.log('qwer secondsDifference', secondsDifference, interaction);
            if (secondsDifference < Metrics.MAX_INTERACTION_SECONDS) {
                if (secondsDifference < smallestSecondsDifference) {
                    smallestSecondsDifference = secondsDifference;
                };
                
                return debug || interaction[InteractionKeys.USER_ID] !== selfId ? 
                    interaction
                    : null;
            } else {
                return null;
            }
        })
        : null;
    // herehere cancel ticking based upon not having viewable interactions (and set tick time to smallestSecondsDifference)
    // const hasViewableInteractions = smallestSecondsDifference < Number.MAX_SAFE_INTEGER;
    // console.log('qwer hasViewableInteractions', hasViewableInteractions, smallestSecondsDifference);
    // if (interactions) { console.log('qwer filts',interactions, filteredInteractions); }

    const tick = e => {
        if (isMounted.current) {
            setTimer(prev => {
                const now = new Date().getTime();
                // console.log('qwer tick', now - prev.now);
                return { ...prev, now };    
            });
        };
    };

    const [timer, setTimer] = useState(
        { 
            now: new Date().getTime(), 
            handler: null 
        }
    );
    
    // const newNow = new Date().getTime();
    // console.log('qwer new now', newNow - timer.now);

    if (!timer.handler) {
        // console.log('qwer start');
        const handler = setInterval(tick, 3000);
        setTimer({ ...timer, handler });
    };

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
            if (timer.handler) {
                clearInterval(timer.handler);
            };
        };
    }, [timer.handler, isMounted]);

    const getInteractionText = (interaction, selfId, adminId, users, challenges) => {
        if (!interaction) {
            return null;
        };
        const type = interaction[InteractionKeys.TYPE];
        switch (type) {
            case InteractionTypes.INVENTORY_ITEM_MISUSED:
                return null;
            case InteractionTypes.TIMER_WARNING:
                return `${interaction[InteractionKeys.OTHER_VALUES][InternalProps.TIMER_MINUTES]} minutes to go...`;
            default: break;
        };
        const interactionUserId = interaction[InteractionKeys.USER_ID];
        const isSelf = interactionUserId === selfId;
        let phrase = null;
        const areIs = isSelf ? "are" : "is";
        const haveHas = isSelf ? "have" : "has";
        const filteredUsers = users.filter(user => user[FirestoreProps.ID] === interactionUserId);
        let userName = "";
        switch (true) {
            case adminId !== null:              userName = "An administrator";                  break;
            case filteredUsers.length === 1:    userName = filteredUsers[0][UserKeys.NAME];     break;
            default:                            userName = "Someone";                           break;
        };
        const userRef = isSelf ? `${userName} (you)` : userName;
        // console.log('qwer', users)
        
        switch (type) {
            case InteractionTypes.INACTIVE:
                return `${userRef} ${haveHas} become a bit quiet...`;
            case InteractionTypes.ENTRY:
                phrase = `entered the ${interaction[InteractionKeys.PAGE]}`;
                break;
            // case InteractionTypes.EXIT:
            //     phrase = `left the ${interaction[InteractionKeys.PAGE]}`;
            //     break;
            case InteractionTypes.LEFT_GAME:
                phrase = "left the event";
                break;
            case InteractionTypes.EVENT_UPDATED:
                phrase = "updated an event";
                break;
            case InteractionTypes.INFO_FOUND:
                phrase = "discovered some info";
                break;
            case InteractionTypes.INVENTORY_ITEM_USED:
                phrase = "used an inventory item";
                break;
            case InteractionTypes.INVENTORY_ITEM_MISUSED:
                phrase = "used an inventory item";
                break;
            case InteractionTypes.HELP:
                phrase = "requested help";
                break;
            case InteractionTypes.MINOR_HINT:
            case InteractionTypes.MAJOR_HINT:
                const isMinor = type === InteractionTypes.MINOR_HINT;
                phrase = `requested a ${isMinor ? "minor" : "major"} hint`;
                break;
            case InteractionTypes.CLICKED_OBJECT:
            case InteractionTypes.CHALLENGE_INCORRECT:
            case InteractionTypes.USER_CHALLENGE_CORRECT:
            case InteractionTypes.TEAM_CHALLENGE_CORRECT:
                // console.log('qwer', interaction);
                const itemRef = interaction[InteractionKeys.OTHER_VALUES][FirestoreProps.DOCUMENT_ID];
                const filteredChallenges = challenges ? 
                    challenges.filter(challenge => challenge.id === itemRef)
                    : null;
                const challenge = filteredChallenges && filteredChallenges.length === 1 ?
                    filteredChallenges[0]
                    : null;
                // console.log('qwer', itemRef, challenge, challenges);
                if (type === InteractionTypes.CLICKED_OBJECT) {
                    phrase = challenge !== null ? 
                        `${areIs} looking at the ${challenge[FirestoreProps.DESCRIPTION]}`
                        : `${areIs} looking at something...`;
                } else {
                    const hasFailed = type === InteractionTypes.CHALLENGE_INCORRECT;
                    // console.log('qwer', challenges, filteredChallenges);
                    const verb = hasFailed ? "failed" : "cracked";
                    const item = challenge ?
                        `the ${challenge[FirestoreProps.DESCRIPTION]} challenge!` :     
                        "an unknown challenge";
                    phrase = `${verb} ${item}`;
                };
                break;
            default:
                phrase = ` unhandled action: ${type}`;
                break;
        };
        return `${userRef} ${phrase}`;
    };    
    
    return (<>
        {filteredInteractions && filteredInteractions.length > 0 ?
            <InteractionsContainer>
                <InteractionsDialogContainer>
                    {filteredInteractions.map(interaction => {
                        // console.log('qwer', interaction)
                        return <InteractionDialogContainer key={interaction.id}>
                            <span>
                                {getInteractionText(
                                    interaction, 
                                    selfId,
                                    adminId,
                                    users,
                                    challenges
                                )}
                            </span>
                        </InteractionDialogContainer>
                        })
                    }
                </InteractionsDialogContainer>
            </InteractionsContainer>
            : null
        }
    </>);
};

const mapStateToProps = (state, ownProps) => ({
    adminId: selectAdminId(state),
    clientTimeDifference: selectClientTimeDifference(state),
    challenges: selectHotspotDetailsBySceneId(ownProps.sceneId)(state),
    debug: selectDebug(state),
    interactions: selectInteractionsByKeyValue(ownProps.filters)(state),
    users: selectUsers(state),
    selfId: selectSelfId(state),
});

export default connect(mapStateToProps)(InteractionsDialog);
