import React from "react";
import { connect } from "react-redux";

import { TableCell, TableContainer } from "../global.styles";

import { FirestoreProps } from "../firebase/firestore.types";
import { UserKeys } from "../firebase/realtime.types";

import { selectUserIdsByBreakoutRoomNumber, selectUsers } from "../redux/users/users.selectors";

const UsersList = ({ userIds, users }) => {

    return (<>
        {userIds && userIds.length ?
            <>
                <p>USERS</p>
                <TableContainer>
                    <TableCell widthPercent={50}>NAME</TableCell>
                    <TableCell widthPercent={50}>ACTIVE</TableCell>
                </TableContainer>
                {userIds.map(userId => {
                    const filteredUsers = users.filter(user => user[FirestoreProps.ID] === userId);
                    const user = filteredUsers.length === 1 ? filteredUsers[0] : null;
                    return user ?
                        <TableContainer>
                            <TableCell widthPercent={50}>{user[UserKeys.NAME]}</TableCell>
                            <TableCell widthPercent={50}>{user[UserKeys.ACTIVE] ? "yes" : "no"}</TableCell>
                        </TableContainer>
                        : null
                })}
            </>
            : null
        }
    </>);
};

const mapStateToProps = (state, ownProps) => ({
    userIds: selectUserIdsByBreakoutRoomNumber(ownProps.breakoutRoomNumber)(state),
    users: selectUsers(state),
});

export default connect(mapStateToProps)(UsersList);
