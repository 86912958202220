import { getNow } from "../firebase/firestore.utils";
import { Metrics } from "../app.types";

const getDate = seconds => new Date(seconds * Metrics.MILLIS_PER_SECOND);

export const getDateIsToday = (clientTimeDifference, seconds) => {
    const startDate = getDate(seconds);
    const nowDate = getDate(getNow() + clientTimeDifference);
    // console.log('qwer', startDate, getNow(), clientTimeDifference);
    return startDate.getDay() === nowDate.getDay()
        && startDate.getMonth() === nowDate.getMonth()
        && startDate.getFullYear() === nowDate.getFullYear()
};

export const getEventHasStarted = (clientTimeDifference, seconds) => {
    return getNow() + clientTimeDifference - seconds > 0;
};

// export const getDeviceType = () => {
//     switch (true) {
//         case isMobileOnly && isAndroid:         return DeviceTypes.ANDROID_PHONE;
//         case isTablet && isAndroid:             return DeviceTypes.ANDROID_TABLET;
//         case isMobileOnly && isIOS:             return DeviceTypes.IPHONE;
//         case isTablet && isIOS:                 return DeviceTypes.IPAD;
//         default:                                return DeviceTypes.DESKTOP;
//     }
// };

export const getMinutesSinceStart = (clientTimeDifference, seconds) => {
    return Math.floor((getNow() + clientTimeDifference - seconds) / 60);
};
