import React from "react";

import { FormSelectSmall } from "../global.styles";
import DatePicker from "react-date-picker";

import { UpdateEventTypes } from "../pages/page.types";
import { Generics, Metrics } from "../app.types";


const DateFormSection = ({ index, startDate, startHour, startMinute, isStartDate, handleChange }) => {
    // console.log('qwer', index, startHour, startMinutes)
    const splitter = Generics.SPLITTER;
    const handleDateChange = value => {
        const propName = isStartDate ? 
            UpdateEventTypes.START_DATE 
            : UpdateEventTypes.SCENE_START_DATES;
        handleChange({ 
            target: { 
                name: `${propName}${splitter}${index}`, 
                value 
            } 
        })
    };
    const maxMinutes = Metrics.MAX_MINUTES_OR_SECONDS;
    const maxHours = Metrics.MAX_HOURS;
    const minuteIntervals = 5;
    const numMinuteIntervals = maxMinutes / minuteIntervals;
    let hourSlots = [];
    let minuteSlots = [];
    let i;
    for (i=0; i<maxHours; i++) {
        hourSlots.push(i);
        if (i < numMinuteIntervals) {
            minuteSlots.push(i * minuteIntervals);
        };
    };

    return (<>
        <DatePicker
            onChange={handleDateChange}
            value={startDate > 0 ? startDate : null}
            required={isStartDate}
        />
        <span> </span>
        <FormSelectSmall
            name={`${UpdateEventTypes.START_HOURS}${splitter}${index}`} 
            onChange={handleChange}
            value={startHour ? startHour : ""}
            required={isStartDate}
        >
            {hourSlots.map(hour => {
                return <option 
                    key={hour}
                    value={hour}
                >
                    {String(hour).padStart(2, "0")}
                </option>
            })}
        </FormSelectSmall>
        :
        <FormSelectSmall
            name={`${UpdateEventTypes.START_MINUTES}${splitter}${index}`} 
            onChange={handleChange}
            value={startMinute ? startMinute : ""}
            required={isStartDate}
        >
            {minuteSlots.map(minute => {
                return <option 
                    key={minute}
                    value={minute}
                >
                    {String(minute).padStart(2, "0")}
                </option>
            })}
        </FormSelectSmall>
    </>);
};

export default DateFormSection;
