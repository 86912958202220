const InternalChallengeOptionsSets = {
    ALPHA: ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z",],
    NUMERIC: ["0","1","2","3","4","5","6","7","8","9"],
};

// ------------

export const ChallengeOptionsSets = {
    ALPHA: Object.assign([], InternalChallengeOptionsSets.ALPHA),
    NUMERIC: Object.assign([], InternalChallengeOptionsSets.NUMERIC),
    ALPHANUMERIC: Object.assign([], InternalChallengeOptionsSets.ALPHA).concat(Object.assign([], InternalChallengeOptionsSets.NUMERIC)),
    EMPTY: [],
};

export const ChallengeOptionsSetTypes = {
    ALPHA: "alpha",
    NUMERIC: "numeric",
    ALPHANUMERIC: "alphanumeric",
};

export const ItemInputTypes = {
    RADIO: "radio",
    SELECT: "select",
    TEXTFIELD: "textfield",
};

export const MediaTypes = {
    AUDIO: "audio",
    IMAGE: "image",
    SIRV: "sirv",
    TEXT: "text",
    VIDEO: "video",
};

export const ResponseElements = {
    CONNECTOR: ":",
    GROUP: "group",
    INPUT: "input",
    SEPARATOR: ",",
};
