import InteractionsActionTypes from "./interactions.types";

export const addInteraction = value => ({
    type: InteractionsActionTypes.ADD_INTERACTION,
    payload: value
});

export const addInteractions = value => ({
    type: InteractionsActionTypes.ADD_INTERACTIONS,
    payload: value
});