import styled from "styled-components";

import { SizableBox } from "../../global.styles";

export const ItemColumn = styled.div`
    ${SizableBox}
    position: relative;
    float: left;
    padding: 4px;
    width: ${props => props.widthPercent}%;
    text-align: center;
`;

export const ItemColumnTitle = styled.div`
    position: absolute;
    margin-bottom: 8px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    color: wheat;
    z-index: 300;
`;

export const ItemImage = styled.img`
    width: 100%;
`;

export const SirvContainer = styled.div`
    height: 200px;
`;
