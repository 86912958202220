import React from "react";
import { connect } from "react-redux";

import { HeaderContainer } from "./components.styles";

import { selectTeamName } from "../redux/team/team.selectors";

const Header = ({ teamName, title }) => {
    
    return (<HeaderContainer>
        {teamName ? 
            <span>{teamName}</span>
            :
            <span>{`${title ? title : 'unknown'}`}</span>
        }
        {/* {handleLogOut ?
            <form onSubmit={handleLogOut}>
                <FormButton type="submit" value="Leave" />
            </form>
            : null
        } */}
    </HeaderContainer>);
};

const mapStateToProps = (state, ownProps) => ({
    teamName: selectTeamName(state),
    title: ownProps.title,
    // handleLogOut: ownProps.handleLogOut,
});

export default connect(mapStateToProps)(Header);
