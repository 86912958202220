import AdminActionTypes from "./admin.types";

export const setAdminBreakoutRoomNumber = value => ({
    type: AdminActionTypes.SET_BREAKOUT_ROOM_NUMBER,
    payload: value
});

export const setAdminId = value => ({
    type: AdminActionTypes.SET_ADMIN_ID,
    payload: value
});

export const setDebug = value => ({
    type: AdminActionTypes.SET_DEBUG,
    payload: value
});

export const setModalMessages = value => ({
    type: AdminActionTypes.SET_MODAL_MESSAGES,
    payload: value
});

export const setServerResponseState = value => ({
    type: AdminActionTypes.SET_SERVER_WAIT,
    payload: value
});
