import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { TableCell, TableContainer } from "../global.styles"

import { selectBreakoutRoomUsers, selectSelf } from "../redux/users/users.selectors";
import { selectCaptainId } from "../redux/team/team.selectors";

import { UserKeys } from "../firebase/realtime.types";

const BreakoutRoomUsers = ({ breakoutRoomUsers, captainId, self }) => {
    const getTitle = userId => userId === captainId ? "Capt. " : "";
    const selfId = self.id;
    return breakoutRoomUsers ? (
        <TableContainer>
            <TableCell widthPercent={100}>Your team:</TableCell>
            <hr />
            <TableCell widthPercent={100}>
                {getTitle(selfId)}{self[UserKeys.NAME]} (you)
            </TableCell>
                {breakoutRoomUsers.map(user => {
                    return user.id !== selfId ? 
                    <TableCell key={user.id} widthPercent={100}>
                        {getTitle(user.id)}{user[UserKeys.NAME]}
                    </TableCell>
                    : null 
                })}
        </TableContainer>
    ) : null;
};

const mapStateToProps = createStructuredSelector({
    breakoutRoomUsers: selectBreakoutRoomUsers,
    captainId: selectCaptainId,
    self: selectSelf,
});

export default connect(mapStateToProps)(BreakoutRoomUsers);
