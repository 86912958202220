import { TeamStates } from "../../firebase/firestore.types";
import LobbyActionTypes from "./lobby.types";

const INITIAL_STATE = {
    captainVoteIds: {},
    allCertifiedTeammateIds: null,
    teamState: TeamStates.BUILDING_TEAM,
};

const lobbyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LobbyActionTypes.SET_ALL_CERTIFIED_TEAMMATE_IDS:
            return { ...state, allCertifiedTeammateIds: action.payload };
        case LobbyActionTypes.SET_CAPTAIN_VOTE_IDS:
            return { ...state, captainVoteIds: action.payload };
        case LobbyActionTypes.SET_TEAM_STATE:
            const teamState = action.payload;
            return teamState ? { ...state, teamState } : state;
        default:
            return state;
    };
};

export default lobbyReducer;