import LobbyActionTypes from "./lobby.types";

export const setAllCertifiedTeammateIds = value => ({
    type: LobbyActionTypes.SET_ALL_CERTIFIED_TEAMMATE_IDS,
    payload: value
});

export const setCaptainVoteIds = value => ({
    type: LobbyActionTypes.SET_CAPTAIN_VOTE_IDS,
    payload: value
});

export const setTeamState = value => ({
    type: LobbyActionTypes.SET_TEAM_STATE,
    payload: value
});
