import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

import { v4 as UUID } from "uuid";  

import { CollectionNames, FirestoreProps } from "./firestore.types";
import { Metrics } from "../app.types";

firebase.initializeApp({
    apiKey: "AIzaSyDX1jaQi8d3Jo7773QzRUVTQSvMYiGLjCs",
    authDomain: "escape-works-5dc75.firebaseapp.com",
    databaseURL: "https://escape-works-5dc75.firebaseio.com",
    projectId: "escape-works-5dc75",
    storageBucket: "escape-works-5dc75.appspot.com",
    messagingSenderId: "986638427821",
    appId: "1:986638427821:web:11666f716db199f1d4c824",
    measurementId: "G-GKYYGE7PFH"
});

export const storageUrl = "gs://escape-works-5dc75.appspot.com/";

export const auth = firebase.auth();

export const firestore = firebase.firestore();

export const realTime = firebase.database();

export const storage = firebase.storage();





export const addFirestoreData = async (collectionName, data, docName) => {
    let success = false;
    if (docName) {
        return await updateFirestoreDatum(collectionName, data, docName);
    };
    await firestore.collection(collectionName)
        .add(data)
        .then(function() {
            success = true;
        })
        .catch(function(error) {
            console.error('qwer', error);
        });
    return success;
};

export const deleteFirestoreData = async (collectionName, docName) => {
    let success = false;
    await firestore.collection(collectionName)
        .doc(docName)
        .delete()
        .then(function() {
            success = true;
        })
        .catch(function(error) {
            console.error('qwer', error);
        });
    return success;
};

export const fetchClientTimeDifference = async () => {
    let result = null;
    const timeBefore = getNow();
    const timestampsRef = firestore.collection(CollectionNames.TIMESTAMPS).doc(UUID());
    await timestampsRef
        .set({ serverTime: firebase.firestore.FieldValue.serverTimestamp() })
        .catch(error => {
            console.log('qwer', error);
            return 0;
        });
    await timestampsRef
        .get()
        .then(value => result = value.data())
        .catch(error => {
            console.log('qwer', error);
            return 0;
        });
    if (!result) {
        return 0;
    }
    const clientSeconds = Math.round(((Date.now() / Metrics.MILLIS_PER_SECOND) + timeBefore) / 2);
    const difference = clientSeconds - result.serverTime.seconds;
    return difference > -10 && difference < 10 ? 0 : difference;
};

// export const fetchCollection = async collectionName => {
//     let result = null;
//     if (!collectionName) {
//         return result;
//     };
//     await firestore.collection(collectionName)
//         .get()
//         .then(function(snap) {
//             result = [];
//             snap.forEach(function(doc) {
//                 result.push(formatWithId(doc.id, doc.data()));
//             })
//             .catch(function(error) {
//                 console.log('qwer', error);
//                 return result;
//             });
//         });
//     return result;
// };

export const fetchDatumByDocName = async (collectionName, docName, callback) => {
    let result = null;
    if (!collectionName) {
        return result;
    };
    // console.log('qwer fetchDatumByDocName', collectionName, docName);
    try {
        await firestore.collection(collectionName).doc(docName).get()
            .then(function(doc) {
                if (doc.exists) {
                    result = formatWithId(docName, doc.data());
                };
                // console.log('qwer', doc.exists, result);
                if (callback) {
                    callback(result);
                } 
            })
            .catch(function(error) {
                console.log('qwer', error);
                return result;
            });
        return result;
    } catch (error) {
        console.log('qwer', error);
        return result;
    };
};

export const fetchDataByFieldPairs = async (collectionName, fieldPairs, callback) => {
    let result = null;
    // console.log('qwer collectionName', collectionName);
    if (!collectionName) {
        return result;
    };
    // console.log('qwer fieldPairs', fieldPairs);
    try {
        let tempRef = await firestore.collection(collectionName)
        if (fieldPairs) {
            let i = 0;
            while (i < fieldPairs.length) {
                const pair = fieldPairs[i++];
                if (tempRef) {
                    const key = pair.key;
                    const value = pair.value;
                    if (key && value) {
                        tempRef = await tempRef.where(key, "==", value)
                    };
                    // console.log('qwer K+V', key, value, tempRef);
                };
            };
        };
        if (!tempRef) {
            return null;
        };
        // console.log('qwer tempRef', tempRef);
        await tempRef.get()
            .then(function(snap) {
                // console.log('qwer snap', snap.docs.length);
                result = [];
                snap.docs.forEach(function(doc) {
                    // console.log('qwer doc', doc);
                    result.push(formatWithId(doc.id, doc.data()));
                });
                if (callback) {
                    callback(result.length > 0 ? result : null);
                } 
            })
            .catch(function(error) {
                console.log('qwer', error);
                return result;
            });
            return result.length > 0 ? result : null;
    } catch (error) {
        console.log('qwer', error);
        return result;
    };
};

export const formatWithId = (id, data) => ({ id, ...data });

export const getNow = () => Math.round(Date.now() / Metrics.MILLIS_PER_SECOND);

export const listenForUpdates = async (collectionName, docName, callback) => {
    if (!collectionName || !docName || !callback) {
        return null;
    };
    // console.log('qwer', collectionName, docName);
    const firestoreRef = await firestore.collection(collectionName).doc(docName);
    const unsubscribe = await firestoreRef.onSnapshot(snap => {
        const result = snap.data();
        if (result) {
            callback(result);
        };
    });
    return unsubscribe;
};

export const sendEmail = (emailAddress, productName, clientName, eventPassCode, userId) => {
    firestore.collection(CollectionNames.EMAIL).add({
        to: emailAddress,
        template: {
            name: FirestoreProps.EMAIL_TEMPLATE,
            data: { 
                clientName, 
                productName, 
                eventPassCode,
                userId,
            },
        },      
    });
};

export const addFirestoreDatum = async (collectionName, datum) => {
    let success = false;
    if (!collectionName || !datum) {
        return success;
    };
    await firestore.collection(collectionName)
        .add(datum)
        .then(function() {
            // console.log("qwer Document successfully written!");
            success = true;
        })
        .catch(function(error) {
            console.error('qwer', error);
        });
    return success;
};

export const updateFirestoreDatum = async (collectionName, datum, docName) => {
    let success = false;
    if (!collectionName || !docName || !datum) {
        return success;
    };
    await firestore.collection(collectionName)
        .doc(docName)
        .set(datum, { merge: true })
        .then(function() {
            // console.log("qwer Document successfully written!");
            success = true;
        })
        .catch(function(error) {
            console.error('qwer', error);
        });
    return success;
};
