export const addElement = (existingElements, newElement) => {
    return getElementIndex(existingElements, newElement) === -1 ? 
        setFirebaseElement(existingElements, newElement)
        : null;
};

export const setFirebaseElement = (existingElements, newElement) => {
    let newElements = existingElements ? [...existingElements] : [];
    // console.log('qwer newElements BEF', newElements)
    let elementIndex = getElementIndex(newElements, newElement);
    // console.log('qwer elementIndex', elementIndex)
    if (elementIndex === -1) {
        newElements.push(newElement);
    } else {
        newElements[elementIndex] = newElement;
    };
    // console.log('qwer newElements AFT', newElements)
    return newElements;
};

export const setFirebaseElements = (existingElements, newElements) => {
    let adjustedElements = arrayExists(existingElements) ?
        [...existingElements] 
        : [];
    for (const i in newElements) {
        adjustedElements = setFirebaseElement(adjustedElements, newElements[i]);
    };
    return adjustedElements;
};

// export const arraysExistAndMatch = (firstArray, secondArray) => {
//     return arrayExists(firstArray)
//         && arrayExists(secondArray)
//         && arraysMatch(firstArray, secondArray);
// };

export const removeElement = (existingElements, oldElement) => {
    let elementIndex = getElementIndex(existingElements, oldElement);
    if (elementIndex === -1) {
        return existingElements;
    };
    let newElements = [...existingElements];
    newElements.splice(elementIndex, 1);
    return newElements;
};

// ------------

const arrayExists = array => array !== null && array !== undefined && array.constructor === Array;

const getElementIndex = (existingElements, newElement) => {
    if (!existingElements || !newElement) {
        return -1;
    }
    const newElementId = newElement.id;
    for (const i in existingElements) {
        if (existingElements[i].id === newElementId) {
            return i;
        };
    };
    return -1;
};
