import { Metrics } from "../app.types";

const Countdown = ({ timer }) => {
    const maxMinutesOrSeconds = Metrics.MAX_MINUTES_OR_SECONDS;
    const maxHours = Metrics.MAX_HOURS;
    const minutes = timer.minutes % maxMinutesOrSeconds;
    let hours = Math.floor(timer.minutes / maxMinutesOrSeconds);
    const days = hours < maxHours ? null :  Math.floor(hours / maxHours);
    hours = hours % maxHours;
    const formatTime = numb => String(numb).padStart(2, "0");
    // console.log('qwer', days, hours, minutes, timer.seconds);
    const secsString = `${formatTime(timer.seconds)}`;
    const minsString = `${formatTime(minutes)}:`;
    const hoursString = hours > 0 ? `${formatTime(hours)}:` : "";
    const daysString = days !== null ? `${formatTime(days)}:` : "";
    return `${daysString}${hoursString}${minsString}${secsString}`
}

export default Countdown;
