import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import withGlobalUtils from "./with-global-utils";

import { CollectionNames, FirestoreProps, ServerResponseStates } from "../firebase/firestore.types";
import { fetchDataByFieldPairs } from "../firebase/firestore.utils";

import { selectAdminBreakoutRoomNumber } from "../redux/admin/admin.selectors";
import { selectAdminId } from "../redux/admin/admin.selectors";
import { selectEvents } from "../redux/event/event.selectors";
import { selectProducts } from "../redux/product/product.selectors";

import { addEvents, setEventId } from "../redux/event/event.actions";
import { addProducts } from "../redux/product/product.actions";

import { AdminProps } from "../app.types";
import { PageTypes } from "./page.types";

const withAdminUtils = WrappedComponent => {
    class WithAdminUtils extends React.Component {
        
        fetchCollection = collectionName => {
            const { addEvents, addProducts, setServerResponseState } = this.props;
            setServerResponseState(ServerResponseStates.WAITING);
            fetchDataByFieldPairs(
                collectionName,
                null,
                collection => {
                    setServerResponseState(ServerResponseStates.DORMANT);
                    if (!collection) {
                        return;
                    };
                    switch (collectionName) {
                        case CollectionNames.EVENTS:    addEvents(collection);      break;
                        case CollectionNames.PRODUCTS:  addProducts(collection);    break;
                        default: break;
                    };
                }
            );
        };
        
        handleChooseEventSubmit = e => {
            e.preventDefault();
            const eventId = e.target.value;
            // console.log('qwer', eventId);
            const filteredEvents = this.props.events.filter(event => event.id === eventId);
            const newEventId = filteredEvents.length === 1 ? 
                filteredEvents[0][FirestoreProps.ID]
                : null;
            this.props.setEventId(newEventId);
            this.props.history.push(`/${PageTypes.ADMIN}/${PageTypes.UPDATE_EVENT}`);
        };
        
        handleManageEventClick = e => {
            e.preventDefault();
            const value = e.target.value;
            let pageType = null;
            switch (value) {
                case AdminProps.MONITOR:            pageType = PageTypes.MONITOR_EVENT;     break;
                case AdminProps.UPDATE:             pageType = PageTypes.UPDATE_EVENT;      break;
                case AdminProps.DESELECT_EVENT:     this.props.setEventId(null);            break;
                default: break;
            };
            const locationSuffix = `/${PageTypes.ADMIN}`;
            this.props.history.push(
                pageType ? 
                    `${locationSuffix}/${pageType}`
                    : locationSuffix
            );
        };
        
        render() {
            return <WrappedComponent 
                fetchCollection={this.fetchCollection}
                handleChooseEventSubmit={this.handleChooseEventSubmit}
                handleManageEventClick={this.handleManageEventClick}
                {...this.props} 
            />
        };
    };

    const mapStateToProps = createStructuredSelector({
        adminId: selectAdminId,
        breakoutRoomNumber: selectAdminBreakoutRoomNumber,
        events: selectEvents,
        products: selectProducts,
    });
    
    const mapDispatchToProps = dispatch => ({
        addEvents: value => dispatch(addEvents(value)),
        addProducts: value => dispatch(addProducts(value)),
        setEventId: value => dispatch(setEventId(value)),
    });
    
    return withGlobalUtils(
        connect(mapStateToProps, mapDispatchToProps)(WithAdminUtils)
    );
};

export default withAdminUtils;