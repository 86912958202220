import React from "react";

import { ItemImage, SirvContainer } from "./item/item-outputs.styles";
import VideoPlayer from "./video-player.component";

import { FirestoreProps } from "../firebase/firestore.types"
import { MediaTypes } from "./item/item-io.types";
import { InteractionKeys, InteractionTypes } from "../firebase/realtime.types";

export const getActionByInteractionType = interactionType => {
    switch (interactionType) {
        case InteractionTypes.CHALLENGE_INCORRECT:          return "wrong answer";
        case InteractionTypes.CLICKED_OBJECT:               return "viewed";
        case InteractionTypes.INFO_FOUND:                   return "found info";
        case InteractionTypes.INVENTORY_ITEM_MISUSED:       return "misused item";
        case InteractionTypes.INVENTORY_ITEM_USED:          return "used item";
        case InteractionTypes.MAJOR_HINT:                   return "big hint";
        case InteractionTypes.MINOR_HINT:                   return "small hint";
        case InteractionTypes.USER_CHALLENGE_CORRECT:       return "answered for team";
        case InteractionTypes.TEAM_CHALLENGE_CORRECT:       return "answered";
        default:                                            return "unknown";
    }
};

// export const getInteractionText = (interaction, selfId, adminId, users, challenges) => {
//     if (!interaction) {
//         return null;
//     };
//     const type = interaction[InteractionKeys.TYPE];
//     switch (type) {
//         case InteractionTypes.INVENTORY_ITEM_MISUSED:
//             return null;
//         case InteractionTypes.TIMER_WARNING:
//             return `${interaction[InteractionKeys.OTHER_VALUES][InternalProps.TIMER_MINUTES]} minutes to go...`;
//         default: break;
//     };
//     const interactionUserId = interaction[InteractionKeys.USER_ID];
//     const isSelf = interactionUserId === selfId;
//     let phrase = null;
//     const areIs = isSelf ? "are" : "is";
//     const haveHas = isSelf ? "have" : "has";
//     const filteredUsers = users.filter(user => user[FirestoreProps.ID] === interactionUserId);
//     let userName = "";
//     switch (true) {
//         case adminId !== null:              userName = "An administrator";                  break;
//         case filteredUsers.length === 1:    userName = filteredUsers[0][UserKeys.NAME];     break;
//         default:                            userName = "Someone";                           break;
//     };
//     const userRef = isSelf ? `${userName} (you)` : userName;
//     // console.log('qwer', users)
    
//     switch (type) {
//         case InteractionTypes.INACTIVE:
//             return `${userRef} ${haveHas} become a bit quiet...`;
//         case InteractionTypes.ENTRY:
//             phrase = `entered the ${interaction[InteractionKeys.PAGE]}`;
//             break;
//         // case InteractionTypes.EXIT:
//         //     phrase = `left the ${interaction[InteractionKeys.PAGE]}`;
//         //     break;
//         case InteractionTypes.LEFT_GAME:
//             phrase = "left the event";
//             break;
//         case InteractionTypes.EVENT_UPDATED:
//             phrase = "updated an event";
//             break;
//         case InteractionTypes.INFO_FOUND:
//             phrase = "discovered some info";
//             break;
//         case InteractionTypes.INVENTORY_ITEM_USED:
//             phrase = "used an inventory item";
//             break;
//         case InteractionTypes.INVENTORY_ITEM_MISUSED:
//             phrase = "used an inventory item";
//             break;
//         case InteractionTypes.HELP:
//             phrase = "requested help";
//             break;
//         case InteractionTypes.MINOR_HINT:
//         case InteractionTypes.MAJOR_HINT:
//             const isMinor = type === InteractionTypes.MINOR_HINT;
//             phrase = `requested a ${isMinor ? "minor" : "major"} hint`;
//             break;
//         case InteractionTypes.CLICKED_OBJECT:
//         case InteractionTypes.CHALLENGE_INCORRECT:
//         case InteractionTypes.USER_CHALLENGE_CORRECT:
//         case InteractionTypes.TEAM_CHALLENGE_CORRECT:
//             // console.log('qwer', interaction);
//             const itemRef = interaction[InteractionKeys.OTHER_VALUES][FirestoreProps.DOCUMENT_ID];
//             const filteredChallenges = challenges ? 
//                 challenges.filter(challenge => challenge.id === itemRef)
//                 : null;
//             const challenge = filteredChallenges && filteredChallenges.length === 1 ?
//                 filteredChallenges[0]
//                 : null;
//             // console.log('qwer', itemRef, challenge, challenges);
//             if (type === InteractionTypes.CLICKED_OBJECT) {
//                 phrase = challenge !== null ? 
//                     `${areIs} looking at the ${challenge[FirestoreProps.DESCRIPTION]}`
//                     : `${areIs} looking at something...`;
//             } else {
//                 const hasFailed = type === InteractionTypes.CHALLENGE_INCORRECT;
//                 // console.log('qwer', challenges, filteredChallenges);
//                 const verb = hasFailed ? "failed" : "cracked";
//                 const item = challenge ?
//                     `the ${challenge[FirestoreProps.DESCRIPTION]} challenge!` :     
//                     "an unknown challenge";
//                 phrase = `${verb} ${item}`;
//             };
//             break;
//         default:
//             phrase = ` unhandled action: ${type}`;
//             break;
//     };
//     return `${userRef} ${phrase}`;
// };

export const getMediumView = (medium, key, storageUrls) => {
    const mediumId = medium[FirestoreProps.ID];
    const type = medium[FirestoreProps.TYPE];
    const value = medium[FirestoreProps.VALUE];
    const complexKey = `${key}_${mediumId}`;
    const filteredStorageUrls = storageUrls ? 
        storageUrls.filter(storageUrl => storageUrl[FirestoreProps.ID] === value)
        : null;
    const storageUrl = filteredStorageUrls && filteredStorageUrls.length === 1 ?
        filteredStorageUrls[0][FirestoreProps.URL]
        : null;
    switch (type) {
        case MediaTypes.AUDIO:
            return <audio key={complexKey} controls>
                <source src={value} type="audio/mpeg"></source>
                Your browser does not support the audio medium.
            </audio>;
        case MediaTypes.IMAGE:
            return storageUrl ? 
                <ItemImage key={complexKey} src={storageUrl} alt="" />
                : null;
        case MediaTypes.SIRV:
            return <SirvContainer>
                <div key={complexKey} className="Sirv" data-src={value}></div>
            </SirvContainer>;
        case MediaTypes.TEXT:
            return <p key={complexKey}>{value}</p>;
        case MediaTypes.VIDEO:
            return storageUrl ? 
                <VideoPlayer key={complexKey} url={storageUrl} />
                : null;
        default:
            return <p key={complexKey}>{`ERROR no case for media type: ${type}`}</p>;
    };
};

export const getNumUses = (inventoryItem, interactions) => {
    const numUses = inventoryItem[FirestoreProps.NUM_USES];
    if (!numUses || numUses === undefined) {
        return null;
    }
    const filteredInteractions = interactions.filter(interaction => {
        const otherValues = interaction[InteractionKeys.OTHER_VALUES];
        return otherValues
            && otherValues !== undefined   
            && otherValues[FirestoreProps.ITEM_ID] === inventoryItem[FirestoreProps.ID]
            && interaction[InteractionKeys.TYPE] === InteractionTypes.INVENTORY_ITEM_USED
    });
    return numUses - filteredInteractions.length;
};

export const getValidInventoryItems = (inventoryItems, interactions, selectedInventoryItemId) => {
    let validInventoryItems = [];
    for (const inventoryItem of inventoryItems) {
            // console.log('qwer i.i.', inventoryItem[FirestoreProps.ID], selectedInventoryItemId);
            if (inventoryItem[FirestoreProps.ID] !== selectedInventoryItemId) {
            const numUses = getNumUses(inventoryItem, interactions);
            if (!numUses || numUses > 0) {
                validInventoryItems.push(inventoryItem);
            };
        };
    };
    return validInventoryItems.length > 0 ? validInventoryItems : null;
};
