import { createSelector } from "reselect";
import { FirestoreProps } from "../../firebase/firestore.types";

import { InteractionKeys } from "../../firebase/realtime.types";

const interactionsReducer = state => state.interactions;

export const selectInteractions = createSelector(
    [interactionsReducer],
    reducer => reducer.interactions
);

const sortInteractions = interactions => interactions.sort((a, b) => {
    return a[InteractionKeys.TIMESTAMP] < b[InteractionKeys.TIMESTAMP]
});

export const selectInteractionsByKeyValue = filters => createSelector(
    [selectInteractions],
    interactions => {
        if (!interactions) {
            return null;
        }
        let results = interactions.filter(interaction => {
            for (const i in filters) {
                const pair = filters[i];
                for (const key in pair) {
                    // console.log("key found", pair, key, pair[key])
                    if (pair[key] !== interaction[key]) {
                        return false;
                    }
                };
            };
            return true;
        });
        return results.length > 0 ? sortInteractions(results) : null;
    }
);

export const selectInteractionsByHotspotDetailsId = hotspotDetailsId => createSelector(
    [selectInteractions],
    interactions => {
        return interactions ?
            interactions.filter(interaction => {
                const otherValues = interaction[InteractionKeys.OTHER_VALUES];
                return otherValues && otherValues !== undefined ? 
                    otherValues[FirestoreProps.DOCUMENT_ID] === hotspotDetailsId
                    : false;
            })
            : null;
    }
);
