import { createSelector } from "reselect";
import { FirestoreProps } from "../../firebase/firestore.types";

const productReducer = state => state.product;

export const selectHotspotDetails = createSelector(
    [productReducer],
    reducer => reducer.hotspotsDetails
);

export const selectHints = createSelector(
    [productReducer],
    reducer => reducer.hints
);

export const selectHotspots = createSelector(
    [productReducer],
    reducer => reducer.hotspots
);

export const selectImageMaps = createSelector(
    [productReducer],
    reducer => reducer.imageMaps
);

export const selectItems = createSelector(
    [productReducer],
    reducer => reducer.items
);

export const selectMedia = createSelector(
    [productReducer],
    reducer => reducer.media
);

export const selectProductId = createSelector(
    [productReducer],
    reducer => reducer.productId
);

export const selectProducts = createSelector(
    [productReducer],
    reducer => reducer.products
);

export const selectScenes = createSelector(
    [productReducer],
    reducer => reducer.scenes
);

export const selectSolutionDependencies = createSelector(
    [productReducer],
    reducer => reducer.solutionDependencies
);

export const selectStorageUrls = createSelector(
    [productReducer],
    reducer => reducer.storageUrls
);

// ------------

export const selectProduct = createSelector(
    [selectProducts, selectProductId],
    (products, productId) => {
        if (!products || !productId) {
            return null;
        }
        const filteredProducts = products.filter(product => product.id === productId);
        // console.log('qwer filteredProducts', filteredProducts);
        return filteredProducts.length === 1 ? filteredProducts[0] : null;
    }
);

export const selectProductAbbreviation = createSelector(
    [selectProduct],
    product => product ? product[FirestoreProps.ABBREVIATION] : null
);

export const selectProductName = createSelector(
    [selectProduct],
    product => product ? product.name : null
);

export const selectProductPassCode = createSelector(
    [selectProduct],
    product => product ? product[FirestoreProps.PASS_CODE] : null
);

// ------------

// export const selectHotspotDetailById = hotspotDetailsId => createSelector(
//     [selectHotspotDetails],
//     hotspotsDetails => {
//         const results = hotspotsDetails ? hotspotsDetails.filter(hotspotDetails => hotspotDetails.id === hotspotDetailsId) : null
//         return results.length === 1 ? results[0] : null;
//     }
// );

export const selectHotspotDetailsBySceneId = sceneId => createSelector(
    [selectHotspotDetails],
    hotspotsDetails => {
        // console.log('qwer', hotspotsDetails);
        if (!sceneId) {
            return null;
        }
        return hotspotsDetails ? 
            hotspotsDetails.filter(hotspotDetails => hotspotDetails.sceneId === sceneId) 
            : null
    }
);

export const selectImageMapBySceneId = sceneId => createSelector(
    [selectImageMaps],
    function(imageMaps) {
        if (!imageMaps) {
            return null;
        };
        const results = imageMaps.filter(imageMap => imageMap.sceneId === sceneId);
        return results.length === 1 ? results[0] : null;
    }
);

export const selectHintByHotspotDetailsId = hotspotDetailsId => createSelector(
    [selectHints],
    hints => {
        if (!hints) {
            return null;
        };
        const results = hints.filter(hint => hint.hotspotDetailsId === hotspotDetailsId);
        return results.length === 1 ? results[0] : null;
    }
);

export const selectHotspotsBySceneId = sceneId => createSelector(
    [selectHotspots, selectImageMaps],
    function(hotspots, imageMaps) {
        // console.log('qwer', hotspots ,imageMaps);
        if (!hotspots || !imageMaps) {
            return null;
        };
        const results = imageMaps.filter(imageMap => {
            return imageMap[FirestoreProps.SCENE_ID] === sceneId;
        });
        // console.log('qwer', sceneId, results);
        if (results.length !== 1) {
            return null;
        };
        const imageMapId = results[0][FirestoreProps.ID];
        // console.log('qwer', imageMapId, results);
        const filteredResults = hotspots.filter(hotspot => {
            return hotspot[FirestoreProps.IMAGE_MAP_ID] === imageMapId
        });
        // console.log('qwer', filteredResults);
        return filteredResults;
    }
);

export const selectSceneById = sceneId => createSelector(
    [selectScenes],
    scenes => {
        if (!scenes) {
            return null;
        };
        const results = scenes.filter(scene => scene[FirestoreProps.ID] === sceneId);
        return results.length === 1 ? results[0] : null;
    }
);

export const selectScenesByProductId = productId => createSelector(
    [selectScenes],
    scenes => {
        if (!scenes) {
            return null;
        };
        const results = scenes
            .filter(scene => scene[FirestoreProps.PRODUCT_ID] === productId)
            .sort((a, b) => a[FirestoreProps.INDEX] - b[FirestoreProps.INDEX]);
        return results.length > 0 ? results : null;
    }
);

export const selectMinutesBySceneId = sceneId => createSelector(
    [selectSceneById(sceneId)],
    scene => scene ? scene[FirestoreProps.MINUTES] : null
);
