import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Column, ContentContainer, Row } from "../global.styles";
import BreakoutRoomsSelect from "../components/breakout-rooms-select.component";
import InteractionsList from "../components/interactions-list.component";
import ManageEventChooser from "../components/manage-event-chooser.component";
import SceneDetails from "../components/scene-details.component";
import UsersList from "../components/users-list.component";
import withAdminUtils from "./with-admin-utils";

import { CollectionNames, FirestoreProps } from "../firebase/firestore.types";
import { fetchDatumByDocName, fetchDataByFieldPairs } from "../firebase/firestore.utils";
import { fetchDocuments, fetchInteractions } from "../firebase/realtime.utils";
import { InteractionKeys, InteractionTypes, RealTimeKeys, UserKeys } from "../firebase/realtime.types";

import { selectAmountBreakoutRooms, selectEventId } from "../redux/event/event.selectors";
import { selectProductId } from "../redux/product/product.selectors";

import { addInteractions } from "../redux/interactions/interactions.actions";
import { addProduct, addProductId, addScenes } from "../redux/product/product.actions";
import { addUsers } from "../redux/users/users.actions";
import { setAdminBreakoutRoomNumber } from "../redux/admin/admin.actions";

import { ModalHeaders } from "../modal.types";
import { PageTypes } from "./page.types";

class MonitorEventPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sceneId: null,
        };
    };

    async componentDidMount() {
        const { amountBreakoutRooms, event, productId, addInteractionBySnap } = this.props;
        const eventId = event[FirestoreProps.ID];
        const eventThread = this.props.getEventThread();

        if (!productId) {
            const product = await fetchDatumByDocName(
                CollectionNames.PRODUCTS, 
                event[FirestoreProps.PRODUCT_ID],
            );
            if (product) {
                this.props.addProduct(product);
                this.props.addProductId(product[FirestoreProps.ID]);
            };
        } else {
            const scenes = await fetchDataByFieldPairs(
                CollectionNames.SCENES, 
                [{ 
                    key: FirestoreProps.PRODUCT_ID, 
                    value: productId
                }]
            );
            if (scenes) {
                this.props.addScenes(scenes);
            };
        };

        const users = await fetchDocuments(`${eventId}/${RealTimeKeys.USERS}`);
        if (users) {
            this.props.addUsers(users);
        };
        
        let i;
        for (i=0; i<amountBreakoutRooms; i++) {
            const interactions = await fetchInteractions(`${eventThread}/${RealTimeKeys.BREAKOUT_ROOM}${i + 1}`);
            // console.log('qwer', `${eventThread}/${RealTimeKeys.BREAKOUT_ROOM}${i + 1}`, interactions);
            if (interactions && interactions.interactions) {
                this.props.addInteractions(interactions.interactions);
            };
            // for (var it = this.props.allIds.values(), val= null; val=it.next().value; ) {
            //     console.log('qwer', val);
            // }
            // console.log('qwer', this.props.allIds)
            this.props.listenForInteractions(
                `${eventThread}/${RealTimeKeys.BREAKOUT_ROOM}${i + 1}`,
                snap => {
                    if (this.props.allIds.indexOf(snap.key) > -1) {
                        return;
                    };
                    const interaction = addInteractionBySnap(snap);
                    if (users && interaction[InteractionKeys.TYPE] === InteractionTypes.HELP) {
                        const userId = interaction[InteractionKeys.USER_ID];
                        const filteredUsers = users.filter(user => user[FirestoreProps.ID] === userId);
                        if (filteredUsers.length === 1) {
                            const user = filteredUsers[0];
                            this.props.setModalMessages({ 
                                header: ModalHeaders.HELP, 
                                message: `${user[UserKeys.NAME]} in breakout room ${user[UserKeys.BREAKOUT_ROOM_NUMBER]}`
                            });
                        };
                    };
                }
            );
        };
            
        this.props.setPageName(PageTypes.MONITOR_EVENT);
    };

    componentWillUnmount() {
        this.props.ignoreInteractions();
    };

    handleBreakoutRoomSelectChange = e => {
        this.props.setAdminBreakoutRoomNumber(+e.target.value);
    };

    handleSceneClick = e => {
        this.setState({ sceneId: e.target[FirestoreProps.ID] })
        // console.log('qwer', e.target[FirestoreProps.ID]);
    };

    render() {
        const { adminId, breakoutRoomNumber, productId } = this.props;
        const { sceneId } = this.state;
        // console.log('qwer', breakoutRoomNumber, productId, sceneId);
        return (<ContentContainer>
            {adminId ? 
                <ManageEventChooser 
                    handleChooseEventSubmit={this.props.handleChooseEventSubmit}
                    handleManageEventClick={this.props.handleManageEventClick}
                />
                : null
            }
            <Row>
                <Column widthPercent={50}>
                <BreakoutRoomsSelect
                    handleChange={this.handleBreakoutRoomSelectChange}
                />
                <SceneDetails 
                    productId={productId}
                    sceneId={sceneId}
                    handleClick={this.handleSceneClick}
                />
                <UsersList 
                    breakoutRoomNumber={breakoutRoomNumber}
                />
                </Column>
                <Column widthPercent={50}>
                    <InteractionsList 
                        breakoutRoomNumber={breakoutRoomNumber}
                        sceneId={sceneId}
                    />
                </Column>
            </Row>
        </ContentContainer>);
    };
}

const mapStateToProps = createStructuredSelector({
    amountBreakoutRooms: selectAmountBreakoutRooms,
    eventId: selectEventId,
    productId: selectProductId,
});

const mapDispatchToProps = dispatch => ({
    addInteractions: value => dispatch(addInteractions(value)),
    addProduct: value => dispatch(addProduct(value)),
    addProductId: value => dispatch(addProductId(value)),
    addScenes: value => dispatch(addScenes(value)),
    addUsers: value => dispatch(addUsers(value)),
    setAdminBreakoutRoomNumber: value => dispatch(setAdminBreakoutRoomNumber(value)),
});

export default withAdminUtils(
    connect(mapStateToProps, mapDispatchToProps)(MonitorEventPage)
);
