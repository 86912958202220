import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { CollectionNames, FirestoreProps, InternalProps, ItemStates } from "../firebase/firestore.types";
import { fetchDatumByDocName } from "../firebase/firestore.utils";
import { InteractionKeys, InteractionTypes, UserKeys } from "../firebase/realtime.types";
import withUserUtils from "./with-user-utils";

import { selectEventId } from "../redux/event/event.selectors";
import { selectHotspotDetails, selectItems, selectSolutionDependencies } from "../redux/product/product.selectors";
import { selectInteractions } from "../redux/interactions/interactions.selectors";
import { selectSceneId } from "../redux/game/game.selectors";
import { selectSelf, selectUsers } from "../redux/users/users.selectors";

import { addInventoryItem, addInventoryItemState, addSolvedSolution } from "../redux/game/game.actions";

const withInventoryItemUtils = WrappedComponent => {
    class WithInventoryItemUtils extends React.Component {

        fetchInventoryItemsByHotspotDetailsId = hotspotDetailsId => {
            const hotspotDetails = this.getHotspotsDetailsById(hotspotDetailsId);
            // console.log('qwer fiby hdId', hotspotDetailsId, hotspotDetails)
            const item = hotspotDetails ? 
                this.getItemById(hotspotDetails[FirestoreProps.ITEM_ID])
                : null;
            if (item) {
                this.fetchInventoryItemsByItemAndHotspotsDetails(
                    item,
                    hotspotDetails,
                );
            };
        };

        fetchInventoryItemsByItemAndHotspotsDetails = (item, hotspotDetails) => {
            if (!item || !hotspotDetails) {
                // console.log('qwer fetchMain REJECTED');
                return;
            }
            const { addInventoryItem, addInventoryItemState } = this.props;
            // console.log('qwer fetchMain A', item, hotspotDetails);
            const itemInventoryItems = item[FirestoreProps.INVENTORY_ITEM_IDS];
            if (itemInventoryItems && itemInventoryItems !== undefined) {
                const hotspotsDetailsState = this.getHotspotsDetailsState(hotspotDetails);
                const itemInventoryItemsByState = itemInventoryItems[hotspotsDetailsState];
                // console.log('qwer fetchMain B', hotspotsDetailsState, itemInventoryItemsByState);
                if (itemInventoryItemsByState && itemInventoryItemsByState !== undefined) {
                    // console.log('qwer fetchMain C');
                    for (const inventoryItemId of itemInventoryItemsByState) {
                        // console.log('qwer fetchMain D');
                        fetchDatumByDocName(
                            CollectionNames.ITEMS, 
                            inventoryItemId,
                            inventoryItem => {
                                addInventoryItem(inventoryItem);
                                addInventoryItemState({
                                    [FirestoreProps.ID]: inventoryItemId,
                                    [InternalProps.STATE]: ItemStates.DEFAULT,
                                });
                            }
                        );                    
                    };
                };
            };
        };

        filterInteractionsByDependentSolutionIds = dependentSolutionIds => {
            const { interactions } = this.props;
            if (
                !interactions 
                || interactions === undefined
                || !dependentSolutionIds 
                || dependentSolutionIds === undefined
            ) {
                return null;
            };
            // console.log('qwer fibdsi ---------------------------------');
            const userInteractions = this.filterObjectsByKeyValue(
                interactions,
                [
                    { [InteractionKeys.TYPE]: InteractionTypes.USER_CHALLENGE_CORRECT },
                    // { [InteractionKeys.USER_ID]: self[FirestoreProps.ID] },
                ]
            );
            const teamInteractions = this.filterObjectsByKeyValue(
                interactions,
                [{ [InteractionKeys.TYPE]: InteractionTypes.TEAM_CHALLENGE_CORRECT }]
            );
            // console.log('qwer fibdsi A', userInteractions);
            // console.log('qwer fibdsi B', teamInteractions);
            let results = [];
            let allInteractions = null;
            switch (true) {
                case !userInteractions && !teamInteractions:
                    return null;
                case !userInteractions:
                    allInteractions = teamInteractions;
                    break;
                case !teamInteractions:
                    allInteractions = userInteractions;
                    break;
                default:
                    allInteractions = userInteractions.concat(teamInteractions);
                    break;
            };
            // console.log('qwer fibdsi C', allInteractions);
            for (const dependentSolutionId of dependentSolutionIds) {
                results = results.concat(allInteractions.filter(interaction => {
                    // console.log('qwer fibdsi D', dependentSolutionId);
                    const otherValues = interaction[InteractionKeys.OTHER_VALUES];
                    if (otherValues && otherValues !== undefined) {
                        const solvedSolutionId = otherValues[FirestoreProps.SOLUTION_ID];
                        // console.log('qwer fibdsi E', solvedSolutionId);
                        return solvedSolutionId === dependentSolutionId;
                    } else {
                        return false;
                    };
                }));
            };
            // console.log('qwer', results);
            return results.length > 0 ? results : null;
        };
        
        filterObjectsByKeyValue = (objects, filters) => {
            // console.log("qwer -------------------------------------");
            if (!objects) {
                return null;
            }
            let results = objects.filter(object => {
                // console.log("qwer object", object);
                for (const pair of filters) {
                    for (const key in pair) {
                        const pairValue = pair[key];
                        const objectValue = object[key];
                        // console.log("qwer pairValue", pairValue);
                        if (typeof pairValue === "object" && pairValue !== null) {
                            for (const objectKey in pairValue) {
                                // console.log("qwer objs", pairValue[objectKey], objectValue[objectKey]);
                                if (pairValue[objectKey] !== objectValue[objectKey]) {
                                    return false;
                                };
                            };
                        } else {
                            // console.log("qwer prims", pairValue, object[key])
                            if (pairValue !== objectValue) {
                                return false;
                            }
                        };
                    };
                };
                return true;
            });
            return results.length > 0 ? this.sortTimestampedObjects(results) : null;
        };

        getDependentSolutionsSolved = hotspotDetails => {
            const hotspotDetailsId = hotspotDetails[FirestoreProps.ID];
            const solutionDependencies = this.getSolutionDependenciesByHotspotDetailsId(hotspotDetailsId);
            // console.log('qwer gdss A', solutionDependencies);
            if (!solutionDependencies) {
                return false;
            };
            const dependentSolutionIds = solutionDependencies[FirestoreProps.DEPENDENT_SOLUTION_IDS];
            if (!dependentSolutionIds) {
                return true;
            }
            // console.log('qwer gdss B', dependentSolutionIds);
            const interactionSolutions = this.filterInteractionsByDependentSolutionIds(
                dependentSolutionIds
            );
            // console.log('qwer gdss C', interactionSolutions);

            if (!interactionSolutions) {
                return false;
            };
            
            const { breakoutRoomNumber, interactions, users } = this.props;
            if (!users || !interactions) {
                return false;
            };
            const filteredUsers = users.filter(user => {
                return user[UserKeys.BREAKOUT_ROOM_NUMBER] === breakoutRoomNumber
            });
            const numUsers = filteredUsers.length;
            // console.log('qwer gdss D', numUsers);
            for (const dependentSolutionId of dependentSolutionIds) {
                const filteredInteractionSolutions = interactionSolutions.filter(interactionSolution => {
                    const otherValues = interactionSolution[InteractionKeys.OTHER_VALUES];
                    const solutionId = otherValues ? 
                        otherValues[FirestoreProps.SOLUTION_ID]
                        : null;
                    return solutionId === dependentSolutionId;
                });
                // console.log('qwer gdss E', numUsers, filteredInteractionSolutions.length);
                if (filteredInteractionSolutions.length !== numUsers) {
                    return false;
                }
                // switch (interactionSolutionToDependentSolutions[InteractionKeys.TYPE]) {
                //     case InteractionTypes.USER_CHALLENGE_CORRECT:
                //         numSolutionsRequired += numUsers;
                //         break;
                //     case InteractionTypes.TEAM_CHALLENGE_CORRECT:
                //         numSolutionsRequired += 1;
                //         break;
                //     default: break;
                // }
            };
            // here after both users answering cryptex successfully it still seems like only one interactionSolutionToDependentSolutions is coming out of filterInteractionsByDependentSolutionIds when it should be two. why?
            // console.log('qwer gdss E', interactionSolutions.length, numSolutionsRequired);

            return true;
        };

        getHotspotsDetailsById = hotspotDetailsId => {
            const { hotspotDetails } = this.props;
            const filteredHotspotsDetails = hotspotDetails.filter(hotspotDetails => {
                return hotspotDetails[FirestoreProps.ID] === hotspotDetailsId
            })
            return filteredHotspotsDetails.length === 1 ? 
                filteredHotspotsDetails[0] 
                : null;
        };

        getHotspotsDetailsByInteraction = interaction => {
            const { hotspotDetails } = this.props;
            const otherValues = hotspotDetails ?
                interaction[InteractionKeys.OTHER_VALUES]
                : null;
            const hotspotDetailsId = otherValues ? 
                otherValues[FirestoreProps.DOCUMENT_ID]
                : null;
            const filteredHotspotDetails = hotspotDetailsId && hotspotDetailsId !== undefined ? 
                hotspotDetails.filter(hotspotDetail => {
                    return hotspotDetail[FirestoreProps.ID] === hotspotDetailsId
                })
                : null;
            return filteredHotspotDetails && filteredHotspotDetails.length === 1 ?
                filteredHotspotDetails[0]
                : null;
        };
        
        getHotspotsDetailsState = hotspotDetails => {
            if (!hotspotDetails) {
                return null;
            };
            const dependentSolutionsSolved = this.getDependentSolutionsSolved(hotspotDetails);
            // console.log('qwer getState A', dependentSolutionsSolved);
            if (!dependentSolutionsSolved) {
                return ItemStates.BLOCKED;
            };
            const { self } = this.props;
            const hotspotDetailsId = hotspotDetails[FirestoreProps.ID];
            const interactionsByHotspotDetailsId = this.getInteractionsByHotspotDetailsId(hotspotDetailsId);
            // console.log('qwer getState B', interactionsByHotspotDetailsId);
    
            const infoAnswers = this.filterObjectsByKeyValue(
                interactionsByHotspotDetailsId,
                [
                    { [InteractionKeys.OTHER_VALUES]: { [FirestoreProps.DOCUMENT_ID]: hotspotDetailsId } }, 
                    { [InteractionKeys.TYPE]: InteractionTypes.INFO_FOUND },
                ]
            );
            // console.log('qwer getState infoAnswers', infoAnswers);
            if (infoAnswers) {
                return ItemStates.UNLOCKED;
            };
    
            const userAnswers = this.filterObjectsByKeyValue(
                interactionsByHotspotDetailsId,
                [
                    { [InteractionKeys.OTHER_VALUES]: { [FirestoreProps.DOCUMENT_ID]: hotspotDetailsId } }, 
                    { [InteractionKeys.TYPE]: InteractionTypes.USER_CHALLENGE_CORRECT },
                    { [InteractionKeys.USER_ID]: self.id },
                ]
            );
            // console.log('qwer getState userAnswers', userAnswers);
            if (userAnswers) {
                return ItemStates.UNLOCKED;
            };
    
            const teamAnswers = this.filterObjectsByKeyValue(
                interactionsByHotspotDetailsId,
                [
                    { [InteractionKeys.OTHER_VALUES]: { [FirestoreProps.DOCUMENT_ID]: hotspotDetailsId } }, 
                    { [InteractionKeys.TYPE]: InteractionTypes.TEAM_CHALLENGE_CORRECT },
                ]
            );
            // console.log('qwer getState teamAnswers', teamAnswers);
            return teamAnswers ?
                ItemStates.UNLOCKED
                : ItemStates.DEFAULT;
        };

        getInteractionsByHotspotDetailsId = hotspotDetailsId => {
            const { interactions } = this.props;
            return interactions ?
                interactions.filter(interaction => {
                    const otherValues = interaction[InteractionKeys.OTHER_VALUES];
                    return otherValues && otherValues !== undefined ?
                        otherValues[FirestoreProps.DOCUMENT_ID] === hotspotDetailsId
                        : false;
                })
                : null;
        };

        getItemById = itemId => {
            const { items } = this.props;
            const filteredItems = items.filter(item => item[FirestoreProps.ID] === itemId);
            // console.log('qwer', itemId, items);
            return filteredItems.length === 1 ? filteredItems[0] : null;
        };

        getSolutionDependenciesByHotspotDetailsId = hotspotDetailsId => {
            const { solutionDependencies } = this.props;
            // console.log('qwer getSolutionDependenciesByHotspotDetailsId', solutionDependencies);
            if (!solutionDependencies) {
                return null;
            }
            // const hotspotDetails = this.getHotspotsDetailsById();
            // const hotspotDetailsId = hotspotDetails[FirestoreProps.ID];
            const filteredSolutionDependencies = solutionDependencies.filter(solutionDependency => {
                return solutionDependency[FirestoreProps.HOTSPOT_DETAILS_ID] === hotspotDetailsId;
            });
            return filteredSolutionDependencies.length === 1 ?
                filteredSolutionDependencies[0]
                : null;
        };

        sortTimestampedObjects = interactions => interactions.sort((a, b) => {
            return a[InteractionKeys.TIMESTAMP] < b[InteractionKeys.TIMESTAMP]
        });

        render() {
            return <WrappedComponent 
                fetchInventoryItemsByHotspotDetailsId={this.fetchInventoryItemsByHotspotDetailsId}
                fetchInventoryItemsByItemAndHotspotsDetails={this.fetchInventoryItemsByItemAndHotspotsDetails}
                // fetchSolutionDependenciesByHotspotDetailsId={this.fetchSolutionDependenciesByHotspotDetailsId}
                getDependentSolutionsSolved={this.getDependentSolutionsSolved}
                getHotspotsDetailsById={this.getHotspotsDetailsById}
                getHotspotsDetailsByInteraction={this.getHotspotsDetailsByInteraction}
                getHotspotsDetailsState={this.getHotspotsDetailsState}
                {...this.props} 
            />
        };
    };

    const mapStateToProps = createStructuredSelector({
        eventId: selectEventId,
        hotspotDetails: selectHotspotDetails,
        interactions: selectInteractions,
        items: selectItems,
        sceneId: selectSceneId,
        self: selectSelf,
        solutionDependencies: selectSolutionDependencies,
        users: selectUsers,
    });
    
    const mapDispatchToProps = dispatch => ({
        addInventoryItem: value => dispatch(addInventoryItem(value)),
        addInventoryItemState: value => dispatch(addInventoryItemState(value)),
        addSolvedSolution: value => dispatch(addSolvedSolution(value)),
    });
    
    return withUserUtils(
        connect(mapStateToProps, mapDispatchToProps)(WithInventoryItemUtils)
    );
};

export default withInventoryItemUtils;