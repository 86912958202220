import React from "react";
import { connect } from "react-redux";

import { 
    FormButton, 
    FormButtonSmall, 
    FormButtonIconSmall, 
    FormLabel, 
    FormTextField,
    TableCell,
    TableContainer,
    SpanCentered,
} from "../global.styles";
// import { CaptainContainer, TrafficLightContainer, VoteButtonContainer } from "./traffic-light-list.styles";

import { TeamStates } from "../firebase/firestore.types";

import { 
    selectAllCertifiedTeammateIds, 
    selectCaptainVoteIdByUserId,
    selectNumCaptainVoteIds,
    selectNumCertifiedTeammates, 
    selectNumCertifiedTeammatesByUserId,
    selectNumTeammatesWhoveCertified,
    selectTeamState,
} from "../redux/lobby/lobby.selectors";
import { selectBreakoutRoomUsers, selectNumBreakoutRoomUsers } from "../redux/users/users.selectors";
import { selectMinPlayersPerTeam } from "../redux/event/event.selectors";
import { selectCaptainId, selectTeamName } from "../redux/team/team.selectors";

import { UserKeys } from "../firebase/realtime.types";

const TrafficLightList = ({ 
    allCertifiedTeammateIds,
    awaitingServer,
    captainId,
    captainVoteId,
    breakoutRoomUsers,
    formTeamName,
    handleCaptainSubmit,
    handleCertificationSubmit,
    handleCertificationReject,
    handleTeamNameChange,
    handleTeamNameSubmit,
    minPlayersPerTeam,
    numBreakoutRoomUsers,
    numCaptainVoteIds,
    numCertifiedTeammates,
    numCertifiedTeammatesByUserId,
    numTeammatesWhoveCertified,
    selfId,
    teamName,
    teamState,
}) => {
    const getUserStatus = (passedNumberCertifiedTeammatesByUserId, userId) => {
        const userHasCertifiedSelf = passedNumberCertifiedTeammatesByUserId > 0;
        const userHasCertifiedEveryone = passedNumberCertifiedTeammatesByUserId === numBreakoutRoomUsers;
        // console.log('qwer:', userId, userHasCertifiedSelf,userHasCertifiedEveryone, passedNumberCertifiedTeammatesByUserId, numBreakoutRoomUsers)
        return {
            userHasCertifiedSelf,
            userHasCertifiedEveryone,
            awaitingTeammates: !userHasCertifiedSelf && numBreakoutRoomUsers < minPlayersPerTeam,
            canCertify: !userHasCertifiedSelf && numBreakoutRoomUsers >= minPlayersPerTeam,
            mustRecertify: userHasCertifiedSelf && !userHasCertifiedEveryone,
            hasCertifiedForNow: userHasCertifiedSelf && userHasCertifiedEveryone,
            isCaptain: captainId === userId,
        };
    };
    const userStatus = getUserStatus(numCertifiedTeammatesByUserId, selfId);
    const hasPersonallyVotedForCaptain = captainVoteId !== null && captainVoteId !== undefined;
    const everyoneHasCertified = numTeammatesWhoveCertified === numBreakoutRoomUsers;
    const everyoneCertified = numCertifiedTeammates === numBreakoutRoomUsers;
    const captainChosen = captainId !== null;
    // console.log('qwer:',breakoutRoomUsers)
    return breakoutRoomUsers ? 
        <div>
            <p>Your team:</p>
            {breakoutRoomUsers.map(breakoutRoomUser => {
                const breakoutRoomUserId = breakoutRoomUser.id;
                const certifiedTeammateIdsByUser = allCertifiedTeammateIds ? 
                    allCertifiedTeammateIds[breakoutRoomUserId]
                    : null;
                // plus one so as to include self
                const moreNumCertifiedTeammateIdsByUser = certifiedTeammateIdsByUser ?
                    certifiedTeammateIdsByUser.length + 1 : 0;
                const breakoutRoomUserStatus = getUserStatus(moreNumCertifiedTeammateIdsByUser, breakoutRoomUserId);
                let trafficLight = undefined;
                // console.log('qwer', breakoutRoomUser.nm, breakoutRoomUserStatus);
                switch (true) {
                    case breakoutRoomUserStatus.hasCertifiedForNow:
                        trafficLight = "🤑";
                        break;
                    case breakoutRoomUserStatus.awaitingTeammates || breakoutRoomUserStatus.canCertify:
                        trafficLight = "😡";
                        break;
                    default:
                        trafficLight = "😑";
                        break;
                };
                const breakoutRoomUserName = breakoutRoomUser[UserKeys.NAME];
                const isSelf = breakoutRoomUserId === selfId;
                const selfIdentifier = isSelf ? " (you)" : "";
                return <TableContainer key={breakoutRoomUserId}>
                    <form id={breakoutRoomUserId} onSubmit={handleCaptainSubmit}>
                        <TableCell widthPercent={15}><SpanCentered>{trafficLight}</SpanCentered></TableCell>
                        <TableCell widthPercent={65}>{`${breakoutRoomUserStatus.isCaptain ? "Capt." : ""} ${breakoutRoomUserName}${selfIdentifier}`}</TableCell>
                        <TableCell widthPercent={20}>
                            {
                                !hasPersonallyVotedForCaptain
                                && (!isSelf || minPlayersPerTeam === 1)
                                && (
                                    teamState === TeamStates.ELECTING_CAPTAIN
                                    || (
                                        teamState === TeamStates.TEAM_BUILT 
                                        && everyoneCertified
                                        && (
                                            userStatus.canCertify 
                                            || userStatus.mustRecertify
                                            || everyoneHasCertified
                                        )
                                    )
                                ) ?
                                    <SpanCentered>
                                        <FormButtonIconSmall type="submit">
                                            ✔
                                        </FormButtonIconSmall>
                                    </SpanCentered>
                                    : null
                            }
                        </TableCell>
                    </form>
                </TableContainer>;
            })}
            {awaitingServer ? 
                <>
                    {userStatus.canCertify || userStatus.mustRecertify ?
                        <p>Checking team...</p>
                        : null
                    }
                    {teamState === TeamStates.ELECTING_CAPTAIN
                        || (teamState === TeamStates.TEAM_BUILT && everyoneCertified) ?
                        <p>Casting vote...</p>
                        : null
                    }
                </>
                :
                <>
                    {teamState === TeamStates.BUILDING_TEAM ?
                        <>
                            {userStatus.awaitingTeammates ?
                                <p>Awaiting teammates...</p>
                                : null
                            }
                            {userStatus.hasCertifiedForNow && !captainChosen ?
                                <p>Waiting for teammates to verify as well.</p>
                                : null
                            }
                        </>
                        : null
                    }
                    {teamState === TeamStates.BUILDING_TEAM || teamState === TeamStates.TEAM_BUILT ?
                        <>
                            {userStatus.canCertify ?
                                <p>Does your team match your Zoom team?</p>
                                : null
                            }
                            {userStatus.mustRecertify ?
                                <p>More teammates have arrived, does your team match your Zoom team now?</p>
                                : null
                            }
                            {userStatus.canCertify || userStatus.mustRecertify ?
                                <form onSubmit={handleCertificationSubmit}>
                                    <FormButtonSmall type="submit" value="Yes" />
                                    <FormButtonSmall 
                                        type="button" 
                                        value="No" 
                                        onClick={handleCertificationReject}
                                    />
                                </form>
                                : null
                            }
                            {!userStatus.canCertify 
                                && !userStatus.mustRecertify
                                && everyoneHasCertified ?
                                <p>Team captain voting started.</p>
                                : null
                            }
                        </>
                        : null
                    }
                    {teamState === TeamStates.ELECTING_CAPTAIN ?
                        <>
                            {hasPersonallyVotedForCaptain ?
                                <p>Vote cast.</p>
                                :
                                <p>Cast your vote.</p>
                            }
                            <p>{numCaptainVoteIds} votes out of {numCertifiedTeammates} cast.</p>
                        </>
                        : null
                    }
                    {teamState === TeamStates.CHOOSING_NAME ?
                        <>
                            {userStatus.isCaptain ?
                                <form onSubmit={handleTeamNameSubmit}>
                                    <p>You are team captain.</p>
                                    <FormLabel>Team name:</FormLabel>
                                    <FormTextField
                                        name="formTeamName" 
                                        type="text" 
                                        value={formTeamName} 
                                        onChange={handleTeamNameChange} 
                                        label="email" 
                                        required
                                        />
                                    {/* <br /> */}
                                    <FormButton type="submit" value="Submit" />
                                </form>
                                : 
                                <>
                                    <p>Team captain chosen.</p>
                                </>
                            }
                        </>
                        : null
                    }
                    {teamState === TeamStates.COMPLETE ?
                        <>
                            <p>Team '{teamName}' ready</p>
                        </>
                        : null
                    }
                </>
            }
        </div>
     : null; 
};

const mapStateToProps = (state, ownProps) => ({
    allCertifiedTeammateIds: selectAllCertifiedTeammateIds(state),
    awaitingServer: ownProps.awaitingServer,
    captainId: selectCaptainId(state),
    captainVoteId: selectCaptainVoteIdByUserId(ownProps.selfId)(state),
    breakoutRoomUsers: selectBreakoutRoomUsers(state),
    formTeamName: ownProps.formTeamName,
    handleCaptainSubmit: ownProps.handleCaptainSubmit,
    handleCertificationSubmit: ownProps.handleCertificationSubmit,
    handleCertificationReject: ownProps.handleCertificationReject,
    handleTeamNameChange: ownProps.handleTeamNameChange,
    handleTeamNameSubmit: ownProps.handleTeamNameSubmit,
    minPlayersPerTeam: selectMinPlayersPerTeam(state),
    numBreakoutRoomUsers: selectNumBreakoutRoomUsers(state),
    numCaptainVoteIds: selectNumCaptainVoteIds(state),
    numCertifiedTeammates: selectNumCertifiedTeammates(state),
    numCertifiedTeammatesByUserId: selectNumCertifiedTeammatesByUserId(ownProps.selfId)(state),
    numTeammatesWhoveCertified: selectNumTeammatesWhoveCertified(state),
    selfId: ownProps.selfId,
    teamName: selectTeamName(state),
    teamState: selectTeamState(state),
});

export default connect(mapStateToProps)(TrafficLightList);
