import UserActionTypes from "./users.types";

export const addOtherUser = value => ({
    type: UserActionTypes.ADD_OTHER,
    payload: value
});

export const addUsers = value => ({
    type: UserActionTypes.ADD_USERS,
    payload: value
});

export const setEmail = value => ({
    type: UserActionTypes.SET_EMAIL,
    payload: value
});

export const setOwnUser = value => ({
    type: UserActionTypes.SET_SELF,
    payload: value
});
