import React from "react";

// this doesn't setPageName
const ErrorPage = () => {
    return (<div>
        <div>Oops, looks like something went wrong!</div>
    </div>);
};

export default ErrorPage;
