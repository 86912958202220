import { createSelector } from "reselect";

const adminReducer = state => state.admin;

export const selectAdminBreakoutRoomNumber = createSelector(
    [adminReducer],
    reducer => reducer.breakoutRoomNumber
);

export const selectAdminId = createSelector(
    [adminReducer],
    reducer => reducer.adminId
);

export const selectDebug = createSelector(
    [adminReducer],
    reducer => reducer.debug
);

export const selectAllIds = createSelector(
    [adminReducer],
    reducer => reducer.allIds
);

export const selectModalMessages = createSelector(
    [adminReducer],
    reducer => reducer.modalMessages
);

export const selectServerResponseState = createSelector(
    [adminReducer],
    reducer => reducer.serverWait
);
