import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Column, ContentContainer, FormButton, Row } from "../global.styles";
import ImageMap from "../components/image-map/image-map.component";
import SceneInputs from "../components/scene-inputs.component";
import SceneTimer from "../components/scene-timer.component";
import withGameUtils from "./with-game-utils";

import { CollectionNames, FirestoreProps, ServerResponseStates } from "../firebase/firestore.types";
import { storeInteraction, storeUser } from "../firebase/realtime.utils";
import { fetchDataByFieldPairs } from "../firebase/firestore.utils";
import { InteractionTypes, RealTimeKeys, RoomsCodes, UserKeys } from "../firebase/realtime.types";

import { 
    selectImageMapProperties, 
    selectPageName, 
    selectSceneIndex 
} from "../redux/game/game.selectors";
import { selectPassCode } from "../redux/event/event.selectors";

import { setHintState, setHotspotDetailsId } from "../redux/game/game.actions";
import { setOwnUser } from "../redux/users/users.actions";

import { formatChallengeResponses } from "../components/item/item.utils";

import { Generics } from "../app.types";
import { PageTypes } from "./page.types";
import { ModalHeaders, ModalMessages } from "../modal.types";

class GamePage extends React.Component {
    activeInterval = null;
    constructor(props) {
        super(props);
        
        this.responses = {};
        this.submitting = false;
    };

    async componentDidMount() {
        const { pageName, sceneId, self, assessInteractionBySnap } = this.props;
        const selfId = self.id;
        const thread = this.props.getBreakoutRoomThread();
        const pageType = PageTypes.GAME;

        this.props.listenForInteractions(
            this.props.getBreakoutRoomThread(),
            snap => assessInteractionBySnap(snap)
        );

        if (pageName === PageTypes.INTRO) {
            storeInteraction(selfId, pageType, InteractionTypes.ENTRY, thread, sceneId);
        };
        this.props.setHintState(null);
        this.props.setHotspotDetailsId(null);
        this.props.setPageName(pageType);
    };

    componentWillUnmount() {
        this.props.clearActiveInterval();
    };

    completeScene = async () => {
       const { eventId, self } = this.props;
       const updatedUser = await storeUser(
           self[UserKeys.NAME], 
           self[UserKeys.BREAKOUT_ROOM_NUMBER], 
           RoomsCodes.LOUNGE,
           self[UserKeys.SCENE_INDEX], 
           eventId + "/" + RealTimeKeys.USERS, 
           self.id
       );
       if (updatedUser) {
           this.props.setOwnUser(updatedUser);
       } else {
           this.props.setModalMessages({ 
               header: ModalHeaders.OOPS, 
               message: ModalMessages.BAD_DATA + " (#937)" 
            });
           return;
       };
    };

    getSceneUrl = () => `/${this.props.eventPassCode}/${PageTypes.SCENE}${this.props.sceneIndex + 1}`;

    // HOC (there are many other handleChanges around too, altho still need to work out how to do shared state in HOC)
    handleChange = e => {
        const { value, name } = e.target;
        this.responses[name] = value;
    };

    handleHotspotClick = e => {
        e.preventDefault();
        const polygon = e.target;
        const polygonData = polygon.id.split(Generics.SPLITTER);
        if (polygonData.length !== 2) {
            this.props.setModalMessages({ 
                header: ModalHeaders.OOPS, 
                message: ModalMessages.BAD_DATA + " (#792)" 
            });
            return;
        };
        const hotspotDetailsId = polygonData[0];
        const space = " ";
        let itemDescription = polygonData[1];
        while (itemDescription.indexOf(space) > -1) {
            itemDescription = itemDescription.replace(space, "-");
        };
        if (this.submitting) {
            return;
        };
        this.submitting = true;
        if (!hotspotDetailsId) {
            this.props.setModalMessages({ 
                header: ModalHeaders.OOPS, 
                message: ModalMessages.BAD_DATA + " (#744)" 
            });
            return;
        };
        const { debug, sceneId, self } = this.props;
        storeInteraction(
            self.id, 
            PageTypes.GAME, 
            InteractionTypes.CLICKED_OBJECT, 
            this.props.getBreakoutRoomThread(),
            sceneId,
            { [FirestoreProps.DOCUMENT_ID]: hotspotDetailsId },
        );
        this.props.setHotspotDetailsId(hotspotDetailsId);
        const location = `${this.getSceneUrl()}/${itemDescription.toLowerCase()}`;
        if (debug) {
            this.props.history.push(location);
        } else {
            window.location = location;
        };
    };

    handleInventoryItemsClick = e => {
        const location = `${this.getSceneUrl()}/${PageTypes.INVENTORY_ITEMS}`;
        this.props.history.push(location);
    };

    handleModalMessage = (header, message) => {
        this.props.setModalMessages({ header, message });
    };
    
    handleSceneCodeSubmit = async e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        };
        this.submitting = true;
        const { sceneId, self } = this.props;
        const selfId = self.id;
        const thread = this.props.getBreakoutRoomThread();
        const formattedResponses = formatChallengeResponses(
            this.responses, 
            FirestoreProps.SCENE_ID, 
            sceneId
        );
        this.props.setServerResponseState(ServerResponseStates.WAITING);
        const answers = await fetchDataByFieldPairs(
            CollectionNames.SCENE_CODES, 
            formattedResponses
        );
        this.props.setServerResponseState(ServerResponseStates.DORMANT);
        if (answers) {
            if (answers.length === 1) {
                const result = await storeInteraction(
                    selfId, 
                    PageTypes.GAME, 
                    InteractionTypes.SCENE_CHALLENGE_CORRECT, 
                    thread,
                    sceneId
                );
                if (result) {
                    this.completeScene();
                } else {
                    this.props.setModalMessages({ 
                        header: ModalHeaders.OOPS, 
                        message: ModalMessages.BAD_DATA + " (#766)" 
                    });
                };
            } else {
                this.props.setModalMessages({ 
                    header: ModalHeaders.OOPS, 
                    message: ModalMessages.BAD_DATA + " (#751)" 
                });
            };
        } else {
            const failure = await storeInteraction(
                selfId, 
                PageTypes.GAME, 
                InteractionTypes.SCENE_CHALLENGE_INCORRECT, 
                thread,
                sceneId
            );
            if (failure) {
                console.log("qwer U LOSED :(");
            } else {
                this.props.setModalMessages({ 
                    header: ModalHeaders.OOPS, 
                    message: ModalMessages.BAD_DATA + " (#746)" 
                });
            };
            this.submitting = false;
        };
    };

    handleSceneTimerComplete = () => {
        this.completeScene();
    };

    render() {
        this.submitting = false;
        // console.log("qwer render", this.props.soll);
        const { clientTimeDifference, imageMapProperties, sceneId } = this.props;
        return (<ContentContainer>
            <Row>
                <Column widthPercent={75}>
                    <ImageMap 
                        sceneId={sceneId} 
                        url={imageMapProperties.url}
                        width={imageMapProperties.width}
                        height={imageMapProperties.height}
                        handleModalMessage={this.handleModalMessage}
                        handlePolygonClick={this.handleHotspotClick} 
                    />
                </Column>
                <Column widthPercent={25}>
                    <FormButton 
                        type="button" 
                        value="Inventory Items" 
                        onClick={this.handleInventoryItemsClick}
                    />
                    <SceneTimer
                        clientTimeDifference={clientTimeDifference}
                        sceneId={sceneId}
                        useSceneTime={true}
                        handleComplete={this.handleSceneTimerComplete}
                    />
                    <SceneInputs 
                        sceneId={sceneId}
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSceneCodeSubmit} 
                        handleModalMessage={this.handleModalMessage}
                    />
                </Column>
            </Row>
        </ContentContainer>);
    };
};

const mapStateToProps = createStructuredSelector({
    eventPassCode: selectPassCode,
    imageMapProperties: selectImageMapProperties,
    pageName: selectPageName,
    sceneIndex: selectSceneIndex,
});

const mapDispatchToProps = dispatch => ({
    setHotspotDetailsId: value => dispatch(setHotspotDetailsId(value)),
    setHintState: value => dispatch(setHintState(value)),
    setOwnUser: value => dispatch(setOwnUser(value)),
});

export default withGameUtils(
    connect(mapStateToProps, mapDispatchToProps)(GamePage)
);
