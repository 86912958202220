import InteractionsActionTypes from "./interactions.types";

import { addElement, setFirebaseElements } from "../redux.utils";

const INITIAL_STATE = {
    interactions: null,
};

const interactionsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case InteractionsActionTypes.ADD_INTERACTION:
            // console.log('qwer intera', action.payload);
            const interactions = addElement(state.interactions, action.payload);
            return interactions ? 
                { ...state, interactions }
                : state;
        case InteractionsActionTypes.ADD_INTERACTIONS:
            // console.log('qwer', state.interactions, action.payload);
            return { 
                ...state, 
                interactions: setFirebaseElements(state.interactions, action.payload) 
            };
        default:
            return state;
    };
};

export default interactionsReducer;

