import ProductActionTypes from "./product.types";

export const addHint = value => ({
    type: ProductActionTypes.ADD_HINT,
    payload: value
});

export const addHotspotDetails = value => ({
    type: ProductActionTypes.ADD_HOTSPOT_DETAILS,
    payload: value
});

export const addHotspots = value => ({
    type: ProductActionTypes.ADD_HOTSPOTS,
    payload: value
});

export const addImageMap = value => ({
    type: ProductActionTypes.ADD_IMAGE_MAP,
    payload: value
});

export const addItem = value => ({
    type: ProductActionTypes.ADD_ITEM,
    payload: value
});

export const addMedium = value => ({
    type: ProductActionTypes.ADD_MEDIUM,
    payload: value
});

export const addProduct = value => ({
    type: ProductActionTypes.ADD_PRODUCT,
    payload: value
});

export const addProductId = value => ({
    type: ProductActionTypes.ADD_PRODUCT_ID,
    payload: value
});

export const addProducts = value => ({
    type: ProductActionTypes.ADD_PRODUCTS,
    payload: value
});

export const addScenes = value => ({
    type: ProductActionTypes.ADD_SCENES,
    payload: value
});

export const addSolutionDependencies = value => ({
    type: ProductActionTypes.ADD_SOLUTION_DEPENDENCIES,
    payload: value
});

export const addStorageUrl = value => ({
    type: ProductActionTypes.ADD_STORAGE_URL,
    payload: value
});
