export const ModalHeaders = {
    HELP: "HELP REQUESTED",
    OOPS: "OOPS!",
};

export const ModalMessages = {
    BAD_DATA: "missing data",
    FATAL: "FATAL ERROR",
    SERVER_TIME_OUT: "Hmm, something's running a bit slow, try again in a sec",
    SUBSCRIPTION_FAIL: "subscription failure",
};
