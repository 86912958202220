import React from "react";

const VideoPlayer = ({ url, handleEnded }) => {
    return (<video 
        width="100%" 
        preload="auto"
        controls 
        poster="http://news.bbcimg.co.uk/media/images/49752000/jpg/_49752198_duckling1-email.jpg"
        onEnded={handleEnded}
        webkit-playsinline="true" 
        playsInline
    >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
    </video>);
};

export default VideoPlayer;
