import React from "react";

const ConfirmEmailForm = ({ emailAddress, handleCancel, handleSubmit }) => (
    <div>
        <p>You sure this is spelled correctly?</p>
        <p><span>{emailAddress}</span></p>
        <form onSubmit={handleSubmit}>
            <input type="submit" value="Yep" />
            <input type="button" value="Oh, maybe not" onClick={handleCancel} />
        </form>
    </div>
);

export default ConfirmEmailForm;
