import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Column, ContentContainer, Row } from "../global.styles";
import EmailAddressForm from "../components/email-address-form.component";
import withUserUtils from "./with-user-utils";

import { selectClient, selectPassCode } from "../redux/event/event.selectors";
import { selectProductName } from "../redux/product/product.selectors";
import { selectEmail } from "../redux/users/users.selectors";

import { setEmail } from "../redux/users/users.actions";

import { ModalHeaders } from "../modal.types";
import { PageTypes } from "./page.types";


class EmailPage extends React.Component {
    constructor(props) {
        super(props);
        // console.log('qwer', props);

        this.state = {
            emailAddress: "",
        };
        this.submitting = false;
        this.emailValidator = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    async componentDidMount() {
        const { email } = this.props;
        // console.log('qwer', email);
        if (email) {
            this.setState({ emailAddress: email})
        };
        this.props.setPageName(PageTypes.EMAIL);
    };

    goToPage = page => {
        const { eventPassCode, history } = this.props;
        const location = `/${page}/${eventPassCode}`;
        history.push(location);
    };

    handleChange = e => {
        const { value, name } = e.target;
        this.setState({ [name]: value });
    };

    handleEnterEmailCancel = e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        this.goToPage(PageTypes.NAME);
    };

    handleEnterEmailSubmit = e => {
        e.preventDefault();
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        const emailAddress = this.state.emailAddress.trim();
        if (this.emailValidator.test(emailAddress)) {
            this.props.setEmail(emailAddress);
            this.goToPage(PageTypes.EMAIL_CONFIRMATION);
        } else {
            this.props.setModalMessages({ header: ModalHeaders.OOPS, message: "email address doesn't look right (#717)" });            
        };
    };

    render() {
        this.submitting = false;
        const { client, productName } = this.props;
        // console.log('qwer', client, productName);
        return (<ContentContainer>
            <Row>
                <Column widthPercent={100}>
                    <p><span>{productName}</span> with <span>{client}</span></p>
                    <EmailAddressForm
                        emailAddress={this.state.emailAddress}
                        handleChange={this.handleChange} 
                        handleCancel={this.handleEnterEmailCancel}
                        handleSubmit={this.handleEnterEmailSubmit} 
                    />
                </Column>
            </Row>
        </ContentContainer>);
    };
};

const mapStateToProps = createStructuredSelector({
    client: selectClient,
    email: selectEmail,
    eventPassCode: selectPassCode,
    productName: selectProductName,
});

const mapDispatchToProps = dispatch => ({
    setEmail: value => dispatch(setEmail(value)),
});

export default withUserUtils(
    connect(mapStateToProps, mapDispatchToProps)(EmailPage)
);
