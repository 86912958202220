import React from "react";

import BreakoutRoomsSelect from "./breakout-rooms-select.component";
import { FormButton, FormLabel, FormTextField } from "../global.styles";

const NameForm = ({ userName, handleChange, handleSubmit }) => (
    <form onSubmit={handleSubmit}>
        <FormLabel>Name:</FormLabel>
        <FormTextField 
            name="userName"
            type="text"
            value={userName}
            onChange={handleChange}
            required
        />
        <BreakoutRoomsSelect
            handleChange={handleChange}
        />
        <FormButton type="submit" value="Submit" />
    </form>
);

export default NameForm;
