import firebase from "firebase/app";

import { formatWithId, realTime } from "./firestore.utils";
import { RealTimeEvents, InteractionKeys, InteractionTypes, UserKeys } from "./realtime.types";

import uuid from "react-uuid";

export const createInteraction = (userId, pageRef, type, timestamp, sceneId, value) => {
    return {
        ...createInteractionWithoutId(userId, pageRef, type, timestamp, sceneId, value),
        id: uuid(),
    };
};

const createInteractionWithoutId = (userId, pageRef, type, timestamp, sceneId, value) => {
    return { 
        [InteractionKeys.USER_ID]: userId, 
        [InteractionKeys.PAGE]: pageRef, 
        [InteractionKeys.TYPE]: type, 
        [InteractionKeys.TIMESTAMP]: timestamp,
        [InteractionKeys.SCENE_ID]: sceneId ? sceneId : null,
        [InteractionKeys.OTHER_VALUES]: value ? value : null,
    };
};

export const storeInactivatedUser = async (user, thread, dataId) => {
    const inactiveUser = { ...user, [UserKeys.ACTIVE]: false }
    // console.log('qwer', inactiveUser);
    return await storeData(inactiveUser, thread, dataId);

};

export const storeInteraction = async (userId, pageRef, type, thread, sceneId, value, dataId) => {
    const interaction = createInteractionWithoutId(
        userId, 
        pageRef, 
        type, 
        firebase.database.ServerValue.TIMESTAMP, 
        sceneId, 
        value
    );
    // console.log('qwer', interaction);
    return await storeData(interaction, thread, dataId);
};

export const storeUser = async (name, breakoutRoomNumber, roomCode, sceneIndex, thread, dataId) => {
    let user = { 
        [UserKeys.NAME]: name, 
        [UserKeys.BREAKOUT_ROOM_NUMBER]: +breakoutRoomNumber, 
        [UserKeys.ROOM_CODE]: roomCode, 
        [UserKeys.SCENE_INDEX]: sceneIndex, 
        [UserKeys.ACTIVE]: true, 
    };
    return await storeData(user, thread, dataId);
};

export const fetchInteractions = async (thread, userId, pageType) => {
    let entryId = null;
    let interactions = [];
    // console.log('qwer ------------------------------------------------------------');
    // console.log('qwer', thread, userId, pageType);
    try {
        await realTime.ref(thread)
           .once(RealTimeEvents.VALUE)
            .then(function(snap) {
                snap.forEach(interaction => {
                    const value = interaction.val();
                    const key = interaction.key;
                    const userMatch = userId && value[InteractionKeys.USER_ID] === userId;
                    const pageMatch = pageType && value[InteractionKeys.PAGE] === pageType;
                    // console.log('qwer', userMatch, pageMatch);
                    if (
                        userMatch 
                        && pageMatch 
                        && value[InteractionKeys.TYPE] === InteractionTypes.ENTRY
                    ) {
                        entryId = key;
                    } else {
                        interactions.push(formatWithId(key, value));
                    };
                });
            });
    } catch (error) {
        console.log('qwer', error);
        return null;
    };
    
    return { 
        interactions: interactions.length > 0 ? interactions : null, 
        entryId, 
    };
};

export const fetchDocuments = async (thread, docId) => {
    let results = [];
    try {
        await realTime.ref(thread)
            .once(RealTimeEvents.VALUE)
            .then(function(snap) {
                snap.forEach(user => {
                    const key = user.key;
                    // console.log('qwer', key, docId)
                    if (!docId || key === docId) {
                        results.push(formatWithId(key, user.val()));
                    }
                });
            });
    } catch (error) {
        console.log('qwer', error);
        return null;
    };
    return results.length > 0 ? 
        results 
        : null;
};

const storeData = async (data, thread, dataId) => {
    // console.log("qwer realtime.utils", dataId, thread, data);
    let result = null;
    try {
        let threadRef = realTime.ref("/" + thread);
        const key = dataId ? dataId : (await threadRef.push()).key
        await threadRef.child("/" + key)
            .set(data)
            .then(function() {
                result = formatWithId(key, data);
            })
            .catch(error => {
                console.log('qwer',error);
                return result;
            });
    } catch (error) {
        console.log('qwer', error);
        return result;
    };  
    return result;
};
