import React from "react";

import { FormButton, FormLabel, FormTextField } from "../global.styles";

const EmailAddressForm = ({ emailAddress, handleChange, handleCancel, handleSubmit }) => (
    <>
        <form onSubmit={handleSubmit}>
            <FormLabel>Email address:</FormLabel>
            <FormTextField 
                name="emailAddress" 
                type="text" 
                value={emailAddress} 
                onChange={handleChange} 
                label="email" 
                />
            <br />
            <FormButton type="submit" value="Submit" />
            <br />
            <FormButton type="button" value="Skip" onClick={handleCancel} />
        </form>
    </>
);

export default EmailAddressForm;
