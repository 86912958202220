import React from "react";
import { connect } from "react-redux";

import ItemInputs from "./item/item-inputs.component";

import { selectSceneById } from "../redux/product/product.selectors";

const SceneInputs = ({ scene, handleChange, handleSubmit }) => (
    <ItemInputs
        uniqueId={scene.id} 
        inputs={scene.inputs}
        handleChange={handleChange}
        handleSubmit={handleSubmit} 
    />
);

const mapStateToProps = (state, ownProps) => ({
    scene: selectSceneById(ownProps.sceneId)(state),
    handleChange: ownProps.handleChange,
    handleSubmit: ownProps.handleSubmit,
});

export default connect(mapStateToProps)(SceneInputs)