export const ChallengeTypes = {
    INFO: "info",
    TEAM: "team",
    USER: "user",
};

export const CollectionNames = {
    EVENTS: "events",
    HINTS: "hints",
    HOTSPOT_DETAILS: "hotspot-details",
    HOTSPOTS: "hotspots",
    IMAGE_MAPS: "image-maps",
    ITEMS: "items",
    HELP: "help",
    EMAIL: "email",
    MEDIA: "media",
    PRODUCTS: "products",
    SCENES: "scenes",
    SCENE_CODES: "scene-codes",
    SOLUTION_DEPENDENCIES: "solution-dependencies",
    SOLUTIONS: "solutions",
    TEAM: "team",
    TEAM_CAPTAIN: "team-captain",
    TEAM_CERTIFICATION: "team-certification",
    TIMESTAMPS: "timestamps",
};

export const FirestoreProps = {
    ABBREVIATION: "av",
    AMOUNT_BREAKOUT_ROOMS: "abr",
    CHALLENGE_TYPE: "ct",
    CLIENT: "cl",
    CORRECT_SOLUTION: "cs",
    DEPENDENT_SOLUTION_IDS: "dsIds",
    DESCRIPTION: "dt",
    DOCUMENT_ID: "dId",
    EMAIL_TEMPLATE: "guide",
    HOTSPOT_DETAILS_ID: "hdId",
    ID: "id",
    INDEX: "in",
    INPUTS: "inputs",
    INTRO_VIDEO: "iv",
    INVENTORY_ITEM_ID: "iiId",
    INVENTORY_ITEM_IDS: "iiIds",
    IMAGE_MAP_ID: "imId",
    ITEM_ID: "itemId",
    MEDIA_IDS: "mediaIds",
    MIN_PLAYERS_PER_TEAM: "mppt",
    MINUTES: "mn",
    NUM_USES: "nu",
    PASS_CODE: "pc",
    PRODUCT_ID: "pId",
    SCENE_ID: "sceneId",
    START_DATE: "sd",
    SCENE_STARTS: "ss",
    SOLUTION_ID: "soId",
    THUMBNAIL: "tn",
    TITLE: "title",
    TO_ADD: "ta",
    TO_REMOVE: "tr",
    TYPE: "type",
    UNIQUE_ID: "uid",
    URL: "url",
    VALUE: "value",
    VALUES: "vs",
};

export const ItemStates = {
    BLOCKED: "bl",
    DEFAULT: "df",
    UNLOCKED: "ul",
};

// herehere these don't go to firestore so should not be in this file
export const InternalProps = {
    DESELECT_EVENT: "Deselect Event",
    MONITOR: "Monitor",
    NULL_OPTION: "nullOption",
    SECONDS: "seconds",
    SELF_ID: "selfId",
    STATE: "st",
    TIMER_MINUTES: "tm",
    UPDATE: "Update",
};

export const ServerResponseStates = {
    DORMANT: "dormant",
    TIME_OUT: "timeOut",
    WAITING: "waiting",
};

export const TeamStates = {
    BUILDING_TEAM: "BUILDING_TEAM",
    CHOOSING_NAME: "CHOOSING_NAME",
    COMPLETE: "COMPLETE",
    ELECTING_CAPTAIN: "ELECTING_CAPTAIN",
    TEAM_BUILT: "TEAM_BUILT",
};
